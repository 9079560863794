package Structs


import Utils.getJsonWithConfig
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonArray
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import myName



@myName("User")
@Serializable
data class User(
    var company_id: Int,
    var user_company_name: String,
    var user_company_print_name: String,
    var user_line_number: String,
    var user_phone_number: String,
    var fax_number: String,
    var user_address: String,
    var user_buisness_number: String,
    var email: String,
    var date_joined: String,
    var print_state: Int,
    var default_print_state: Int,
    var no_tax_p: Int,
    var no_tax_c: Int,
    var active: Int,
    var company_type: Int,
    var daily_prices: Int,
    var add_carteset: Int,
    var note_price: Int,
    var edi_state: Int,
    var taklit: Int,
    var weight: Int,
    var order_cofc: Int,
    var tax_pay_note: Int,
    var barcode_reader: Int,
    var external_id: Int,
    var tax_note_print_state: Int, // 0 - notes, 1- sum, 2 - products  , 3 -note + sum, 4 - note + products
    var unit_drop: String,

    var tax_note_split: Int,
    var tax_note_split_dv: Int,
    var visit_debt:Int,
    var default_notes:String = "[]",
    var hesh_israel: Int=0,
) {
    var with_sign: Int=1
    var defaultNotesJson = ReadUserCommentJson.createFromJson(default_notes)
    override fun toString(): String {
        return user_company_name.toString()
    }


    @myName("getName")
    fun getName(date: String): String? {
        return date
    }

    @myName("needPriceInNoteShow")
    fun needPriceInNoteShow(): Boolean {
        return note_price == 1 || note_price == 2 || note_price == 3 || note_price == 5
    }

    @myName("needPriceChangeInNote")
    fun needPriceChangeInNote(): Boolean {
        return note_price == 1 || note_price == 2 || note_price == 3
    }

    fun needRangePriceChange(): Boolean {
        return note_price == 3
    }

    @myName("savePriceInNote")
    fun savePriceInNote(): Boolean {
        return note_price == 1 || note_price == 4
    }

    fun getCompanyTypeStr(): String {
        return when (company_type) {
            0 -> "ח.פ/ע.מ"
            1 -> "ח.פ"
            2 -> "ע.מ"
            3 -> "ע.פ"
            4->"ח.פ"
            5->"ע.מ"
            else -> "ח.פ/ע.מ"
        }

    }

    @myName("withBarcode")
    fun withBarcode(): Boolean {
        return default_print_state % 2 == 1
    }

    @myName("withReturns")
    fun withReturns(): Boolean {
        return (default_print_state / 2) % 2 == 1
    }

    @myName("withPrice")
    fun withPrice(): Boolean {
        return (default_print_state / 4) % 2 == 1
    }
    @myName("getJsonDesc")
    fun getJsonDesc(p: String): ReadUserCommentJson? {
        return defaultNotesJson[p]
    }

}

@Serializable
data class ReadUserCommentJson(
    val type: String,
    val param: String,
    val name: String,
    val forceInNote:Int=0
) {



    companion object {
        fun createFromJson(js: String): Map<String,ReadUserCommentJson> {
            return (getJsonWithConfig().parseToJsonElement((js).replace("\\'", "'")) as JsonArray).map {
                getJsonWithConfig().decodeFromJsonElement(ReadUserCommentJson.serializer(), it)
            }.map { it.param to it }.toMap()
        }


    }
}