package Utils

import myName

val WRAPPED_NAME = listOf<String>(
    "", "קרטון", "ארגז", "ק''ג", "יח'", "משטח", "כללי","דלי","שק","ליטר","מנה","קופסא","שקיות"
)

@myName("WrappedName")
enum class WrappedName(val state: Int = 0) {
    NONE(0),
    CARTON(1),
    BOX(2),
    KG(3),
    UNIT(4),
    SURFACE(5),
    SOME_UNIT(6),
    DLI(7),
    SAK(8),
    LITER(9),
    DOSE(10),
    CRATE(11),
    LITTLE_BAGS(12)
    ;

    override fun toString(): String {
        return MethodName()
    }

    fun convert(ps: Int): WrappedName {
        return when (ps) {
            0 -> {
                NONE
            }
            1 -> {
                CARTON
            }
            2 -> {
                BOX
            }
            3 -> {
                KG
            }
            4 -> {
                UNIT
            }
            5 -> {
                SURFACE
            }
            6 -> {
                SOME_UNIT
            }
            7->  {
                DLI
            }
            8->  {
                SAK
            }
            9->  {
                LITER
            }
            10->  {
                DOSE
            }
            11->  {
                CRATE
            }
            12->  {
                LITTLE_BAGS
            }
            else -> {
                UNIT
            }
        }
    }

    fun MethodName(): String {
        return WRAPPED_NAME[this.state]
    }
    @myName("isContainer")
    fun isContainer():Boolean {
        return when (this) {
            CARTON,BOX,SURFACE,DLI,SAK,DOSE,CRATE,LITTLE_BAGS -> {
                true
            }
            else -> {
                false
            }
        }
    }
    companion object {
        fun convertFrom(name: String): WrappedName? {
            return WRAPPED_NAME.indexOfFirst { it.compareTo(name) == 0 }.let {
                if (it == -1)
                    null
                else
                    NONE.convert(it)
            }
        }

    }

}