package Structs.Api

import DataBase.UINKDBInterface
import Structs.InformationBase
import Utils.DocType
import kotlinx.serialization.Serializable
import myName

val reasonMap: Map<Int, String> = mapOf(
    27 to "דחייה",
    29 to "אישור",
    34 to "הסתייגות",
    28 to "דחייה עקב כפילות תעודה",
    26 to "דחייה טכנית",
    7 to "דחייה על כפילות התעודה הוקלדה ידנית",
    24 to "כמות שהתקבלה בפועל",
    45 to "כמות שהתקבלה בפועל לא סופי",
    12 to "ביטול תמ נדחה",
    40 to "קליטה ידנית"
)

@myName("ApiNote")
@Serializable
open class ApiNote(
    val api_method: Int,
    val doc_type: Int,
    val doc_id: Int,
    val ent_id: Int,
    val doc_date: String,
    val connected_id: Long,
    val send: Int,
    val reason: String,
    val retries:Int
) :
    InformationBase() {
    val method = Api.STORENEXT.convert(api_method)
    val docType = DocType.DELIVERY_NOTES.convert(doc_type)

    fun getDoc():InformationBase?{
        return when(docType){
            DocType.DELIVERY_NOTES->UINKDBInterface.activeDB.getClientDeliveryNotes(delivery_id = doc_id).first.firstOrNull()
            DocType.TAX_NOTE,DocType.TAX_PAY_NOTE,DocType.TAX_NOTE_CANCEL->UINKDBInterface.activeDB.getClientTaxNote(id=doc_id, type = doc_type).first.firstOrNull()
            DocType.PAY_NOTE->UINKDBInterface.activeDB.getClientPay(id=doc_id).first.firstOrNull()
            DocType.ORDERS->UINKDBInterface.activeDB.getClientOrderNotes(order_id = doc_id).first.firstOrNull()
            else -> {
                throw  Exception("NOT SUPPORTED")
            }
        }

    }
    fun getReasonStr(): String {

        if (method == Api.STORENEXT) {
            try {
                return reasonMap[reason.toInt()] ?: ""
            } catch (e: Exception) {

            }
        }
        return reason

    }

    override fun getConnectedDate(): String {
        return doc_date
    }

    override fun getConnectedId(): Int {
        return doc_id
    }

    override fun getConnectedEntId(): Int {
        return ent_id
    }

    override fun getConnectedValue(): Float {
        return 0f
    }

    override fun getConnectedName(): String {
        return ""
    }
}