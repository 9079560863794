import kotlinx.serialization.json.*

@JsName("NKTypeConvertor")
object NKTypeConvertor {
    @JsName("toKotlinInt")
    fun toKotlinInt(n: Int): Int {
        return n.toLong().toInt()
    }

    @JsName("toKotlinLong")
    fun toKotlinLong(n: Int): Long {
        return n.toLong()
    }

    @JsName("toKotlinFloat")
    fun toKotlinFloat(n: Float): Float {
        return n.toDouble().toFloat()
    }

    @JsName("toKotlinList")
    fun toKotlinList(l: Array<Any>): List<Any> {
        return l.toList()

    }

    @JsName("jsToKotlinMap")
    fun jsToKotlinMap(jsArray: dynamic): List<Map<String, Any>> {
        val jsonArray = jsArray as Array<dynamic>
        return jsonArray.map { jsObject ->
            val jsonString = JSON.stringify(jsObject)
            val jsonObject = Json.parseToJsonElement(jsonString) as JsonObject
            jsonObject.filter { (_, value) ->
                val primitive = value.jsonPrimitive
                ((primitive.contentOrNull ?: primitive.booleanOrNull ?: primitive.intOrNull
                ?: primitive.doubleOrNull ?: primitive.longOrNull) != null)
            }.mapValues { (_, value) ->
                val primitive = value.jsonPrimitive
                (primitive.contentOrNull ?: primitive.booleanOrNull ?: primitive.intOrNull
                ?: primitive.doubleOrNull ?: primitive.longOrNull ?: "")
            }.toMap()
        }
    }
}