package DataBase

import Structs.*
import Structs.Api.ApiNote
import Utils.*
import kotlin.math.roundToInt

open class UINKDBInterface : NKDBInterface() {
    suspend fun fetchSpecificSupplierData(params: HashMap<String, Any>): DBQuery {
        val id = params["id"]!! as Int
        val fromDate = (params["fromDate"] as String?) ?: DatesManipulator.dateNowNoHour()
        val toDate = (params["fromDate"] as String?) ?: DatesManipulator.dateNowNoHour()
        return fetchSpecificSupplierData(listOf(id), fromDate, toDate)
    }

    suspend fun fetchSuppliers(params: HashMap<String, Any>): DBQuery {

        return fetchSuppliers()
    }

    override fun setCompany(company: String, company_id: String?, local: Boolean) {
        super.setCompany(company, company_id, local)
        activeDB = this
    }

    fun setActiveDB() {
        activeDB = this
    }

    suspend fun badReturnCheck(): Any? {
        return null
    }

    suspend fun buildClientCreation(
        params: HashMap<String, Any>
    ): Respone<List<ClientCreation>, DBQuery> {
        return this.buildClientCreation(
        )
    }

    suspend fun getClients(
        params: HashMap<String, Any>
    ): Respone<List<Client>, DBQuery> {
        return this.buildClients(
            active = params["active"] as Boolean?,
            fromDate = params["date"] as String,
            toDate = params["date"] as String,
            branch = params["branch"] as Int?,
            withDaily = (params["withDaily"] as Boolean) ?: false
        )
    }

    suspend fun buildClientsDailyData(
        params: HashMap<String, Any>
    ): Respone<List<ClientDailyData>, DBQuery> {
        val singleDriverId = params["driver_id"] as Int?
        val multiDriverId = params["driver_ids"] as List<Int>?
        val singleCollectorId = params["collector_id"] as Int?
        val multiCollectorId = params["collector_ids"] as List<Int>?
        return this.buildClientsDailyData(
            id = params["id"] as Int?,
            date = params["date"] as String,
            driver_id = multiDriverId ?: singleDriverId?.let { listOf(it) },
            collector_id = multiCollectorId ?: singleCollectorId?.let { listOf(it) },
            day = params["day"] as Int?,
            ids = params["ids"] as List<Int>?
        )
    }

    suspend fun newClientsAllDailyData(
        params: HashMap<String, Any>
    ): Respone<List<ClientDailyData>, DBQuery> {
        val day = params["day"] as Int?
        var date = params["date"] as String?
        if (day != null) {
            date = baseOrderDaysToData[day]

        }

        return this.newClientAllDailyData(
            date = date!!,
            daily = params["data"] as List<ClientDailyData>,
            dailyFinder = params["dailyFinder"] as Boolean? ?: false,
        )
    }

    suspend fun newClientsDailyData(
        params: HashMap<String, Any>
    ): Respone<List<ClientDailyData>, DBQuery> {
        return this.newClientDailyData(
            id = params["id"] as Int,
            date = params["date"] as String,
            driver_id = params["driver_id"] as Int,
            collector_id = params["collector_id"] as Int,
            position = params["position"] as Int,
            car = params["car"] as String ?: "",
        )
    }

    suspend fun newClient(
        params: HashMap<String, Any>
    ): Respone<Client?, DBQuery> {
        return this.newClient(
            agent = params["agent"]!! as String,
            date = params["date"] as String,
            name = params["name"]!! as String,
            include_tax = params["include_tax"] as Int?,
            business_name = params["business_name"] as String?,
            business_id = params["business_id"] as String?,
            address = params["address"] as String?,
            print_state = params["print_state"] as Int?,
            phone = params["phone"] as String?,
            phone_contact = params["personalNamePhone"] as String?,
            branch = params["branch"] as Int?,
            master = params["master"] as Int?,
            discount = params["discount"] as Float?,
            email = params["email"] as String?,
            external_id = params["external_id"] as String?,
            day = (params["day"] as Int?) ?: params["days"] as Int?,
            notes = params["notes"] as Int?,
            no_tax_client = params["no_tax_client"] as Int?,
            comments = params["comments"] as String?,
            min_order = params["min_order"] as Int?,
            id = params["id"] as Int?,
            location = params["location"] as String?,
            category = params["category"] as String?,
            obligo = params["obligo"] as Float?,
            price_control = params["price_control"] as Int?,
            payment_notes = params["payment_notes"] as String?,
            category2 = params["category2"] as String?,
            driver = params["driver"] as String?,
            min_order_sum = params["min_order_sum"] as Float?,
            tax_note_type = params["tax_note_type"] as Int?,


            )
    }

    suspend fun getClientBranch(
        params: HashMap<String, Any>
    ): Respone<List<Client>, DBQuery> {
        return this.buildClients(
            active = params["active"] as Boolean?,
            fromDate = params["fromDate"] as String,
            toDate = params["toDate"] as String,
            branch = params["branch"] as Int?,
        )
    }

    suspend fun updateDriver(
        params: HashMap<String, Any>
    ): DBQuery {
        val driver = (params["driver"] as String).let { d ->
            getAgents().first.filter { it.isDriver() }.firstOrNull { it.user_name == d }
        } ?: return DBQuery.FAILED
        val id = params["id"] as Int
        val all_base = params["all_base"] as Boolean
        val fromDate = params["fromDate"] as String?
        val p = updateDriverAll(id, driver.id, all_base, fromDate)
        return p
    }

    suspend fun updateClient(
        params: HashMap<String, Any>
    ): Respone<Client?, DBQuery> {
        return this.updateClient(
            id = params["id"]!! as Int,
            date = params["date"] as String,
            agent = params["agent"] as String?,
            name = params["name"] as String?,
            include_tax = params["include_tax"] as Int?,
            business_name = params["business_name"] as String?,
            business_id = params["business_id"] as String?,
            address = params["address"] as String?,
            print_state = params["print_state"] as Int?,
            position = params["position"] as Int?,
            active = params["active"] as Int?,
            phone = params["phone"] as String?,
            phone_contact = (params["personalNamePhone"] as String?) ?: params["phone_contact"] as String?,
            branch = params["branch"] as Int?,
            master = params["master"] as Int?,
            discount = params["discount"] as Float?,
            email = params["email"] as String?,
            external_id = params["external_id"] as String?,
            day = params["day"] as Int?,
            notes = params["notes"] as Int?,
            no_tax_client = params["no_tax_client"] as Int?,
            comments = params["comments"] as String?,
            min_order = params["min_order"] as Int?,
            location = params["location"] as String?,
            category = params["category"] as String?,
            obligo = params["obligo"] as Float?,
            price_control = params["price_control"] as Int?,
            payment_notes = params["payment_notes"] as String?,
            category2 = params["category2"] as String?,
            driver = params["driver"] as String?,
            min_order_sum = params["min_order_sum"] as Float?,
            tax_note_type = params["tax_note_type"] as Int?,
        )
    }


    suspend fun newClientProduct(
        params: HashMap<String, Any>
    ): Respone<Product?, DBQuery> {
        return this.newClientProduct(
            name = params["name"]!! as String,
            date = params["date"]!! as String,
            barcode = params["barcode"] as String?,
            default_price = params["default_price"] as Float?,
            no_tax_product = params["no_tax_product"] as Int?,
            external_id = params["external_id"] as String?,
            unit = params["unit"] as Int?,
            unit_amount = params["unit_amount"] as Float?,
            category = params["category"] as String?,
            cost_price = params["cost_price"] as Float?,
            split_category = params["split_category"] as Int?,
            category2 = params["category2"] as String?,

            )
    }


    suspend fun updateClientProduct(
        params: HashMap<String, Any>
    ): Respone<Product?, DBQuery> {
        return this.updateClientProduct(
            id = params["id"]!! as Int,
            name = params["name"] as String?,
            date = params["date"] as String,
            barcode = params["barcode"] as String?,
            default_price = params["default_price"] as Float?,
            position = params["position"] as Int?,
            active = params["active"] as Int?,
            no_tax_product = params["no_tax_product"] as Int?,
            taklit_id = params["taklit_id"] as Int?,
            taklit_type = params["taklit_type"] as Int?,
            external_id = params["external_id"] as String?,
            unit = params["unit"] as Int?,
            unit_amount = params["unit_amount"] as Float?,
            category = params["category"] as String?,
            cost_price = params["cost_price"] as Float?,
            split_category = params["split_category"] as Int?,
        )
    }

    suspend fun updateClientAllupdateClientAllData(
        params: HashMap<String, Any>
    ): Respone<List<Client>, DBQuery> {
        return this.updateClientAllData(
            mapper = params["mapper"] as List<Map<String, Any>>,
            ids = params["ids"] as List<Int>?
        )
    }

    suspend fun updateAllOrderProduct(
        params: HashMap<String, Any>
    ): Respone<List<OrderProduct>, DBQuery> {
        return this.updateAllOrderProduct(
            mapper = params["mapper"] as List<Map<String, Any>>
        )
    }

    suspend fun updateClientAllProduct(
        params: HashMap<String, Any>
    ): Respone<List<Product>, DBQuery> {
        return this.updateClientAllProduct(
            mapper = params["mapper"] as List<Map<String, Any>>,
            full = (params["full"] as Boolean?) ?: false
        )
    }

    suspend fun updateClientProductPosition(params: HashMap<String, Any>): DBQuery {
        val new_p_list = params["products"] as List<Product>

        val maps = new_p_list.mapIndexed { index: Int, it: Product ->
            val map: MutableMap<String, Any> = mutableMapOf()
            map["product_id"] = it.id
            map["id"] = it.id
            map["position"] = index
            map
        }

        return updateClientAllProduct(maps, false).second
    }

    suspend fun updateSupplierProductPosition(params: HashMap<String, Any>): DBQuery {
        val new_p_list = params["products"] as List<Product>

        val maps = new_p_list.mapIndexed { index: Int, it: Product ->
            val map: MutableMap<String, Any> = mutableMapOf()
            map["id"] = it.id
            map["position"] = index
            map
        }

        return updateSupplierAllProduct(maps).second
    }

    suspend fun updateAllClientProduct(params: HashMap<String, Any>): Respone<List<Product>, DBQuery> {
        val new_p_list = params["products"] as List<Int>
        val category = params["category"] as String?
        val category2 = params["category2"] as String?
        val position = params["position"] as Int?
        val no_tax_product = params["no_tax_product"] as Int?
        val available = params["available"] as Boolean?
        val sales = params["sales"] as Int?
        val min_order = params["min_order"] as Int?
        val forceLock = params["forceLock"] as Int?
        val hourLock = params["hourLock"] as String?
        val dayLock = params["dayLock"] as Int?
        val step = params["step"] as Int?
        val collector = params["collector"] as Int?
        val unit_preferece = params["unit_preferece"] as Int?
        val unitStr = params["unit"] as String?
        val unit = unitStr?.let { WrappedName.convertFrom(unitStr) }?.state
        val extra_unitStr = params["extra_unit"] as String?
        val extra_unit = extra_unitStr?.let { WrappedName.convertFrom(extra_unitStr) }?.state
        val unit_amount = params["unit_amount"] as Float?
        val conversion_rate = params["conversion_rate"] as Float?


        val o_maps =
            if (available != null || sales != null || min_order != null || forceLock != null || hourLock != null || dayLock != null || step != null || collector != null || unit_preferece != null || extra_unit != null || conversion_rate != null)
                new_p_list.mapIndexed { index: Int, it: Int ->
                    val map: MutableMap<String, Any> = mutableMapOf()
                    map["id"] = it
                    available?.let { map["available"] = if (available) 1 else 0 }
                    position.let { map["position"] = index }
                    sales?.let { map["sales"] = sales }
                    min_order?.let { map["min_order"] = min_order }
                    forceLock?.let { map["forceLock"] = forceLock }
                    hourLock?.let { map["hourLock"] = hourLock }
                    dayLock?.let { map["dayLock"] = dayLock }
                    step?.let { map["step"] = step }
                    unit_preferece?.let { map["unit_preferece"] = unit_preferece }
                    collector?.let { map["collector"] = collector }

                    extra_unit?.let { map["extra_unit"] = extra_unit }

                    conversion_rate?.let { map["conversion_rate"] = conversion_rate }

                    map
                }
            else
                null


        val o_maps_call = o_maps?.let { updateAllOrderProduct(o_maps) } ?: Respone(listOf(), DBQuery.SUCCESS)
        if (o_maps_call.second != DBQuery.SUCCESS) {
            return Respone(listOf(), DBQuery.FAILED)
        }
        val maps =
            if (category != null || category2 != null || position != null || no_tax_product != null || unit != null || unit_amount != null) new_p_list.mapIndexed { index: Int, it: Int ->
                val map: MutableMap<String, Any> = mutableMapOf()
                map["id"] = it
                map["product_id"] = it
                position?.let { map["position"] = index }
                category?.let { map["category"] = category }
                category2?.let { map["category2"] = category2 }
                unit?.let { map["unit"] = unit }
                unit_amount?.let { map["unit_amount"] = unit_amount }
                no_tax_product?.let { map["no_tax_product"] = no_tax_product }
                map
            } else null

        return maps?.let { updateClientAllProduct(maps, false) } ?: getClientProduct(new_p_list)
    }

    suspend fun buildSupplierProducts(params: HashMap<String, Any>): Respone<List<Product>, DBQuery> {
        return this.buildSupplierProducts(
            fromDate = params["fromDate"] as String,
            toDate = params["toDate"] as String,
            attach = params["attach"] as Boolean?,
        )
    }

    suspend fun buildClientProducts(params: HashMap<String, Any>): Respone<List<Product>, DBQuery> {
        return this.buildClientProducts(
            id = (params["id"] as Int?),
            fromDate = params["fromDate"] as String,
            toDate = params["toDate"] as String,
            ids = (params["ids"] as List<Int>?)
        )
    }

    suspend fun buildLatestClientPrices(params: HashMap<String, Any>): Respone<List<RawPrice>, DBQuery> {
        return this.buildLatestClientPrices(
            ids = (params["ids"] as List<Int>?),
            product_id = (params["product_id"] as Int?),
        )
    }

    suspend fun buildClientSpecialPrice(params: HashMap<String, Any>): Respone<List<RawPrice>, DBQuery> {
        return this.buildClientCosts(
            ids = params["ids"] as List<Int>,
            fromDate = params["fromDate"] as String,
            toDate = params["toDate"] as String
        )
    }

    suspend fun buildClientPriorityPrices(params: HashMap<String, Any>): Respone<List<RawPrice>, DBQuery> {
        return this.buildClientPriorityPrices(
            id = (params["ids"] as List<Int>),
            date = params["date"] as String,
        )
    }

    suspend fun buildClientPrices(params: HashMap<String, Any>): Respone<List<RawPrice>, DBQuery> {
        return this.buildClientPrices(
            id = (params["ids"] as List<Int>?) ?: (params["id"] as Int?)?.let { listOf(it) },
            product_id = (params["product_id"] as Int?)?.let { listOf(it) },
            fromDate = params["fromDate"] as String,
            toDate = params["toDate"] as String,
            available = params["available"] as Boolean?,
        )
    }

    suspend fun buildClientBranchNotes(
        params: HashMap<String, Any>
    ): Respone<List<DeliveryNote>, DBQuery> {
        return if ("date_holder" in params)
            this.buildClientNotes(
                client_ids = params["clients"] as List<Int>,
                datesHolder = params["date_holder"] as DatesHolder,
                full = params["full"] as Boolean? ?: false,
                open = params["open"] as Boolean?,
            )
        else
            this.buildClientNotes(
                client_ids = params["clients"] as List<Int>,
                full = params["full"] as Boolean? ?: false,
                open = params["open"] as Boolean?,

                )
    }

    suspend fun newClientPrice(
        params: HashMap<String, Any>
    ): Respone<RawPrice?, DBQuery> {
        return this.newClientPrice(
            id = params["id"]!! as Int,
            product_id = params["product_id"]!! as Int,
            price = params["price"]!! as Float,
            date = params["date"] as String?,
            discount = params["discount"] as Float? ?: 0f,
            toDate = params["toDate"] as String?,
        )
    }

    suspend fun updateAllSpecialProductPrice(
        params: HashMap<String, Any>
    ): DBQuery {
        return this.updateAllSpecialProductPrice(
            ids = params["ids"] as List<Int>,
            product_id = params["product_ids"]!! as List<Int>,
            price = params["price"] as Float?,
            date = params["date"] as String,
            percent = params["percent"] as Float?,
            tax_mutate = (params["tax_mutate"] as Boolean?) ?: true,
            percent_price = params["percent_price"] as Float?,
            toDate = params["toDate"] as String?,
            byPrice = params["byPrice"] as Float?,
            unique = params["unique"] as Boolean?,
        )
    }


    suspend fun updateAllClientProductPrice(
        params: HashMap<String, Any>
    ): DBQuery {
        return this.updateAllClientProductPrice(
            ids = params["ids"] as List<Int>?,
            product_id = params["product_ids"]!! as List<Int>,
            price = params["price"] as Float?,
            date = params["date"] as String,
            percent = params["percent"] as Float?,
            tax_mutate = (params["tax_mutate"] as Boolean?) ?: true,
            percent_price = params["percent_price"] as Float?,
            toDate = params["toDate"] as String?,
            byPrice = params["byPrice"] as Float?,
            unique = params["unique"] as Boolean?,
            baseChange = (params["baseChange"] as Boolean?) ?: false,
            alreadyPrice = (params["alreadyPrice"] as Boolean?) ?: false,
        )
    }

    suspend fun updateAllPrice(
        params: HashMap<String, Any>
    ): DBQuery {
        val isSupplier = (params["supplier"] as Boolean?) ?: false
        return if (isSupplier) this.updateAllSupplierProductPrice(
            ids = params["ids"] as List<Int>?,
            product_ids = params["product_ids"] as List<Int>?,
            date = params["date"] as String,
            toDate = params["toDate"] as String?,
            prices = params["prices"] as List<RawPrice>,
        ) else this.updateAllClientProductPrice(
            ids = params["ids"] as List<Int>?,
            product_ids = params["product_ids"] as List<Int>?,
            date = params["date"] as String,
            toDate = params["toDate"] as String?,
            prices = params["prices"] as List<RawPrice>,
            availables = params["availables"] as List<Int>?,
        )
    }

    suspend fun getSuppliers(
        params: HashMap<String, Any>
    ): Respone<List<Supplier>, DBQuery> {
        return this.buildSuppliers(
            active = (params["active"] as Boolean?)?.let { (it).toInt() },
            fromDate = params["date"] as String,
            toDate = params["date"] as String,
        )
    }

    suspend fun updateAllSuppliersProductPrice(
        params: HashMap<String, Any>
    ): DBQuery {
        return this.updateAllSuppliersProductPrice(
            ids = params["ids"] as List<Int>?,
            product_id = params["product_ids"]!! as List<Int>,
            price = params["price"] as Float?,
            date = params["date"] as String,
            percent = params["percent"] as Float?,
            tax_mutate = (params["tax_mutate"] as Boolean?) ?: true,
            percent_price = params["percent_price"] as Float?,
            toDate = params["toDate"] as String?,
            byPrice = params["byPrice"] as Float?,
            unique = params["unique"] as Boolean?,
        )
    }

    suspend fun buildClientVisitNote(
        params: HashMap<String, Any>
    ): Respone<List<VisitNote>, DBQuery> {
        return this.buildClientVisitNote(
            fromDate = params["fromDate"] as String?,
            toDate = params["toDate"] as String?,
            ids = params["ids"] as List<Int>?,
            ent_ids = params["ent_ids"] as List<Int>?,
            states = params["states"] as List<Int>?,
            full = (params["full"] as Boolean?) ?: false,
        )
    }

    suspend fun newClientVisitNote(
        params: HashMap<String, Any>
    ): Respone<VisitNote?, DBQuery> {

        return this.newClientVisitNote(
            client_id = params["client_id"] as Int,
            agent = params["agent"] as String,
            document_date = params["document_date"] as String,
            date_issued = params["date_issued"] as String,
            lon_lat = params["lon_lat"] as String,
            states = params["states"] as List<Int>,
            comment = params["comment"] as String,
            order_id = params["order_id"] as Int?,
            pd = params["pd"] as ProductDelivery?,
            payment = params["payment"] as Float?,
            f = params["image"] as ByteArray?,

            )
    }

    suspend fun updateClientVisitNote(
        params: HashMap<String, Any>
    ): Respone<VisitNote?, DBQuery> {
        return this.updateClientVisitNote(
            id = params["id"] as Int,
            comment = params["comment"] as String?,
            payment = params["payment"] as Float?,
            pd = params["pd"] as ProductDelivery?,
            states = params["states"] as List<Int>?,
            active = params["active"] as Boolean?,
            order_id = params["order_id"] as Int?,
            date = params["date"] as String?,
        )
    }


    suspend fun buildClientNotes(
        params: HashMap<String, Any>
    ): Respone<List<DeliveryNote>, DBQuery> {

        return if (params.containsKey("last")) {
            this.buildClientLastNotes(
                client_id = params["client_id"] as Int, full = params["full"] as Boolean? ?: false
            )
        } else {
            if (params.containsKey("date_holder")) {
                this.buildClientNotes(
                    client_id = params["client_id"] as Int?,
                    datesHolder = params["date_holder"] as DatesHolder,
                    full = params["full"] as Boolean? ?: false
                )
            } else {
                val client_ids = (params["client_ids"] as List<Int>?) ?: (params["client_id"] as Int?)?.let {

                    if ((params["branched"] as Boolean?) ?: false) {
                        val branch = getClient(it).first?.branch
                        branch?.let { br ->
                            if (br != -1) getBranchMembers(br).first.map { it.id }
                            else listOf(it)
                        } ?: listOf(it)
                    } else listOf(it)

                }
                this.buildClientNotes(
                    client_ids = client_ids,
                    delivery_id = params["id"] as Int?,
                    fromDate = params["fromDate"] as String?,
                    toDate = params["toDate"] as String?,
                    full = params["full"] as Boolean? ?: false,
                    open = params["open"] as Boolean?,
                    api = params["api"] as Boolean? ?: false,
                    orders_ids = params["order_ids"] as List<Int>?,
                )
            }
        }

    }

    suspend fun buildClientEdiNotes(
        params: HashMap<String, Any>
    ): Respone<List<EdiNote>, DBQuery> {
        val ids = params["id"]?.let { listOf(it as Int) }
        return this.buildClientEdiNotes(
            note_id = ids ?: (params["note_id"] as List<Int>?),
            connected_id = params["connected_id"] as Long?,
            send = params["send"] as Boolean?,
            fromDate = params["fromDate"] as String?,
            toDate = params["toDate"] as String?,
            full = params["full"] as Boolean? ?: false
        )


    }

    suspend fun newClientDeliveryNote(
        params: HashMap<String, Any>
    ): Respone<List<DeliveryNote>, DBQuery> {
        return this.newClientDeliveryNote(
            agent = params["agent"]!! as String,
            ent_id = params["ent_id"]!! as Int,
            date = params["date"]!! as String,
            date_issued = params["date_issued"]!! as String,
            delivery_value = params["delivery_value"]!! as List<ProductDelivery>,
            with_price = params["with_price"] as Boolean? ?: false,
            connected_id = params["connected_id"] as Long?,
            notes = params["notes"] as String?,
            order_id = params["order_id"] as Int?,
            increase_id = (params["increase_id"] as Int?) ?: 0,
            delivery_id = params["delivery_id"] as Int?,
            notes2 = params["notes2"] as String?,
            notes3 = params["notes3"] as String?,
            notes4 = params["notes4"] as String?,
            notes5 = params["notes5"] as String?,
            agent_id = params["agent_id"] as Int?,


            )
    }

    suspend fun updateClientNote(
        params: HashMap<String, Any>
    ): Respone<DeliveryNote?, DBQuery> {
        return this.updateClientNote(
            delivery_id = params["id"] as Int,
            paid = params["paid"] as Int?,
            active = params["active"] as Int?,
            connected_id = params["connected_id"] as Long?,
            delivery_value = params["delivery_value"] as List<ProductDelivery>?,
            order_id = params["order_id"] as Int?,
            notes = params["notes"] as String?,
            notes2 = params["notes2"] as String?,
            notes3 = params["notes3"] as String?,
            notes4 = params["notes4"] as String?,
            notes5 = params["notes5"] as String?,
            agent_id = params["agent_id"] as Int?,
            updatePrice = params["updatePrice"] as Boolean?,

            )
    }

    suspend fun buildClientBaseOrderNotes(
        params: HashMap<String, Any>
    ): Respone<List<OrderNote>, DBQuery> {
        return this.getClientBaseOrderNotes(
            day = params["day"] as Int,
            client_ids = params["client_ids"] as List<Int>?,
        )
    }


    suspend fun buildClientOrderNotes(
        params: HashMap<String, Any>
    ): Respone<List<OrderNote>, DBQuery> {

        return if (params.containsKey("last")) {
            this.buildClientLastOrderNotes(
                client_id = params["client_id"] as Int,
                full = params["full"] as Boolean? ?: false,
            )
        } else {
            this.buildClientOrderNotes(
                client_id = params["client_id"] as Int?,
                order_id = params["order_id"] as Int?,
                fromDate = params["fromDate"] as String?,
                toDate = params["toDate"] as String?,
                full = params["full"] as Boolean? ?: false,
                withoutInventory = params["withoutInventory"] as Boolean? ?: false,
                order_ids = params["order_ids"] as List<Int>?,
                client_ids = params["client_ids"] as List<Int>?,
                status = params["status"] as List<Int>?,
                inverse_status = params["inverse_status"] as List<Int>?
            )
        }
    }


    suspend fun newInventoryNote(params: HashMap<String, Any>): Respone<List<OrderNote>,DBQuery> {
        return this.newInventoryNote(
            params["agents"] as List<String>,
            params["date"] as String,
            params["delivery_value"] as List<List<ProductDelivery>>,
            params["sup_delivery_value"] as List<List<ProductDelivery>>,
            params["orders"] as List<Int>,
            agent_ids = params["agent_ids"] as List<Int>?,
        )
    }

    suspend fun newInventoryNoteWeb(params: HashMap<String, Any>): DBQuery {

        val data = params["data"] as List<InventoryItem>
        val agent_ids = data.map { it.agent_id }.distinct()
        val exist = buildClientOrderNotes(
            client_id = -1,
            fromDate = params["date"] as String,
            toDate = params["date"] as String,
            agent_id = agent_ids
        )
        if (exist.second != DBQuery.SUCCESS) {
            return exist.second
        }
        val order = exist.first.mapNotNull { it.agent_id to it }.toMap()
        data.groupBy { it.agent_id }.forEach {
            val agent = it.key
            val info = it.value
            val order_info = order[agent]
            val changes = info.map {
                ProductDelivery(
                    it.product_id,
                    it.inventoryStart ?: 0f,
                    0f,
                    0f,
                    wrapped_amount = it.inventoryStart2 ?: 0f,
                    conversion_ratio = getClientProduct(it.product_id).first?.getOrderProduct()?.conversion_rate ?: 0f,
                )
            }
            val pd = if (order_info == null) {
                changes
            } else {
                val cur = changes.map { it.productId to it }.toMap().toMutableMap()
                order_info.delivery_info.forEach {
                    if (!cur.containsKey(it.productId)) {
                        cur[it.productId] = it
                    }
                }
                cur.values.toList()
            }
            val q = (if (order_info != null) {
                updateClientOrderNote(order_id = order_info.order_id, delivery_value = pd, inventory = "true")
            } else {
                newClientOrderNote(
                    agent = params["agent"]!! as String,
                    ent_id = -1,
                    date = params["date"]!! as String,
                    date_issued = DatesManipulator.dateNow(),
                    delivery_value = pd,
                    sup_delivery_value = emptyList(),
                    active = 1,
                    agent_id = agent
                )
            }).second
            if (q != DBQuery.SUCCESS) {
                return q
            }

        }
        return DBQuery.SUCCESS
    }

    suspend fun newClientOrderNote(
        params: HashMap<String, Any>
    ): Respone<OrderNote?, DBQuery> {
        return this.newClientOrderNote(
            agent = params["agent"]!! as String,
            ent_id = params["ent_id"]!! as Int,
            date = params["date"]!! as String,
            date_issued = params["date_issued"]!! as String,
            delivery_value = params["delivery_value"]!! as List<ProductDelivery>,
            sup_delivery_value = params["sup_delivery_value"]!! as List<ProductDelivery>,
            active = (params["status"] as Int?) ?: 1,
            notes = params["notes"] as String?,
            category = params["category"] as String?,
            agent_id = params["agent_id"] as Int?,
            notes2 = params["notes2"] as String?,
            notes3 = params["notes3"] as String?,
            notes4 = params["notes4"] as String?,
            notes5 = params["notes5"] as String?,
            with_price = (params["new_price"] as Boolean?) ?: false,
            collection = params["collection"] as Map<Int, CollectionObject>?,
            checkUpdate = (params["checkUpdate"] as Boolean?) ?: false,

            )
    }

    suspend fun updateClientOrderNote(
        params: HashMap<String, Any>
    ): Respone<OrderNote?, DBQuery> {
        var status = (params["status"] as String?)?.let {
            OrderStatus.REGULAR.inverseName(it).state
        }
        return this.updateClientOrderNote(
            order_id = params["order_id"]!! as Int,
            agent = params["agent"] as String?,
            active = status ?: params["active"] as Int?,
            date = params["date"] as String?,
            delivery_value = params["delivery_value"] as List<ProductDelivery>?,
            sup_delivery_value = params["sup_delivery_value"] as List<ProductDelivery>?,
            notes = params["notes"] as String?,
            category = params["category"] as String?,
            updatePrice = (params["updatePrice"] as Boolean?) ?: false,
            agent_id = params["agent_id"] as Int?,
            notes2 = params["notes2"] as String?,
            notes3 = params["notes3"] as String?,
            notes4 = params["notes4"] as String?,
            notes5 = params["notes5"] as String?,
            collection = params["collection"] as Map<Int, CollectionObject>?,
            collector_id = params["collector_id"] as Int?,
            karton = params["karton"] as Float?,
            update_agent = params["update_agent"] as String?,
        )
    }

    suspend fun buildClientMonthlyCycle(
        params: HashMap<String, Any>
    ): Respone<List<MonthlyCycle>, DBQuery> {
        return this.buildClientMonthlyCycle(
            date = params["date"] as String?, id = params["id"] as Int?, full = params["full"] as Boolean? ?: false
        )
    }

    suspend fun buildClientMonthlyCyclePdf(
        params: HashMap<String, Any>
    ): Respone<ByteArray?, DBQuery> {
        return this.buildClientMonthlyCyclePdf(
            client_name = (params["client_name"] as String),
            month = params["month"] as String,
            year = params["year"] as String
        )

    }

    suspend fun buildClientDeliveryNotePdf(
        params: HashMap<String, Any>
    ): Respone<ByteArray?, DBQuery> {
        return this.buildClientDeliveryNotePdf(
            id = params["id"] as Int, origin = params["origin"] as Int, force_price = params["force_price"] as Boolean?
        )
    }

    suspend fun buildClientCartesetPdf(
        params: HashMap<String, Any>
    ): Respone<ByteArray?, DBQuery> {
        return this.buildClientCartesetPdf(
            id = params["id"] as Int, dates = params["dates"] as String, onlyTaxSide = params["onlyTaxSide"] as Boolean?
        )
    }

    suspend fun buildClientOrderPdf(
        params: HashMap<String, Any>
    ): Respone<ByteArray?, DBQuery> {
        return this.calcClientOrderPdf(
            ids = params["ids"] as List<Int>, cofc = (params["cofc"] as Boolean?)
                ?: false, id = params["id"] as Int?
        )
    }

    suspend fun buildClientMASSdocPdf(
        params: HashMap<String, Any>
    ): Respone<ByteArray?, DBQuery> {
        return this.calcMASSdocPdf(
            ids = params["ids"] as List<Int>,
            docs = params["docs"] as List<Int>,
            force_price = params["force_price"] as Boolean?,
            origin = params["origin"] as Boolean?,
            originForce = params["originForce"] as Int?,
        )
    }

    suspend fun calcClientReportPdf(
        params: HashMap<String, Any>
    ): Respone<ByteArray?, DBQuery> {
        return this.calcClientReportPdf(
            fromDate = params["fromDate"] as String,
            toDate = params["toDate"] as String,
            report_type = ReportType.valueOf(params["report_type"] as String),
        )
    }

    suspend fun buildClientPricesPdf(
        params: HashMap<String, Any>
    ): Respone<ByteArray?, DBQuery> {
        return this.buildClientPricesPdf(
            id = params["id"] as Int, date = params["date"] as String, p_ids = params["p_ids"] as List<Int>?
        )
    }

    suspend fun buildClientTaxNoteCyclePdf(
        params: HashMap<String, Any>
    ): Respone<ByteArray?, DBQuery> {
        return this.buildClientTaxNoteCyclePdf(
            client_name = (params["client_name"] as String),
            month = params["month"] as String,
            year = params["year"] as String,
            id = params["id"] as Int,
            type = params["type"] as Int,
            origin = params["origin"] as Int
        )
    }

    suspend fun buildClientPayCyclePdf(
        params: HashMap<String, Any>
    ): Respone<ByteArray?, DBQuery> {
        return this.buildClientPayCyclePdf(
            client_name = (params["client_name"] as String),
            month = params["month"] as String,
            year = params["year"] as String,
            id = params["id"] as Int,
            origin = params["origin"] as Int
        )

    }

    suspend fun calcClientDeliveryNotePdf(params: HashMap<String, Any>): Respone<ByteArray?, DBQuery> {
        return this.calcClientDeliveryNotePdf(
            client_id = params["client_id"] as Int,
            date_range = params["date_range"] as String,
            specific_notes = params["specific_notes"] as String?,
            tax_id_merge = params["tax_id_merge"] as List<Int>?,
        )
    }

    suspend fun calcClientDeliveryNotePdfEasy(params: HashMap<String, Any>): Respone<ByteArray?, DBQuery> {
        val docs = params["ids"] as List<Int>
        val order = (params["order"] as Boolean?)
        val realDocs =
            if (order == true) getClientOrderNotes(order_ids = docs).first else getClientDeliveryNotes(docs).first
        val client_id = realDocs.first().ent_id
        val dates = DatesHolder(realDocs.map { it.date }.distinct())
        return this.calcClientDeliveryNotePdf(
            client_id = client_id,
            date_range = dates.toStringFirstLastRange(),
            specific_notes = docs.joinToString(","),
            tax_id_merge = params["tax_id_merge"] as List<Int>?,
            order = order
        )
    }

    suspend fun calcClientDebtXSL(params: HashMap<String, Any>): Respone<ByteArray?, DBQuery> {
        return this.calcClientDebtXSL(
            date_range = params["date_range"] as String,
            agent = params["agent"] as Int,
            noZero = params["noZero"] as Int?
        )
    }

    suspend fun calcDocumentZip(params: HashMap<String, Any>): Respone<ByteArray?, DBQuery> {
        return this.calcDocumentZip(
            doc_type = params["doc_type"] as Int,
            date_range = params["date_range"] as String,
        )
    }

    suspend fun calcApiExcelZip(params: HashMap<String, Any>): Respone<ByteArray?, DBQuery> {
        return this.calcApiExcelZip(
            date_start = params["date_start"] as String,
            date_end = params["date_end"] as String,
            xlsxType = params["xlsxType"] as String?,
            c_ids = params["c_ids"] as List<Int>?,
            ids = params["ids"] as List<Int>?,
        )
    }

    suspend fun calcApiHashavshevetZip(params: HashMap<String, Any>): Respone<ByteArray?, DBQuery> {
        return this.calcApiHashavshevetZip(
            date_start = params["date_start"] as String,
            date_end = params["date_end"] as String,
            ids = params["ids"] as List<Int>?,
            types = params["types"] as List<Int>?,
        )
    }

    suspend fun setClientMonthlyCycle(
        params: HashMap<String, Any>
    ): Respone<MonthlyCycle?, DBQuery> {
        return this.setClientMonthlyCycle(
            date = params["date"]!! as String,
            id = params["id"]!! as Int,
            value = params["value"]!! as Float,
            paid = params["paid"] as Float?,
            date_paid = params["date_paid"] as String?,
            date_updated = params["date_updated"]!! as String
        )
    }


    suspend fun buildClientTaxNote(
        params: HashMap<String, Any>
    ): Respone<List<ClientTaxNote>, DBQuery> {
        return if (params.containsKey("last")) {
            this.buildClientLastTaxNote(
                client_id = params["client_id"] as Int?,
                full = params["full"] as Boolean? ?: false,
                type = params["type"] as Int?,
                client_ids = params["client_ids"] as List<Int>?


            )
        } else {
            val client_ids = (params["client_ids"] as List<Int>?) ?: (params["client_id"] as Int?)?.let {
                val branch = getClient(it).first?.branch
                branch?.let { br ->
                    if (br != -1) getBranchMembers(br).first.map { it.id }
                    else listOf(it)
                } ?: listOf(it)

            }

            return this.buildClientTaxNote(
                client_ids = client_ids,
                id = params["id"] as Int?,
                ids = params["ids"] as List<Int>?,
                fromDate = params["fromDate"] as String?,
                toDate = params["toDate"] as String?,
                full = params["full"] as Boolean? ?: false,
                type = params["type"] as Int?,
                open = params["open"] as Boolean?,
                withProduct = params["withProduct"] as Int?,
                check_date = params["check_date"] as String?,
                order_ids = params["order_ids"] as List<Int>?,
            )
        }

    }

    suspend fun newClientTaxNoteDVWithBranchSplit(
        params: HashMap<String, Any>
    ): Respone<List<String>, DBQuery> {
        return this.newClientTaxNoteDVWithBranchSplit(
            document_date = params["document_date"]!! as String,
            deliveryNotes = getClientDeliveryNotes(ids = params["delivery_notes"] as List<Int>).first,
            discount_percent = params["discount_percent"] as Float?,
            external_details = params["external_details"] as String?,
            agent_id = params["agent_id"] as Int?,
            notes2 = params["notes2"] as String?,
            notes3 = params["notes3"] as String?,
            notes4 = params["notes4"] as String?,
            notes5 = params["notes5"] as String?,
            agent = params["agent"] as String,
            doRound = (params["doRound"] as Boolean?) ?: false,
            splitBranch = (params["splitBranch"] as Boolean?) ?: false,
            increase_id = params["increase_id"] as Int?,
        )
    }

    suspend fun clearCartesert(params: HashMap<String, Any>): DBQuery {
        return this.clearCartesert(
            params["id"] as Int,
            params["date"] as String,
        )
    }

    suspend fun newClientTaxNoteWithSplit(
        params: HashMap<String, Any>
    ): Respone<List<InformationBase>, DBQuery> {
        return this.newClientTaxNoteWithSplit(
            client_id = params["client_id"]!! as Int,
            date = params["date"]!! as String,
            document_date = params["document_date"]!! as String,
            delivery_value = params["delivery_info"]!! as List<ProductDelivery>,
            details = params["details"] as ClientStaticData,
            discount_percent = params["discount_percent"] as Float?,
            type = params["type"]!! as Int,
            with_price = params["with_price"] as Boolean? ?: false,
            external_details = params["external_details"] as String?,
            agent = params["agent"] as String,
            fastPay = params["fastPay"] as PaymentData?,
            round = params["round"] as Boolean,
            order_id = params["order_id"] as Int?,
            agent_id = params["agent_id"] as Int?,
            notes2 = params["notes2"] as String?,
            notes3 = params["notes3"] as String?,
            notes4 = params["notes4"] as String?,
            notes5 = params["notes5"] as String?,
            increase_id = params["increase_id"] as Int?,
        )
    }

    suspend fun newClientTaxNote(
        params: HashMap<String, Any>
    ): Respone<List<ClientTaxNote>, DBQuery> {
        return this.newClientTaxNote(
            client_id = params["client_id"]!! as Int,
            date = params["date"]!! as String,
            document_date = params["document_date"]!! as String,
            value = params["value"]!! as Float,
            total_value = params["total_value"]!! as Float,
            dataValues = params["dataValues"]!! as List<ClientTaxNoteData>,
            details = params["details"] as ClientStaticData,
            discount_percent = params["discount_percent"] as Float?,
            cover_dates = params["cover_dates"] as String?,
            paymentsData = params["paymentsData"]!! as List<PaymentData>,
            type = params["type"]!! as Int,
            extra_delivery_notes_meta = params["extra_notes"] as List<Int>?,
            with_price = params["with_price"] as Boolean? ?: false,
            delivery_notes = params["delivery_notes"] as List<DeliveryNote>?,
            external_details = params["external_details"] as String?,
            agent = params["agent"] as String?,
            order_id = params["order_id"] as Int?,
            tax_note_id = params["tax_note_id"] as Int?,
            agent_id = params["agent_id"] as Int?,
            notes2 = params["notes2"] as String?,
            notes3 = params["notes3"] as String?,
            notes4 = params["notes4"] as String?,
            notes5 = params["notes5"] as String?,
            increase_id = params["increase_id"] as Int?,


            )
    }

    suspend fun cancelClientTaxNote(
        params: HashMap<String, Any>
    ): Respone<ClientTaxNote?, DBQuery> {
        return this.cancelClientTaxNote(
            id = params["id"]!! as Int,
            type = params["type"]!! as Int,
            date = params["date"]!! as String,
            agent = (params["agent"] as String?) ?: "",
            increase_id = params["increase_id"] as Int?,
        )
    }

    suspend fun buildClientCheck(
        params: HashMap<String, Any>
    ): Respone<List<PaymentData>, DBQuery> {
        return buildClientChecks(
            client_id = params["client_id"] as Int?,
            client_ids = params["client_ids"] as List<Int>?,
            last = params["last"] as Int?
        )

    }

    suspend fun buildClientPay(
        params: HashMap<String, Any>
    ): Respone<List<ClientPay>, DBQuery> {
        return if (params.containsKey("last")) {
            this.buildClientLastPay(
                client_id = params["client_id"] as Int?,
                full = params["full"] as Boolean? ?: false,
                client_ids = params["client_ids"] as List<Int>?
            )
        } else {
            val client_ids = (params["client_ids"] as List<Int>?) ?: (params["client_id"] as Int?)?.let {
                val branch = getClient(it).first?.branch
                branch?.let { br ->
                    if (br != -1) getBranchMembers(br).first.map { it.id }
                    else listOf(it)
                } ?: listOf(it)

            }
            return this.buildClientPay(
                id = params["id"] as Int?,
                fromDate = params["fromDate"] as String?,
                toDate = params["toDate"] as String?,
                full = params["full"] as Boolean? ?: false,
                client_ids = client_ids,
                check_date = params["check_date"] as String?,
            )
        }

    }

    suspend fun newClientPay(
        params: HashMap<String, Any>
    ): Respone<ClientPay?, DBQuery> {
        return this.newClientPay(
            client_id = params["client_id"]!! as Int,
            date = params["date"]!! as String,
            document_date = params["document_date"]!! as String,
            value = params["value"]!! as Float,
            details = params["details"] as ClientStaticData,
            paymentsData = params["paymentsData"]!! as List<PaymentData>,
            tax_note_id = params["tax_note_id"] as String?,
            cancel_id = params["cancel_id"] as Int?,
            external_details = params["external_details"] as String?,
            agent = params["agent"] as String?,
            refundRest = (params["refundRest"] as Boolean?),
            type = 0,
            increase_id = params["increase_id"] as Int?,

            )
    }

    suspend fun updateClientPay(
        params: HashMap<String, Any>
    ): Respone<ClientPay?, DBQuery> {
        return this.updateClientPay(
            id = params["id"]!! as Int,
            paymentsData = params["paymentsData"] as List<PaymentData>?,
            tax_note_id = params["tax_note_id"] as String?,
            cancel_id = params["cancel_id"] as Int?,
            reopen_tax_notes = params["reopen_tax_notes"] as String?,
            active = params["active"] as Int?,
            external_details = params["external_details"] as String?,
        )
    }

    suspend fun closePayment(
        params: HashMap<String, Any>
    ): Respone<InformationBase?, DBQuery> {
        val id = params["id"]!! as Int
        val type = params["type"]!! as Int
        val method = params["method"]!! as Int
        val details = params["details"] as String?
        val value = params["value"] as Float
        val checkNumber = params["check_number"] as String?

        val pay = if (type == 7) getClientPay(id = id).first.firstOrNull() else getClientTaxNote(
            id = id,
            type = 1
        ).first.firstOrNull()

        if (pay != null) {
            val payments = if (type == 7) (pay as ClientPay).paymentsData else (pay as ClientTaxNote).paymentsData
            val ind =
                payments.indexOfFirst { it.payMethod.state == method && (it.check_number == checkNumber || it.value == value && (it.paid == null || it.paid == 0)) }
            if (ind != -1) {
                val cur_payments = payments.mapIndexed { index: Int, paymentData: PaymentData ->
                    if (index == ind) {
                        paymentData.copy(paid = 1, paid_date = DatesManipulator.dateNow(), paid_details = details)
                    } else
                        paymentData
                }
                return if (type == 7)
                    this.updateClientPay(
                        id = params["id"]!! as Int,
                        paymentsData = cur_payments,
                    ) as Respone<InformationBase?, DBQuery>
                else
                    this.updateClientTaxNotePay(
                        id = params["id"]!! as Int,
                        paymentsData = cur_payments,
                    ) as Respone<InformationBase?, DBQuery>
            }

        }

        return Respone(null, DBQuery.FAILED)


    }

    suspend fun cancelClientPay(
        params: HashMap<String, Any>
    ): Respone<ClientPay?, DBQuery> {
        return this.cancelClientPay(
            id = params["id"]!! as Int, date = params["date"]!! as String, agent = (params["agent"] as String?)
                ?: "", increase_id = params["increase_id"] as Int?
        )
    }

    suspend fun deActivate(params: HashMap<String, Any>): DBQuery {
        when {
            (params["type"]!! as String).compareTo("client") == 0 -> {
                return deActivate(params["value"]!! as Client)
            }

            (params["type"]!! as String).compareTo("delivery") == 0 -> {
                return deActivate((params["entity"]!! as String), params["value"]!! as DeliveryNote)
            }

            (params["type"]!! as String).compareTo("product") == 0 -> {
                return deActivate((params["entity"]!! as String), params["value"]!! as Product)
            }

            (params["type"]!! as String).compareTo("supplier") == 0 -> {
                return deActivate(params["value"]!! as Supplier)
            }
        }
        return DBQuery.FAILED
    }

    suspend fun activate(params: HashMap<String, Any>): DBQuery {
        when {
            (params["type"]!! as String).compareTo("client") == 0 -> {
                return activate(params["value"]!! as Client)
            }

            (params["type"]!! as String).compareTo("delivery") == 0 -> {
                return activate((params["entity"]!! as String), params["value"]!! as DeliveryNote)
            }

            (params["type"]!! as String).compareTo("product") == 0 -> {
                return activate((params["entity"]!! as String), params["value"]!! as Product)
            }

            (params["type"]!! as String).compareTo("supplier") == 0 -> {
                return activate(params["value"]!! as Supplier)
            }
        }
        return DBQuery.FAILED
    }


    suspend fun newSupplier(
        params: HashMap<String, Any>
    ): Respone<Supplier?, DBQuery> {
        return this.newSupplier(
            name = params["name"]!! as String,
            date = params["date"]!! as String,
            include_tax = params["include_tax"] as Int?,
            business_name = params["business_name"] as String?,
            business_id = params["business_id"] as String?,
            address = params["address"] as String?,
            print_state = params["print_state"] as Int?,
            phone = params["phone"] as String?,
            phone_contact = params["phone_contact"] as String?,
            no_tax_client = params["no_tax_client"] as Int?,
            external_id = params["external_id"] as String?,
            comments = params["comments"] as String?,
            category = params["category"] as String?,
            payment_notes = params["payment_notes"] as String?,
            category2 = params["category2"] as String?,
        )
    }

    suspend fun updateSupplier(
        params: HashMap<String, Any>
    ): Respone<Supplier?, DBQuery> {
        return this.updateSupplier(
            id = params["id"]!! as Int,
            date = params["date"]!! as String,
            name = params["name"] as String?,
            include_tax = params["include_tax"] as Int?,
            business_name = params["business_name"] as String?,
            business_id = params["business_id"] as String?,
            address = params["address"] as String?,
            print_state = params["print_state"] as Int?,
            position = params["position"] as Int?,
            active = params["active"] as Int?,
            phone = params["phone"] as String?,
            no_tax_client = params["no_tax_client"] as Int?,
            external_id = params["external_id"] as String?,
            comments = params["comments"] as String?,
            category = params["category"] as String?,
            payment_notes = params["payment_notes"] as String?,
            category2 = params["category2"] as String?,
            phone_contact = params["phone_contact"] as String?,
        )
    }


    suspend fun newSupplierProduct(
        params: HashMap<String, Any>
    ): Respone<Product?, DBQuery> {
        return this.newSupplierProduct(
            name = params["name"]!! as String,
            date = params["date"]!! as String,
            barcode = params["barcode"] as String?,
            default_price = params["default_price"] as Float?,
            connected_product = params["connected_product"] as Int?,
            no_tax_product = params["no_tax_product"] as Int?,
            category = params["category"] as String?,
            external_id = params["external_id"] as String?,
        )
    }

    suspend fun updateSupplierProduct(params: HashMap<String, Any>): Respone<Product?, DBQuery> {
        return this.updateSupplierProduct(
            id = params["id"]!! as Int,
            date = params["date"]!! as String,
            name = params["name"] as String?,
            barcode = params["barcode"] as String?,
            default_price = params["default_price"] as Float?,
            position = params["position"] as Int?,
            active = params["active"] as Int?,
            connected_product = params["connected_product"] as Int?,
            no_tax_product = params["no_tax_product"] as Int?,
            category = params["category"] as String?,
            external_id = params["external_id"] as String?,
        )
    }


    suspend fun buildSupplierPrices(params: HashMap<String, Any>): Respone<List<RawPrice>, DBQuery> {
        return this.buildSupplierPrices(
            id = params["id"] as Int?,
            product_id = params["product_id"] as Int?,
            fromDate = params["fromDate"] as String,
            toDate = params["toDate"] as String
        )
    }


    suspend fun newSupplierPrice(
        params: HashMap<String, Any>
    ): Respone<RawPrice?, DBQuery> {
        return this.newSupplierPrice(
            id = params["id"]!! as Int,
            product_id = params["product_id"]!! as Int,
            price = params["price"]!! as Float,
            date = params["date"] as String?,
            discount = params["discount"] as Float? ?: 0f,
            toDate = params["toDate"] as String?,
        )
    }

    suspend fun buildSupplierNotes(
        params: HashMap<String, Any>
    ): Respone<List<DeliveryNote>, DBQuery> {
        return if (params.containsKey("last")) {
            this.buildSupplierLastNotes(
                supplier_id = params["supplier_id"] as Int, full = params["full"] as Boolean? ?: false

            )
        } else {
            this.buildSupplierNotes(
                supplier_id = params["supplier_id"] as Int?,
                delivery_id = params["delivery_id"] as Int?,
                fromDate = params["fromDate"] as String?,
                toDate = params["toDate"] as String?,
                full = params["full"] as Boolean? ?: false
            )
        }
    }

    suspend fun closeSupplierNote(
        params: HashMap<String, Any>
    ): Respone<List<DeliveryNote>, DBQuery> {
        return this.closeSupplierDeliveryNote(
            delivery_ids = params["ids"] as List<Int>
        )
    }

    suspend fun updateSupplierNote(
        params: HashMap<String, Any>
    ): Respone<DeliveryNote?, DBQuery> {
        return this.updateSupplierDeliveryNote(
            delivery_id = params["id"] as Int,
            paid = params["paid"] as Int?,
            active = params["active"] as Int?,
            connected_id = params["connected_id"] as Int?,
            delivery_value = params["delivery_value"] as List<ProductDelivery>?,
            notes = params["notes"] as String?,
            notes2 = params["notes2"] as String?,
            notes3 = params["notes3"] as String?,
            notes4 = params["notes4"] as String?,
            notes5 = params["notes5"] as String?,

            )
    }

    suspend fun newSupplierDeliveryNote(
        params: HashMap<String, Any>
    ): Respone<List<DeliveryNote>, DBQuery> {
        return this.newSupplierDeliveryNote(
            agent = params["agent"]!! as String,
            ent_id = params["ent_id"]!! as Int,
            date = params["date"]!! as String,
            date_issued = params["date_issued"]!! as String,
            delivery_value = params["delivery_value"]!! as List<ProductDelivery>,
            connected_id = params["connected_id"] as Int?,
            with_price = params["with_price"] as Boolean? ?: false,
            notes = params["notes"] as String?,
            notes2 = params["notes2"] as String?,
            notes3 = params["notes3"] as String?,
            notes4 = params["notes4"] as String?,
            notes5 = params["notes5"] as String?,
        )
    }

    suspend fun calcSupplierDeliveryNotePdf(params: HashMap<String, Any>): Respone<ByteArray?, DBQuery> {
        val docs = params["ids"] as List<Int>?
        return if (docs != null) {
            val realDocs = getSupplierDeliveryNotes(delivery_ids = docs).first
            val client_id = realDocs.first().ent_id
            val dates = DatesHolder(realDocs.map { it.date }.distinct())
            this.calcSupplierDeliveryNotePdf(
                sup_id = client_id, date_range = dates.toStringFirstLastRange(), specific_notes = docs.joinToString(",")
            )
        } else {
            this.calcSupplierDeliveryNotePdf(
                sup_id = params["client_id"] as Int, date_range = params["date_range"] as String
            )
        }

    }

    suspend fun buildSupplierMonthlyCyclePdf(
        params: HashMap<String, Any>
    ): Respone<ByteArray?, DBQuery> {
        return this.buildSupplierMonthlyCyclePdf(
            sup_name = (params["sup_name"] as String),
            month = params["month"] as String,
            year = params["year"] as String
        )

    }

    suspend fun buildSupplierMonthlyCycle(
        params: HashMap<String, Any>
    ): Respone<List<MonthlyCycle>, DBQuery> {
        return this.buildSupplierMonthlyCycle(
            date = params["date"] as String?, id = params["id"] as Int?, full = params["full"] as Boolean? ?: false
        )
    }

    suspend fun setSupplierMonthlyCycle(
        params: HashMap<String, Any>
    ): Respone<MonthlyCycle?, DBQuery> {
        return this.setSupplierMonthlyCycle(
            date = params["date"]!! as String,
            id = params["id"]!! as Int,
            value = params["value"]!! as Float,
            paid = params["paid"] as Float?,
            date_paid = params["date_paid"] as String?,
            date_updated = params["date_updated"]!! as String
        )

    }

    suspend fun buildTaklitProducts(
        params: HashMap<String, Any>
    ): Respone<List<TaklitProduct>, DBQuery> {
        return this.buildTaklitProducts(
            date = params["date"] as String, id = params["id"] as Int?
        )
    }

    suspend fun calcClientDebtTillNow(params: HashMap<String, Any>): DBQuery {
        return this.calcClientDebtTillNow(
            params["fromDate"] as String,
            params["toDate"] as String,
            params["id"] as Int?,
            withDebtYearly = (params["withDebtYearly"] as Boolean?) ?: false
        )
    }

    suspend fun calcClientDebtTillNowWithMonthly(params: HashMap<String, Any>): DBQuery {
        val debt = this.calcOptimizedCartesetValue(
            ids = (params["id"] as Int?)?.let { listOf(it) },
            toDate = params["toDate"] as String,
        )
        val monthly = buildClientMonthlyCycle(params["date"] as String, id = params["id"] as Int?, full = true)
        if (debt != DBQuery.SUCCESS || monthly.second != DBQuery.SUCCESS)
            return DBQuery.FAILED
        return DBQuery.SUCCESS
    }


    suspend fun calcAmountData(params: HashMap<String, Any>): Respone<Triple<NotesAmountsQuery?, NotesAmountsQuery?, NotesAmountsQuery?>, DBQuery> {
        return this.calcAmountData(
            params["fromDate"] as String,
            params["toDate"] as String,
            params["advancer"] as StateNotifier?,
            params["id"] as Int?,
            (params["withAgent"] as Boolean?) ?: false


        )
    }

    suspend fun prepareMassTaxNote(params: HashMap<String, Any>): Respone<List<TaxNoteMassHolder>, DBQuery> {
        return this.prepareMassTaxNote(
            params["date"] as String,
            client_ids = (params["client_ids"] as List<Int>?)
                ?: ((params["client_id"] as Int?)?.let { listOf(it) }),
            params["fromDate"] as String?
        )
    }

    suspend fun calcDocumentSum(params: HashMap<String, Any>): Respone<ByteArray?, DBQuery> {
        return this.calcDocumentSum(
            params["doc_type"] as Int,
            params["date_range"] as String,
            (params["pdf"] as Boolean?) ?: false,
            active_only = params["active_only"] as Boolean?,
            open_only = params["open_only"] as Boolean?,
            ent_id = params["ent_id"] as List<Int>?,
        )
    }

    suspend fun calcDataToAccountManager(params: HashMap<String, Any>): Respone<ByteArray?, DBQuery> {
        return this.dataToAccountManager(
            params["date_range"] as String
        )
    }

    suspend fun calcOrderAmountData(params: HashMap<String, Any>): Respone<Pair<NotesAmountsQuery?, NotesAmountsQuery?>, DBQuery> {
        return this.calcOrdersAmountData(
            params["fromDate"] as String,
            params["toDate"] as String,
            params["advancer"] as StateNotifier?,
            (params["withAgent"] as Boolean?) ?: false
        )
    }

    suspend fun duplicateTomorowsOrder(params: HashMap<String, Any>): DBQuery {
        return this.duplicateTomorowsOrder(date = params["date"] as String, toDate = params["toDate"] as String?)
    }

    companion object {
        lateinit var activeDB: UINKDBInterface
    }

    suspend fun buildOrderProducts(
        params: HashMap<String, Any>
    ): Respone<List<OrderProduct>, DBQuery> {
        return this.buildOrderProducts(
            id = params["id"] as Int?,
            product_id = params["product_id"] as Int?,
            available = params["available"] as Boolean?,
            active = params["active"] as Boolean?,
        )
    }

    suspend fun newOrderProducts(
        params: HashMap<String, Any>
    ): Respone<OrderProduct?, DBQuery> {
        return this.newOrderProducts(
            id = params["id"] as Int,
            product_id = params["product_id"] as Int,
            extra_unit = params["extra_unit"] as Int,
            conversion_rate = params["conversion_rate"] as Float,
            available = params["available"] as Boolean,
            active = params["active"] as Boolean,
            weight = params["weight"] as Float,
            volume = params["volume"] as Float,
            englishName = params["englishName"] as String,
            orderName = params["orderName"] as String?,
            position = params["position"] as Int?,
            sales = params["sales"] as Int?,
            forceLock = params["forceLock"] as Int?,
            hourLock = params["hourLock"] as String?,
            dayLock = params["dayLock"] as Int?,
            step = params["step"] as Float?,
            collector = params["collector"] as Int?,
            min_order = params["min_order"] as Float?,
            unit_preferece = params["unit_preferece"] as Int?,

            )
    }


    suspend fun buildCofcClients(
        params: HashMap<String, Any>
    ): Respone<List<Client>, DBQuery> {
        return this.buildCofcClients(
            id = params["id"] as Int,
            date = params["date"] as String,
        )
    }

    suspend fun getCofcUser(
        params: HashMap<String, Any>
    ): Respone<User?, DBQuery> {
        return this.getCofcUser(
            id = params["id"] as Int
        )
    }

    suspend fun registerCofcId(
        params: HashMap<String, Any>
    ): Respone<String?, DBQuery> {
        return this.registerCofcId(
            id = params["id"] as Int, until = params["date"] as String, open_date = params["open_date"] as String?
        )
    }

    suspend fun getCofcLink(
        params: HashMap<String, Any>
    ): Respone<Triple<String?, Int, String?>?, DBQuery> {
        val link = this.registerCofcId(
            id = params["id"] as Int, until = params["date"] as String, open_date = params["open_date"] as String?
        )

        return Respone(Triple(link.first, params["id"] as Int, params["open_date"] as String?), link.second)
    }

    suspend fun getCofcProducts(
        params: HashMap<String, Any>
    ): Respone<List<Product>, DBQuery> {
        return this.getCofcProducts(
            id = params["id"] as Int,
            date = params["date"] as String,
        )
    }

    suspend fun buildProductImages(
        params: HashMap<String, Any>
    ): DBQuery {
        return this.buildProductImages(
            id = params["id"] as Int,
            date = params["date"] as String,
            product_id = params["product_id"] as Int?,
        )
    }

    suspend fun getCofcOrderProducts(
        params: HashMap<String, Any>
    ): Respone<List<OrderProduct>, DBQuery> {
        return this.getCofcOrderProducts(
            id = params["id"] as Int,
            op_id = params["op_id"] as Int?,
            product_id = params["product_id"] as Int?,
            available = params["available"] as Boolean?,
            active = params["active"] as Boolean?,
        )
    }

    suspend fun getCofcRealImagesAvailable(
        params: HashMap<String, Any>
    ): Respone<List<Int>, DBQuery> {
        return this.getCofcRealImagesAvailable(
            id = params["id"] as Int
        )
    }

    suspend fun getCofcProductsImage(
        params: HashMap<String, Any>
    ): Respone<ByteArray?, DBQuery> {
        return this.getCofcProductsImage(
            id = params["id"] as Int,
            product_id = params["product_id"] as Int,
            date = params["date"] as String,
        )
    }

    suspend fun getClientProductImg(
        params: HashMap<String, Any>
    ): DBQuery {
        return this.getClientProductsImage(
            product_id = params["product_id"] as Int?
        )
    }

    suspend fun getCofcClientsPrices(
        params: HashMap<String, Any>
    ): Respone<List<RawPrice>, DBQuery> {
        return this.getCofcClientsPrices(
            id = params["id"] as Int,
            date = params["date"] as String,
        )
    }

    suspend fun getCofcClientsOrder(
        params: HashMap<String, Any>
    ): Respone<List<OrderNote>, DBQuery> {
        return this.getCofcClientsOrder(
            id = params["id"] as Int,
            dateFrom = params["dateFrom"] as String,
            dateTo = params["dateTo"] as String,
            status = params["status"] as Int?,
        )
    }

    suspend fun newCofcClientOrderNote2(
        params: HashMap<String, Any>
    ): Respone<OrderNote?, DBQuery> {
        return this.newCofcClientOrderNote(
            agent = params["agent"]!! as String,
            ent_id = params["ent_id"]!! as Int,
            date = params["date"]!! as String,
            date_issued = params["date_issued"]!! as String,
            delivery_value = params["delivery_value"]!! as List<ProductDelivery>,
            sup_delivery_info = params["sup_delivery_info"]!! as List<ProductDelivery>,
            active = (params["active"]!! as Int?) ?: 1,
            notes = params["notes"] as String?,
            category = params["category"] as String?,
            order_id = params["order_id"] as Int?,

            )
    }

    suspend fun updateCofcClientOrderNote2(
        params: HashMap<String, Any>
    ): Respone<OrderNote?, DBQuery> {
        return this.updateCofcClientOrderNote(
            order_id = params["order_id"]!! as Int,
            agent = params["agent"] as String?,
            ent_id = params["ent_id"]!! as Int,
            date = params["date"]!! as String,
            delivery_value = params["delivery_value"]!! as List<ProductDelivery>,
            sup_delivery_info = params["sup_delivery_info"]!! as List<ProductDelivery>,
            active = (params["active"]!! as Int?) ?: 1,
            notes = params["notes"] as String?,
            category = params["category"] as String?,
        )
    }

    suspend fun uploadImg(
        params: HashMap<String, Any>
    ): DBQuery {
        return this.uploadImg(
            f = params["image"]!! as ByteArray, product_id = params["product_id"] as Int
        )
    }

    suspend fun uploadLogo(
        params: HashMap<String, Any>
    ): DBQuery {
        return this.uploadLogo(
            f = params["image"]!! as ByteArray
        )
    }

    suspend fun uploadSign(
        params: HashMap<String, Any>
    ): Respone<NoteSignHolder?, DBQuery> {
        return this.uploadSign(
            f = params["image"]!! as ByteArray,
            note_id = params["note_id"]!! as Int,
            signer = params["signer"]!! as String,
            date = params["date"]!! as String,
            doc_type = params["doc_type"]!! as Int,
            offline = (params["offline"] as Boolean?) ?: false
        )
    }

    suspend fun uploadPhoto(
        params: HashMap<String, Any>
    ): Respone<NoteSignHolder?, DBQuery> {
        return this.uploadPhoto(
            f = params["image"]!! as ByteArray,
            note_id = params["note_id"]!! as Int,
            date = params["date"]!! as String,
            doc_type = params["doc_type"]!! as Int,
            offline = (params["offline"] as Boolean?) ?: false
        )
    }

    suspend fun getSignImg(
        params: HashMap<String, Any>
    ): Respone<Triple<ByteArray?, Int, Int>, DBQuery> {
        return this.getSignImg(
            note_id = params["note_id"]!! as Int,
            doc_type = (params["doc_type"] as Int?) ?: 4,
            strict = (params["strict"] as Boolean?) ?: false
        )
    }

    suspend fun getSignPhoto(
        params: HashMap<String, Any>
    ): Respone<Triple<ByteArray?, Int, Int>, DBQuery> {
        return this.getSignPhoto(
            note_id = params["note_id"]!! as Int,
            doc_type = (params["doc_type"] as Int?) ?: 4,
            strict = (params["strict"] as Boolean?) ?: false
        )
    }

    suspend fun getSign(
        params: HashMap<String, Any>
    ): Respone<List<NoteSignHolder>, DBQuery> {
        return this.getSign(
            ids = params["note_ids"]!! as List<Int>,
            doc_type = (params["doc_type"] as Int?) ?: 4,
        )
    }

    suspend fun newClientCarteset(
        params: HashMap<String, Any>
    ): Respone<ClientCarteset?, DBQuery> {
        return this.newClientCarteset(
            client_id = params["id"]!! as Int,
            date = params["date"]!! as String,
            positive = params["positive"]!! as Float,
            negative = params["negative"]!! as Float,
            state = (params["state"] as Int?) ?: 0,
            notes = params["notes"] as String?,
        )
    }

    suspend fun newAllClientCarteset(
        params: HashMap<String, Any>
    ): Respone<List<ClientCarteset>, DBQuery> {
        return this.newAllClientCarteset(
            date = params["date"]!! as String,
            carteset = params["carteset"]!! as List<ClientCarteset>,
        )
    }

    suspend fun buildClientCartesetMap(
        params: HashMap<String, Any>
    ): Respone<List<ClientCarteset>, DBQuery> {
        return this.buildClientCartesetMap(
            id = params["id"] as Int?,
            fromDate = params["fromDate"]!! as String,
            toDate = params["toDate"]!! as String,
            state = (params["state"] as Int?),
            ids = params["ids"] as List<Int>?
        )
    }


    suspend fun newClientProductApi(
        params: HashMap<String, Any>, update: Boolean = true
    ): Respone<Product?, DBQuery> {
        val id = params["product_id"] as Int?
        val product = if (id == null) {
            this.newClientProduct(
                name = params["name"]!! as String,
                date = params["date"]!! as String,
                barcode = params["barcode"] as String?,
                default_price = params["default_price"] as Float?,
                no_tax_product = params["no_tax_product"] as Int?,
                external_id = params["external_id"] as String?,
                unit = (params["unit"] as String?)?.let { WrappedName.convertFrom(it)?.state },
                unit_amount = params["unit_amount"] as Float?,
                category = params["category"] as String?,
                cost_price = params["cost_price"] as Float?,
                split_category = params["split_category"] as Int?,
                position = params["position"] as Int?,
                base_price = params["base_price"] as Float?,
                cost_include_returns = params["cost_include_returns"] as Int?,
                category2 = params["category2"] as String?,
                taklit_id = params["taklit_id"] as Int?,
                taklit_type = params["taklit_type"] as Int?,
                id = params["internal_id"] as Int?
            )
        } else {
            if (update)
                this.updateClientProduct(
                    id = id,
                    name = params["name"] as String?,
                    date = params["date"]!! as String,
                    barcode = params["barcode"] as String?,
                    default_price = params["default_price"] as Float?,
                    no_tax_product = params["no_tax_product"] as Int?,
                    external_id = params["external_id"] as String?,
                    taklit_id = params["taklit_id"] as Int?,
                    taklit_type = params["taklit_type"] as Int?,
                    unit = (params["unit"] as String?)?.let { WrappedName.convertFrom(it)?.state },
                    unit_amount = params["unit_amount"] as Float?,
                    category = params["category"] as String?,
                    cost_price = params["cost_price"] as Float?,
                    split_category = params["split_category"] as Int?,
                    position = params["position"] as Int?,
                    base_price = params["base_price"] as Float?,
                    cost_include_returns = params["cost_include_returns"] as Int?,
                    active = (params["active"] as Boolean?)?.let { if (it) 1 else 0 },
                    category2 = params["category2"] as String?,
                )
            else getClientProduct(id)
        }
        if (product.second != DBQuery.SUCCESS) {
            return product
        }
        val newOrderProduct = if (update) this.newOrderProducts(id = (params["order_product_id"] as Int?) ?: -1,
            product_id = product.first!!.id,
            extra_unit = (params["extra_unit"] as String?)?.let { WrappedName.convertFrom(it)?.state } ?: 0,
            conversion_rate = (params["conversion_rate"] as Float?) ?: 0f,
            available = (params["available"] as Boolean?) ?: true,
            active = true,
            weight = (params["weight"] as Float?),
            volume = (params["volume"] as Float?),
            englishName = (params["englishName"] as String?),
            orderName = (params["orderName"] as String?),
            sales = (params["sales"] as Int?) ?: 0,
            forceLock = (params["forceLock"] as Int?) ?: 0,
            hourLock = (params["hourLock"] as String?) ?: "",
            dayLock = (params["dayLock"] as Int?) ?: 127,
            step = (params["step"] as Float?) ?: 0f,
            collector = (params["collector"] as Int?) ?: -1,
            min_order = (params["min_order"] as Float?) ?: 0f,
            unit_preferece = (params["unit_preferece"] as Int?),
            position = (params["orderPosition"] as Int?))
        else
            product.first?.getOrderProduct()?.let { Respone(it, DBQuery.SUCCESS) } ?: Respone(null, DBQuery.SUCCESS)

        if (newOrderProduct.second != DBQuery.SUCCESS) {
            return Respone(product.first, newOrderProduct.second)
        }
        if (params["image"] != null) {

            val byteArr = params["image"]!! as ByteArray
            val img = this.uploadImg(
                f = byteArr, product_id = product.first!!.id
            )
            if (img != DBQuery.SUCCESS) {
                return Respone(product.first, img)
            }
        }

        if (params.containsKey("addToSupplier") && !(product.first!!.getConnectedProduct()
                .isNotEmpty() || getAllSupplierProduct(false).first.any {
                it.getName().compareTo(product.first!!.getName()) == 0
            })
        ) {
            val newSupProduct = this.newSupplierProduct(
                name = product.first.name,
                date = params["date"]!! as String,
                barcode = product.first.barcode,
                default_price = 0f,
                connected_product = product.first.id,
                no_tax_product = product.first.no_tax_product,
                category = product.first.category
            )
            if (newSupProduct.second != DBQuery.SUCCESS) {
                return Respone(product.first, newSupProduct.second)
            }
        }
        return product
    }


    // API
    suspend fun buildClientApiNotes(
        params: HashMap<String, Any>
    ): Respone<List<ApiNote>, DBQuery> {

        return this.getApiNote(
            doc_ids = params["ids"] as List<Int>?,
            doc_type = params["doc_type"] as Int?,
            connected_id = params["connected_id"] as Long?,
            api_method = params["api_method"] as Int?,
            send = params["send"] as Int?,
            fromDate = params["fromDate"] as String?,
            toDate = params["toDate"] as String?,
            full = params["full"] as Boolean? ?: false,
            retries = params["retries"] as Int?,
        )
    }

    suspend fun setClientApiNotes(
        params: HashMap<String, Any>
    ): Respone<List<ApiNote>, DBQuery> {

        return this.setApiNote(
            doc_id = params["doc_id"] as Int,
            doc_type = params["doc_type"] as Int,
            api_method = params["api_method"] as Int,
            doc_date = params["doc_date"] as String?,
            connected_id = params["connected_id"] as Long?,
            ent_id = params["ent_id"] as Int?,
            send = params["send"] as Int?,
            reason = params["reason"] as String?,
            retries = params["retries"] as Int?,
        )
    }


    suspend fun getAllSpecificClientDocuments(
        params: HashMap<String, Any>
    ): Respone<List<InformationBase>, DBQuery> {
        val client_id = params["client_id"] as Int?
        val client_ids = params["client_ids"] as List<Int>?
        val driver_id = params["driver_id"] as Int?

        val client = client_id?.let { getClient(it).first }
        val fromDate = params["fromDate"] as String
        val toDate = params["toDate"] as String
        val fromDrivers = client_ids ?: driver_id?.let {
            buildClientsDailyData(date = toDate, driver_id = listOf(it)).first.map { it.id }
        }
        val full = (params["full"] as Boolean?) ?: false
        val apis = (params["api"] as Boolean?) ?: false
        val tax_only = (params["tax_only"] as Boolean?) ?: false
        val visit = (params["visit"] as Boolean?) ?: false
        val onlyProducts = (params["onlyProducts"] as Boolean?) ?: false
        val cost = (params["cost"] as Boolean?) ?: false
        val result: MutableList<InformationBase> = mutableListOf()
        if (!tax_only) {
            val dvs_raw = if (full && false) {
                this.getOptimizedClientNote(
                    ent_id = fromDrivers ?: client_id?.let { listOf(it) },
                    fromDate = fromDate,
                    toDate = toDate,
                    full = full
                )
            } else {
                this.buildClientNotes(
                    client_id = client_id, fromDate = fromDate, toDate = toDate, full = false, client_ids = fromDrivers
                )
            }

            if (dvs_raw.second != DBQuery.SUCCESS) {
                return Respone(listOf(), dvs_raw.second)
            }
            result.addAll(dvs_raw.first)

            val order_raw = if (full && false) this.getOptimizedClientNote(
                ent_id = fromDrivers ?: client_id?.let { listOf(it) },
                fromDate = fromDate,
                toDate = toDate,
                full = full,
                order = true
            )
            else {
                this.buildClientOrderNotes(

                    fromDate = fromDate,
                    toDate = toDate,
                    full = false,
                    withoutInventory = true,
                    client_ids = fromDrivers ?: client_id?.let { listOf(it) }
                )
            }
            if (order_raw.second != DBQuery.SUCCESS) {
                return Respone(listOf(), order_raw.second)
            }
            result.addAll(order_raw.first)
        }

        val ids = fromDrivers?.let { getAllClientsWithBranches(it).first } ?: if (client?.hasBranch() == true) {
            getBranchMembers(client.branch, false).first.map { it.id }

        } else {
            client_id?.let { listOf(it) }
        }
        val tax_notes = this.buildClientTaxNote(
            client_ids = ids,
            fromDate = fromDate,
            toDate = toDate,
            full = false,
            withProduct = if (onlyProducts) 1 else null
        )
        if (tax_notes.second != DBQuery.SUCCESS) {
            return Respone(listOf(), tax_notes.second)
        }
        result.addAll(tax_notes.first)
        if (!onlyProducts) {
            val pay_notes = this.buildClientPay(
                client_ids = ids, fromDate = fromDate, toDate = toDate, full = false
            )
            if (pay_notes.second != DBQuery.SUCCESS) {
                return Respone(listOf(), pay_notes.second)
            }
            result.addAll(pay_notes.first)

            val debts = this.buildClientCartesetMap(
                ids = ids, fromDate = fromDate, toDate = toDate
            )
            if (debts.second != DBQuery.SUCCESS) {
                return Respone(listOf(), debts.second)
            }
            result.addAll(debts.first)

        }

        if (visit) {
            val visiting = this.buildClientVisitNote(
                ent_ids = ids, fromDate = fromDate, toDate = toDate, full = full
            )
            if (visiting.second != DBQuery.SUCCESS) {
                return Respone(listOf(), visiting.second)
            }
            result.addAll(visiting.first)
        }
        if (cost) {
            val costs = buildClientCosts(fromDate, toDate, listOf(-1))
        }
        val q = if (full)
            buildFullOnDocs(result, api = true)
        else DBQuery.SUCCESS
        return Respone(result, q)

    }

    suspend fun getDocumentPDF(
        params: HashMap<String, Any>
    ): Respone<ByteArray?, DBQuery> {

        val doc = params["doc"] as InformationBase
        val origin = (params["origin"] as Boolean?)?.let { if (it) 1 else 0 } ?: 1
        val type = doc.getConnectedDocType()
        val date = doc.getConnectedDate()
        val year = DatesManipulator.getYear(date)
        val month = number_to_month[DatesManipulator.getMonth(date).toInt()]!!
        val ent_id = doc.getConnectedEntId()
        val doc_id = doc.getConnectedId()
        return when (type) {
            DocType.TAX_NOTE.state, DocType.TAX_PAY_NOTE.state, DocType.TAX_NOTE_CANCEL.state -> {
                val client = (doc as ClientTaxNote).getClient()
                buildClientTaxNoteCyclePdf(
                    client_name = client.getName(date),
                    month = month,
                    year = year,
                    id = doc_id,
                    type = type,
                    origin = origin
                )
            }

            DocType.DELIVERY_NOTES.state -> {
                buildClientDeliveryNotePdf(
                    id = doc_id, origin = origin
                )
            }

            DocType.ORDERS.state -> {
                calcClientOrderPdf(
                    ids = listOf(doc_id)
                )
            }

            DocType.SUP_DELIVERY_NOTES.state -> {
                calcSupplierDeliveryNotePdf(
                    sup_id = doc_id, date_range = date, specific_notes = "$doc_id"
                )
            }

            DocType.PAY_NOTE.state -> {
                val client = (doc as ClientPay).getClient()
                buildClientPayCyclePdf(
                    client_name = client.getName(date), month = month, year = year, id = doc_id, origin = origin
                )
            }

            else -> {
                Respone(null, DBQuery.FAILED)
            }
        }
    }

    suspend fun closeDocuments(
        params: HashMap<String, Any>
    ): DBQuery {
        val docs = params["docs"] as List<Int>
        val types = params["doc_types"] as List<Int>
        val pairs = docs.zip(types)
        pairs.groupBy { it.second }.forEach {
            when (it.key) {
                DocType.TAX_NOTE.state, DocType.TAX_PAY_NOTE.state, DocType.TAX_NOTE_CANCEL.state -> {
                    val ids = it.value.map { it.first }
                    val q = updateAllClientTaxNotes(it.value.map { it.first },
                        it.value.map { it.second },
                        active_state = it.value.map { 2 })

                    if (q.second != DBQuery.SUCCESS) {
                        return q.second
                    }
                }

                DocType.DELIVERY_NOTES.state -> {
                    val ids = it.value.map { it.first }
                    val q = updateAllClientNotes(delivery_ids = ids, paid = it.value.map { 1 })

                    if (q.second != DBQuery.SUCCESS) {
                        return q.second
                    }
                }

            }
        }
        return DBQuery.SUCCESS
    }

    suspend fun activeDocument(
        params: HashMap<String, Any>
    ): Respone<InformationBase?, DBQuery> {
        val doc = params["doc"] as InformationBase
        val date = params["date"] as String
        val active = params["active"] as Boolean
        val agent = params["agent"] as String
        val type = doc.getConnectedDocType()
        return when (type) {
            DocType.TAX_NOTE.state, DocType.TAX_PAY_NOTE.state, DocType.TAX_NOTE_CANCEL.state -> {
                cancelClientTaxNote(
                    id = doc.getConnectedId(), type = type, date = date, agent = agent
                ) as Respone<InformationBase?, DBQuery>
            }

            DocType.DELIVERY_NOTES.state -> {
                updateClientNote(
                    delivery_id = doc.getConnectedId(), active = if (active) 1 else 0

                ) as Respone<InformationBase?, DBQuery>
            }

            DocType.ORDERS.state -> {
                updateClientOrderNote(
                    order_id = doc.getConnectedId(), active = if (active) 1 else 0

                ) as Respone<InformationBase?, DBQuery>
            }

            DocType.PAY_NOTE.state -> {
                cancelClientPay(
                    id = doc.getConnectedId(), date = date, agent = agent
                ) as Respone<InformationBase?, DBQuery>
            }

            DocType.SUP_DELIVERY_NOTES.state -> {
                updateSupplierDeliveryNote(
                    delivery_id = doc.getConnectedId(), active = if (active) 1 else 0
                ) as Respone<InformationBase?, DBQuery>
            }

            DocType.VISIT.state -> {
                updateClientVisitNote(
                    id = doc.getConnectedId(), active = active
                ) as Respone<InformationBase?, DBQuery>
            }

            else -> {
                Respone(null, DBQuery.FAILED)
            }
        }
    }

    suspend fun queryData(
        params: HashMap<String, Any>
    ): Respone<List<DataQueryHolder?>?, DBQuery> {

        return this.queryData(
            fromDate = params["fromDate"] as String,
            toDate = params["toDate"] as String,
            byProduct = params["byProduct"] as List<Int>?,
            byEnt = params["byEnt"] as List<Int>?,
            byAgent = params["byAgent"] as List<String>?,
            byMoney = params["byMoney"] as Boolean?,
            byCost = params["byCost"] as Boolean?,
            byMonth = params["byMonth"] as Int?,
            byYear = params["byYear"] as Int?,

            doc_type = (params["doc_type"] as Int?) ?: 4,
            withDaily = (params["withDaily"] as Boolean?) ?: false,
            cofc = (params["cofc"] as Int?),
            paid = (params["paid"] as Boolean?),
            filter_date = params["filter_date"] as String?,
        )
    }

    suspend fun queryBaseDays(
        params: HashMap<String, Any>
    ): Respone<List<DataQueryHolder?>?, DBQuery> {
        val realFrom = params["fromDate"] as String
        val realTo = params["toDate"] as String
        val fromDate = baseOrderDaysToData[1]
        val toDate = baseOrderDaysToData[6]
        val data = this.queryData(
            fromDate = fromDate!!,
            toDate = toDate!!,
            byProduct = params["byProduct"] as List<Int>?,
            byEnt = params["byEnt"] as List<Int>?,
            byDay = -1,
            byAgent = params["byAgent"] as List<String>?,
            byMoney = params["byMoney"] as Boolean?,
            byCost = params["byCost"] as Boolean?,
            byMonth = params["byMonth"] as Int?,
            byYear = params["byYear"] as Int?,
            doc_type = 10,
            withDaily = false,
        )
        if (data.second != DBQuery.SUCCESS) {
            return Respone(listOf(), data.second)
        }
        val daysMapCountBetween =
            DatesManipulator.getDatesRange(realFrom, realTo).map { DatesManipulator.getDayOfWeekIsrael(it) }
                .groupingBy { it }.eachCount()

        data.first?.forEach {
            it?.let {
                it.multiply(((daysMapCountBetween[it.byDay] ?: 1).toFloat()))


            }
        }
        val g = DataQueryHolder.toGroupAggregate(
            data.first as List<DataQueryHolder>,
            false,
            false,
            false,
            (params["byDay"] as Boolean?) ?: false,
            true,
            true,
            false
        )
        return Respone(g, DBQuery.SUCCESS)
    }

    suspend fun changeBaseAmount(
        params: HashMap<String, Any>
    ): Respone<List<OrderNote>, DBQuery> {
        val fromDate = baseOrderDaysToData[1]
        val toDate = baseOrderDaysToData[6]
        val data = params["data"] as List<baseChangeHolder>
        val clients = data.map { it.c_id }.distinct()

        val o = buildClientOrderNotes(
            client_ids = clients,
            fromDate = fromDate!!,
            toDate = toDate!!,
        )



        if (o.second != DBQuery.SUCCESS) {
            return Respone(o.first, o.second)
        }
        val isNull = data.filter { it.day==null }
        var useData = data.filter { it.day!=null }.toMutableList()
        if(isNull.isNotEmpty())
        {
            var insertion: MutableList<baseChangeHolder> = mutableListOf()
            // we need algo to fiex the data
            val bases = isNull.groupBy { Pair(it.c_id, it.p_id) }
            o.first.forEach { on->
            on.delivery_info.forEach {
                insertion.add(baseChangeHolder(on.ent_id, it.productId, it.value+it.wrapped_amount*it.conversion_ratio,day=DatesManipulator.getDayOfWeekIsrael(on.date)))
            }
        }
            var newBases :MutableList<baseChangeHolder> = mutableListOf()
            val orderInsertion = insertion.groupBy { Pair(it.c_id, it.p_id) }
            bases.forEach { (k, v) ->
                if(orderInsertion.containsKey(k))
                {
                    val base = v.first()
                    val orders = orderInsertion[k]!!
                    val curSum = orders.sumByDouble { it.amount.toDouble() }
                    var baseAmount  = base.amount
                    orders.forEachIndexed { index, baseChangeHolder ->
                        val ratioAmount=(baseChangeHolder.amount/curSum * baseAmount).roundToInt().toFloat()

                        if(index==orders.size-1)
                        {
                            newBases.add(baseChangeHolder(base.c_id, base.p_id, baseAmount, day = baseChangeHolder.day))
                        }
                        else
                        {
                            newBases.add(baseChangeHolder(base.c_id, base.p_id, ratioAmount, day = baseChangeHolder.day))
                            baseAmount-=ratioAmount
                        }

                    }

                }
            }
            useData.addAll(newBases)

        }



        val bases = useData.groupBy { Triple(it.c_id, it.p_id, it.day) }


        val insertion: MutableList<List<ProductDelivery>> = mutableListOf()
        val ids: MutableList<Int> = mutableListOf()
        o.first.forEach { on ->
            val day = DatesManipulator.getDayOfWeekIsrael(on.date)
            var changed=false
            val dvs = on.delivery_info.mapNotNull {
                val key = Triple(on.ent_id, it.productId, day)
                if (bases.containsKey(key)) {
                    val base = bases[key]!!.first()
                    changed=true
                    if(base.amount==0f)
                        null
                    else it.copy(value = base.amount, wrapped_amount = 0f)


                } else
                    it
            }
            if(changed) {
                insertion.add(dvs)
                ids.add(on.order_id)
            }

        }
        if(ids.isNotEmpty())
        return this.updateAllClientOrders(
            order_ids = ids, pds = insertion
        )
        return Respone(listOf(), DBQuery.SUCCESS)

//        o.first.forEach { on->
//            on.delivery_info.forEach {
//                insertion.add(baseChangeHolder(on.ent_id, it.productId, it.value+it.wrapped_amount*it.conversion_ratio,day=DatesManipulator.getDayOfWeekIsrael(on.date)))
//            }
//        }
//        val orders = insertion.groupBy { Pair(it.c_id,it.p_id) }
//        val newInsert:MutableList<baseChangeHolder> = mutableListOf()
//
//        orders.forEach {
//            val key = it.key
//            val base = bases[key]
//            val order = it.value
//            val baseData = base?.first()
//            val orderData = order.first()
//            if(baseData != null && orderData != null){
//                val newAmount = baseData.amount - orderData.amount
//                if(newAmount != 0f){
//                    newInsert.add(baseChangeHolder(baseData.ent_id, baseData.p_id, newAmount, day = baseData.day))
//                }
//            }
//        }
//        bases.forEach {
//            val client = it.key
//            val clientOrders = orders[client] ?: listOf()
//            val clientData = it.value
//
//
//
//        }


    }

    suspend fun matchData(
        params: HashMap<String, Any>
    ): Respone<Pair<List<DataQueryHolder?>?, List<DataQueryHolder?>?>, DBQuery> {
        val first_query = params["first_query"] as Int
        val second_query = params["second_query"] as Int

        val first = this.queryData(
            fromDate = params["fromDate"] as String,
            toDate = params["toDate"] as String,
            byProduct = params["byProduct"] as List<Int>?,
            byEnt = params["byEnt"] as List<Int>?,
            byAgent = params["byAgent"] as List<String>?,
            byMoney = params["byMoney"] as Boolean?,
            byCost = params["byCost"] as Boolean?,
            doc_type = first_query,
            withDaily = (params["withDaily"] as Boolean?) ?: false,
            cofc = (params["cofc"] as Int?),
            paid = (params["paid"] as Boolean?),
            filter_date = params["filter_date"] as String?,
        )
        val second = this.queryData(
            fromDate = params["fromDate"] as String,
            toDate = params["toDate"] as String,
            byProduct = params["byProduct"] as List<Int>?,
            byEnt = params["byEnt"] as List<Int>?,
            byAgent = params["byAgent"] as List<String>?,
            byMoney = params["byMoney"] as Boolean?,
            byCost = params["byCost"] as Boolean?,
            doc_type = second_query,
            withDaily = (params["withDaily"] as Boolean?) ?: false,
            cofc = (params["cofc"] as Int?),
            paid = (params["paid"] as Boolean?),
            filter_date = params["filter_date"] as String?,
        )
        if (first.second == DBQuery.SUCCESS && second.second == DBQuery.SUCCESS) {
            return Respone(Pair(first.first, second.first), DBQuery.SUCCESS)
        }
        return Respone(Pair(null, null), DBQuery.FAILED)
    }


    suspend fun newClientsAllNotes(
        params: HashMap<String, Any>
    ): Respone<List<InformationBase>, DBQuery> {
        var date = params["date"] as String?
        var order_id = params["order_ids"] as List<Int?>?
        val ids = params["ids"] as List<Int>
        val pds = params["pds"] as List<List<ProductDelivery>>
        var notes = (params["notes"] as List<String>?)?.map {
            prepareStrToJson(it)

        }
        var notes2 = (params["notes2"] as List<String>?)?.map {
            prepareStrToJson(it)

        }
        var notes3 = (params["notes3"] as List<String>?)?.map {
            prepareStrToJson(it)

        }
        var notes4 = (params["notes4"] as List<String>?)?.map {
            prepareStrToJson(it)

        }
        var notes5 = (params["notes5"] as List<String>?)?.map {
            prepareStrToJson(it)

        }
        var agent = params["agent"] as String
        val agents = getAgents().first
        var orderAgents = (params["orderAgents"] as List<String>?)?.map {
            val driver = agents.firstOrNull { ag -> ag.user_name == it }?.id ?: -1
            driver

        }
        return this.newAllClientNotes(
            date = date!!,
            agent = agent,
            agent_id = orderAgents,
            ids = ids,
            pds = pds,
            order_id = order_id,
            notes = notes,
            notes2 = notes2,
            notes3 = notes3,
            notes4 = notes4,
            notes5 = notes5,
            withTaxNotes = (params["withTaxNotes"] as Boolean?) ?: false,
            round = (params["round"] as Boolean?) ?: false,

            )
    }

    suspend fun updateAllClientTaxNotes(
        params: HashMap<String, Any>
    ): Respone<List<ClientTaxNote>, DBQuery> {

        var ids = params["ids"] as List<Int>
        var types = params["types"] as List<Int>
        var active_state = params["active_state"] as List<Int>?
        var order_id = params["order_id"] as List<Int>?
        var external_details = params["external_details"] as List<String>?
        var payments = params["paymentsData"] as List<List<PaymentData>>?
        var notes2 = params["notes2"] as List<String>?
        var notes3 = params["notes3"] as List<String>?
        var notes4 = params["notes4"] as List<String>?
        var notes5 = params["notes5"] as List<String>?
        var taxConfirmation = params["taxConfirmation"] as List<String>?
        var agent = params["agent"] as String?
        val agents = getAgents().first
        var orderAgents = (params["orderAgents"] as List<String>?)?.map {
            val driver = agents.firstOrNull { ag -> ag.user_name == it }?.id ?: -1
            driver

        }

        return this.updateAllClientTaxNotes(
            ids = ids,
            types = types,
            active_state = active_state,
            external_details = external_details,
            order_id = order_id,
            notes2 = notes2,
            notes3 = notes3,
            notes4 = notes4,
            notes5 = notes5,
            payments = payments,
            agent = agent,
            agent_id = orderAgents,
            taxConfirmation = taxConfirmation,
            increase_id = params["increase_id"] as Int?
        )
    }

    suspend fun updateAllClientNotes(
        params: HashMap<String, Any>
    ): Respone<List<DeliveryNote>, DBQuery> {
        var delivery_ids = params["delivery_ids"] as List<Int>
        var active = params["actives"] as List<Int>?
        var order_id = params["order_ids"] as List<Int>?
        var paid = params["paids"] as List<Int>?
        var pdf = params["pdf"] as List<Int>?
        return this.updateAllClientNotes(
            delivery_ids = delivery_ids, active = active, order_id = order_id, paid = paid, pdf = pdf
        )
    }

    suspend fun updateAllClientOrders(
        params: HashMap<String, Any>
    ): Respone<List<OrderNote>, DBQuery> {
        var categories = params["categories"] as List<String>?
        var status = (params["status"] as List<String>?)?.map {
            OrderStatus.REGULAR.inverseName(it)
        }
        var date = (params["date"] as String?)
        var order_id = params["order_ids"] as List<Int>
        var notes = (params["notes"] as List<String>?)?.map {
            prepareStrToJson(it)
        }

        var notes2 = (params["notes2"] as List<String>?)?.map {
            prepareStrToJson(it)
        }
        var notes3 = (params["notes3"] as List<String>?)?.map {
            prepareStrToJson(it)
        }
        var notes4 = (params["notes4"] as List<String>?)?.map {
            prepareStrToJson(it)
        }
        var notes5 = (params["notes5"] as List<String>?)?.map {
            prepareStrToJson(it)
        }
        val agents = getAgents().first
        var orderAgents = (params["orderAgents"] as List<String>?)?.map {
            val driver = agents.firstOrNull { ag -> ag.user_name == it }?.id ?: -1
            driver

        }
        var agent = params["agent"] as String?
        return this.updateAllClientOrders(
            order_ids = order_id, status = status, categories = categories, agent = agent, date = date,
            notes = notes, notes2 = notes2, notes3 = notes3, notes4 = notes4, notes5 = notes5, agent_id = orderAgents
        )
    }

    suspend fun newClientsAllOrders(
        params: HashMap<String, Any>
    ): Respone<List<OrderNote>, DBQuery> {

        val day = params["day"] as Int?
        var date = params["date"] as String?
        var categories = params["categories"] as List<String>
        var status = (params["status"] as List<String>?)?.map {
            OrderStatus.REGULAR.inverseName(it)
        }
        var notes = (params["notes"] as List<String>?)?.map {
            prepareStrToJson(it)
        }

        var notes2 = (params["notes2"] as List<String>?)?.map {
            prepareStrToJson(it)
        }
        var notes3 = (params["notes3"] as List<String>?)?.map {
            prepareStrToJson(it)
        }
        var notes4 = (params["notes4"] as List<String>?)?.map {
            prepareStrToJson(it)
        }
        var notes5 = (params["notes5"] as List<String>?)?.map {
            prepareStrToJson(it)
        }
        var ref_ids = (params["ref_ids"] as List<String>?)?.map {
            it
        }
        val agents = getAgents().first
        var orderAgents = (params["orderAgents"] as List<String>?)?.map {
            val driver = agents.firstOrNull { ag -> ag.user_name == it }?.id ?: -1
            driver

        }

        if (day != null) {
            date = baseOrderDaysToData[day]
            categories = categories.map { if (it.isEmpty()) "בסיס" else it }
        }
        return this.newAllClientOrders(
            date = date!!,
            agent = params["agent"] as String,
            ids = params["ids"] as List<Int>,
            pds = params["pds"] as List<List<ProductDelivery>>,
            notes = notes,
            categories = categories,
            status = status,
            notes2 = notes2,
            notes3 = notes3,
            notes4 = notes4,
            notes5 = notes5,
            agent_id = orderAgents,
            run_over = (params["run_over"] as Boolean?) ?: true,
            order_ids = params["order_ids"] as List<Int?>?,
            ref_ids = ref_ids,
            withHistory = (params["withHistory"] as Boolean?) ?: false,
            collection = params["collection"] as List<List<CollectionObject>>?
        )
    }

    suspend fun calcOptimizedCartesetValue(
        params: HashMap<String, Any>
    ): DBQuery {
        return this.calcOptimizedCartesetValue(
            ids = params["ids"] as List<Int>?, toDate = params["toDate"] as String?
        )
    }

    suspend fun buildAllProductImages(
        params: HashMap<String, Any>
    ): DBQuery {
        return this.buildProductImages(
            state = params["state"] as Map<Int, ByteArrayValueStore>?,
        )
    }

    suspend fun calcClientsXslx(params: HashMap<String, Any>): Respone<ByteArray?, DBQuery> {
        return this.calcClientsXslx(params["clients"] as List<Int>?)
    }

    suspend fun calcProductsXslx(params: HashMap<String, Any>): Respone<ByteArray?, DBQuery> {
        return this.calcProductsXslx(params["products"] as List<Int>?)
    }

    suspend fun calcQuerySum(params: HashMap<String, Any>): Respone<ByteArray?, DBQuery> {
        return this.calcQuerySum(
            fromDate = params["fromDate"] as String,
            toDate = params["toDate"] as String,
            ids = params["ids"] as List<Int>,
            title = params["title"] as String,
            issuer = params["issuer"] as String,
            withOrders = params["withOrders"] as Boolean?,
            withNotes = params["withNotes"] as Boolean?,
            specific_products = params["specific_products"] as List<Int>?,
            seperate = params["seperate"] as Int?,
            byIds = params["byIds"] as Int?,

            )
    }

    suspend fun calcDriverOrderSum(params: HashMap<String, Any>): Respone<ByteArray?, DBQuery> {
        return this.calcDriverOrderSum(
            date = params["date"] as String,
            ids = params["ids"] as List<Int>,
            groupType = params["groupType"] as Int,
            withOrders = params["withOrders"] as Int?,
            withNotes = params["withNotes"] as Int?,
            specific_products = params["specific_products"] as List<Int>?,
            title = params["title"] as String?,
            seperate = params["seperate"] as Int?,
            byIds = params["byIds"] as Int?,
        )
    }

    suspend fun calcEDI100(params: HashMap<String, Any>): Respone<ByteArray?, DBQuery> {
        return this.calcEDI100(
            tax_id = params["tax_id"] as Int,
            notes = params["notes"] as List<Int>?,

            )
    }

    suspend fun copyPriceFromClient(params: HashMap<String, Any>): DBQuery {
        val from = params["from"] as Int
        val to = params["to"] as Int
        val datefrom = params["fromDate"] as String
        val dateTo = params["toDate"] as String?
        val datePrice = params["datePrice"] as String
        return copyPriceFromClient(from, to, datefrom, dateTo, datePrice)

    }

    suspend fun getProductsStatistics(params: HashMap<String, Any>): Respone<List<OrderStatistics>, DBQuery> {
        val day = params["day"] as Int?
        val from = params["from"] as String?
        val ids = params["ids"] as List<Int>
        val date = params["to"] as String
        val withLast = params["withLast"] as Int?
        val month_counter = params["month_counter"] as Int?
        return getProductsStatistics(ids, date, day, from, withLast, month_counter)

    }

    suspend fun newAgent(params: HashMap<String, Any>): Respone<Agent?, DBQuery> {
        return newAgent(
            id = params["id"] as Int?,
            user_name = params["user_name"] as String,
            password = params["password"] as String?,
            type = params["type"] as Int
        )

    }

    suspend fun updateAgent(params: HashMap<String, Any>): Respone<List<Agent>, DBQuery> {
        val ids = params["ids"] as List<Int>
        val user_name = params["user_name"] as List<String>?
        val password = params["password"] as List<String>?
        val comment = params["comment"] as List<String>?
        return updateAgent(
            ids, user_name, password, comment
        )

    }

    suspend fun getClientsVisitationOptimized(
        params: HashMap<String, Any>
    ): Respone<Pair<List<ClientVisitationHolder>, List<String>>?, DBQuery> {
        return getClientsVisitationOptimized(
            ids = params["ids"] as List<Int>?,
            fromDate = params["fromDate"] as String,
            toDate = params["toDate"] as String,
            tax_visitation = params["tax_visitation"] as Boolean,
            note_visitation = params["note_visitation"] as Boolean,
            dv_visitation = params["dv_visitation"] as Boolean,
            allClients = params["allClients"] as Boolean,
        )
    }

    suspend fun getClientsDebts(
        params: HashMap<String, Any>
    ): Respone<List<ClientDebt>, DBQuery> {
        return getClientsDebts(
            ids = params["ids"] as List<Int>?,
            fromDate = params["fromDate"] as String,
            toDate = params["toDate"] as String,
        )
    }

    suspend fun getClientPayDetails(
        params: HashMap<String, Any>
    ): Respone<List<PaymentData>, DBQuery> {
        return getClientPayDetails(
            ids = params["ids"] as List<Int>?,
            fromDate = params["fromDate"] as String?,
            toDate = params["toDate"] as String?,
            paid = params["paid"] as Boolean?,
        )
    }

    suspend fun getOptimizedClientNote(
        params: HashMap<String, Any>
    ): Respone<List<Note>, DBQuery> {
        return this.getOptimizedClientNote(
            ent_id = params["ent_ids"] as List<Int>?,
            delivery_id = params["id"] as Int?,
            delivery_ids = params["ids"] as List<Int>?,
            fromDate = params["fromDate"] as String?,
            toDate = params["toDate"] as String?,
            full = params["full"] as Boolean? ?: false,
            open = params["open"] as Boolean?
        )
    }

    suspend fun sendEmail(
        params: HashMap<String, Any>
    ): DBQuery {
        return this.sendEmail(
            f = params["f"] as ByteArray,
            email = params["email"] as String,
            title = params["title"] as String,
            doc_type = params["doc_type"] as Int?,
            doc_id = params["doc_id"] as Int?
        )
    }

    suspend fun sendReqForApi(
        params: HashMap<String, Any>
    ): DBQuery {
        return this.sendReqForApi(
            doc_type = params["doc_type"] as Int,
            doc_id = params["doc_id"] as Int,
            api_method = params["api_method"] as Int?,
        )
    }

    suspend fun loadXlsx(
        params: HashMap<String, Any>
    ): Respone<String?, DBQuery> {
        return this.loadXlsx(
            f = params["f"] as ByteArray,
            type = params["type"] as String?,
            date = params["date"] as String?,
            load_doc_id = params["load_doc_id"] as Boolean?,
            load_type = params["load_type"] as String
        )
    }

    suspend fun getOptimizedRoute(params: HashMap<String, Any>): Respone<List<Int>?, DBQuery> {
        return this.getOptimizedRoute(
            clients = params["ids"] as List<Int>,
            updateGeo = (params["geoTransfrom"] as Boolean?) ?: false,
            start_point = params["start_point"] as String?,
            end_point = params["end_point"] as String?,
        )
    }

    suspend fun buildOptimizeRouteByDrivers(params: HashMap<String, Any>): Respone<List<ClientDailyData>, DBQuery> {
        return this.buildOptimizeRouteByDrivers(
            client_id = params["ids"] as List<Int>,
            date = params["date"] as String,
            save = params["save"] as Boolean,
            start_point = params["start_point"] as String?,
            end_point = params["end_point"] as String?,
        )
    }

    suspend fun splitDriversClientsToCollectors(params: HashMap<String, Any>): Respone<List<ClientDailyData>, DBQuery> {
        return this.splitDriversClientsToCollectors(
            client_id = params["ids"] as List<Int>,
            driver_id = params["driver_id"] as List<Int>,
            collector_id = params["collector_id"] as List<Int>,
            date = params["date"] as String,
            save = params["save"] as Boolean,
            maxBoxSize = params["maxBoxSize"] as Int,
            inverseToCollectors = (params["inverseToCollectors"] as Boolean?) ?: false,
        )
    }

    suspend fun buildLogo(params: HashMap<String, Any>): Respone<ByteArray?, DBQuery> {

        return this.buildLogo((params["cofc"] as Int?))
    }

    suspend fun reportError(params: HashMap<String, Any>): DBQuery {

        return this.reportError(
            data = params["data"] as String,
            agent = params["agent"] as String,
            version = params["version"] as String,

            )
    }

    suspend fun getInventory(params: HashMap<String, Any>): Respone<List<InventoryItem>, DBQuery> {

        return this.getInventory(
            product_id = params["product_id"] as List<Int>?,
            agent_id = params["agent_id"] as List<Int>?,
            fromDate = params["fromDate"] as String?,
            toDate = params["toDate"] as String?,
        )
    }

    suspend fun clearInventory(params: HashMap<String, Any>): DBQuery {

        return this.clearInventory(
            product_id = params["product_id"] as List<Int>?,
            agent_id = params["agent_id"] as List<Int>?,
        )
    }

    suspend fun buildClientVisitDistinct(params: HashMap<String, Any>): Respone<List<ClientVisitHolder>, DBQuery> {

        return this.buildClientVisitDistinct(
            ids = params["ids"] as List<Int>?,
            fromDate = params["fromDate"] as String,
            toDate = params["toDate"] as String,
        )
    }

    suspend fun getInventoryNote(params: HashMap<String, Any>): Respone<List<InventoryItem>, DBQuery> {
        val full = params["full"] as Boolean? ?: false
        val agent = params["agent_id"] as List<Int>?
        val inv = this.buildClientOrderNotes(
            client_id = -1,
            fromDate = params["fromDate"] as String,
            toDate = params["toDate"] as String,
            agent_id = agent,
        ).first.map { InventoryItem.orderNoteToInventoryItem(it, full) }.flatten().toMutableList()
        if (full && inv.isEmpty() && agent != null) {
            agent.forEach {
                inv.addAll(InventoryItem.prepareAllItems(mapOf(), it))
            }

        }
        return Respone(inv, DBQuery.SUCCESS)
    }

    suspend fun getClientCompare(params: HashMap<String, Any>): Respone<Pair<List<ClientCompareHolder>, List<Any>>, DBQuery> {
        val method = params["method"] as Int? ?: 0
        val date = params["date"] as String
        val agents = params["agents"] as List<String>?

        return this.getClientCompare(
            method = method,
            date = date,
            agents = agents,
            cost = params["cost"] as Boolean? ?: false,
        )
    }

    suspend fun sendMsgAll(params: HashMap<String, Any>): DBQuery {
        return sendMsgAll(
            ids = params["ids"] as List<Int>,
            msg = params["msg"] as String,
            title = params["title"] as String,
            doc_type = params["doc_type"] as List<Int>?,
            emails = params["emails"] as List<String>?,
            phones = params["phones"] as List<String>?,
            action_date = params["action_date"] as String?,
            special_arg = params["special_arg"] as String?,

            )
    }

    suspend fun refreshDocuments(params: HashMap<String, Any>): Respone<List<InformationBase>, DBQuery> {
        val documents = params["documents"] as List<Int>
        val types = params["types"] as List<Int>
        val full = params["full"] as Boolean? ?: false
        val offline = params["offline"] as Boolean? ?: false
        val docs = types.mapIndexed { index: Int, i: Int -> Pair(documents[index], i) }
        val groups = docs.groupBy { it.second }
        val result = mutableListOf<InformationBase>()
        if (offline) {
            groups.forEach { (t, u) ->
                when (t) {
                    DocType.DELIVERY_NOTES.state -> {
                        getClientDeliveryNotes(ids = u.map { it.first }).first.forEach {
                            result.add(it)
                        }
                    }

                    DocType.ORDERS.state -> {
                        getClientOrderNotes(order_ids = u.map { it.first }).first.forEach {
                            result.add(it)
                        }
                    }

                    DocType.TAX_NOTE.state -> {
                        getClientTaxNote(ids = u.map { it.first }, type = 0).first.forEach {
                            result.add(it)
                        }
                    }

                    DocType.TAX_NOTE_CANCEL.state -> {
                        getClientTaxNote(ids = u.map { it.first }, type = 2).first.forEach {
                            result.add(it)
                        }
                    }

                    DocType.TAX_PAY_NOTE.state -> {
                        getClientTaxNote(ids = u.map { it.first }, type = 2).first.forEach {
                            result.add(it)
                        }
                    }

                    DocType.VISIT.state -> {
                        buildClientVisitNote(ids = u.map { it.first }, full = full).first.forEach {
                            result.add(it)
                        }

                    }

                    DocType.PAY_NOTE.state -> {

                        getClientPay(
                            ids = u.map { it.first },
                        ).first.forEach {
                            result.add(it)
                        }
                    }


                }
            }
        } else {
            groups.forEach { (t, u) ->
                when (t) {
                    DocType.DELIVERY_NOTES.state -> {
                        buildClientNotes(delivery_ids = u.map { it.first }, full = full).first.forEach {
                            result.add(it)
                        }
                    }

                    DocType.ORDERS.state -> {
                        buildClientOrderNotes(order_ids = u.map { it.first }, full = full).first.forEach {
                            result.add(it)
                        }
                    }

                    DocType.TAX_NOTE.state -> {
                        buildClientTaxNote(ids = u.map { it.first }, type = 0, full = full).first.forEach {
                            result.add(it)
                        }
                    }

                    DocType.TAX_NOTE_CANCEL.state -> {
                        buildClientTaxNote(ids = u.map { it.first }, type = 2, full = full).first.forEach {
                            result.add(it)
                        }
                    }

                    DocType.TAX_PAY_NOTE.state -> {
                        buildClientTaxNote(ids = u.map { it.first }, type = 1, full = full).first.forEach {
                            result.add(it)
                        }
                    }

                    DocType.VISIT.state -> {
                        buildClientVisitNote(ids = u.map { it.first }, full = full).first.forEach {
                            result.add(it)
                        }

                    }

                    DocType.PAY_NOTE.state -> {

                        buildClientPay(
                            ids = u.map { it.first },
                            full = full,
                        ).first.forEach {
                            result.add(it)
                        }
                    }


                }
            }
        }

        return Respone(result, DBQuery.SUCCESS)
    }


    suspend fun getLastUpdateEnt(params: HashMap<String, Any>): Respone<List<Int>, DBQuery> {
        return this.getLastUpdateEnt(
            action_time = params["action_time"] as String
        )
    }

    suspend fun getLastUpdateOrders(params: HashMap<String, Any>): Respone<List<OrderNote>, DBQuery> {
        val o = this.getLastUpdateOrders(
            action_time = params["action_time"] as String,
            date = params["date"] as String,
            full = false,
        )

        if (params["full"] as Boolean? ?: false && o.first.isNotEmpty()) {
            val full = buildFullClientOrderNotes(o.first, false)
            val daily =
                buildClientsDailyData(
                    date = params["date"] as String,
                    ids = o.first.map { it.ent_id },
                    dailyFinder = true
                )
        }
        return Respone(o.first, o.second)
    }

    suspend fun getProductAvailable(params: HashMap<String, Any>): Respone<List<Available>, DBQuery> {
        val ids = params["ids"] as List<Int>?
        val product_ids = params["product_ids"] as List<Int>?
        val available = params["available"] as Int?
        val result = getProductAvailable(ids = ids, product_ids = product_ids, available = available)
        return Respone(result.first, result.second)
    }

    suspend fun newAllAvailable(params: HashMap<String, Any>): Respone<List<Available>, DBQuery> {
        val ids = params["ids"] as List<Int>
        val product_ids = params["product_ids"] as List<Int>
        val availables = params["availables"] as List<Int>?
        val available = params["available"] as Int?
        val data: MutableList<Available> = mutableListOf()
        ids.forEachIndexed { index, i ->
            product_ids.forEachIndexed { index2, j ->
                data.add(Available(i, j, availables?.get(index) ?: available ?: 1))
            }
        }


        val result = newAllAvailable(data = data)
        return Respone(result.first, result.second)
    }

    suspend fun sendClientApi(params: HashMap<String, Any>): DBQuery {
        val doc_id = params["doc_id"] as List<Int>
        val doc_type = params["doc_type"] as List<Int>
        return sendClientApi(doc_id, doc_type)

    }

    suspend fun resetWhatsappConn(params: HashMap<String, Any>): DBQuery {
        return resetWhatsappConn()
    }

    suspend fun getClientCPData(params: HashMap<String, Any>): Respone<List<ClientProductData>, DBQuery> {
        val ids = params["ids"] as List<Int>?
        val productIds = params["productIds"] as List<Int>?
        val result = getClientCPData(ids, productIds)
        return Respone(result.first, result.second)
    }

    suspend fun setClientCPData(params: HashMap<String, Any>): Respone<List<ClientProductData>, DBQuery> {
        val ids = params["ids"] as List<Int>
        val productIds = params["productIds"] as List<Int>
        val barcodes = params["barcodes"] as List<String?>?
        return setClientCPData(ids, productIds, barcodes)
    }

    suspend fun hashIsraelAuth(params: HashMap<String, Any>): DBQuery {
        val token = params["token"] as String
        val result = this.hashIsraelAuth(token = token)
        return result
    }

    suspend fun hashIsraelSign(params: HashMap<String, Any>): Respone<ClientTaxNote?, DBQuery> {

        val result = this.hashIsraelSign(id = params["id"] as Int, type = params["type"] as Int)
        return result
    }

    suspend fun updateMassDocument(params: HashMap<String, Any>): Respone<List<InformationBase>, DBQuery> {
        val data = params["data"] as List<ChangeNoteHelper>
        val orders = mutableListOf<Int>()
        val orders_pds = mutableListOf<List<ProductDelivery>>()
        val delivery = mutableListOf<Int>()
        val delivery_pds = mutableListOf<List<ProductDelivery>>()
        val sup = mutableListOf<Int>()
        val sup_pds = mutableListOf<List<ProductDelivery>>()
        val savePriceInNote = getUser().first?.savePriceInNote() == true
        val savePriceInClient = params["savePriceInClient"] as Boolean? ?: true
        val updatePrices: MutableList<RawPrice> = mutableListOf()
        val updateDrivers: MutableList<ClientDailyData> = mutableListOf()
        val delivery_ids = data.filter { it.docType == DocType.DELIVERY_NOTES.state }.map { it.doc_id }.distinct()
        if (delivery_ids.isNotEmpty())
            buildClientNotes(delivery_ids = delivery_ids, full = true)

        val order_ids = data.filter { it.docType == DocType.ORDERS.state }.map { it.doc_id }.distinct()
        if (order_ids.isNotEmpty())
            buildClientOrderNotes(order_ids = order_ids, full = true)

        val sup_ids = data.filter { it.docType == DocType.SUP_DELIVERY_NOTES.state }.map { it.doc_id }.distinct()
        if (sup_ids.isNotEmpty())
            buildSupplierNotes(delivery_ids = sup_ids, full = true)
        val curDate = DatesManipulator.dateNowNoHour()
        val drivers = getAgents().first.filter { it.isDriver() }
        data.filter { it.docType == DocType.DELIVERY_NOTES.state || it.docType == DocType.ORDERS.state || it.docType == DocType.SUP_DELIVERY_NOTES.state }
            .groupBy { Pair(it.doc_id, it.docType) }.forEach {
                val note = when (it.key.second) {
                    DocType.DELIVERY_NOTES.state -> {
                        getClientDeliveryNotes(delivery_id = it.key.first).first.firstOrNull()

                    }

                    DocType.ORDERS.state -> {
                        getClientOrderNotes(order_id = it.key.first).first.firstOrNull()
                    }

                    DocType.SUP_DELIVERY_NOTES.state -> {
                        getSupplierDeliveryNotes(delivery_id = it.key.first).first.firstOrNull()
                    }

                    else -> null
                }

                if (note != null) {
                    val ent = note.getEnt(it.key.second != DocType.SUP_DELIVERY_NOTES.state)
                    val newDelivery = mutableListOf<ProductDelivery>()
                    val seen = mutableMapOf<Int, ProductDelivery>()
                    val changes = it.value
                    note.delivery_info.forEachIndexed { index, pd ->
                        seen[pd.productId] = pd

                    }
                    val driver_id = note.driver_id
                    val curDriver = if (driver_id != null && driver_id != -1) {
                        drivers.firstOrNull { it.id == driver_id }
                    } else null
                    val cps = changes.map { it.driver }
                        .filter { it != null && it != "" && (curDriver == null || curDriver.user_name != it) }
                        .distinct().mapNotNull {
                            drivers.firstOrNull { d -> d.user_name == it }
                        }
                    if (cps.isNotEmpty()) {
                        cps.forEach { cp ->
                            updateDrivers.add(
                                ClientDailyData(
                                    note.date,
                                    ent.id,
                                    cp.id,
                                    0,
                                    0,
                                    0,
                                    0, "", 0
                                )
                            )


                        }
                    }



                    changes.forEach { cn ->
                        if (cn.p_id in seen) {
                            val curPd = seen[cn.p_id]!!
                            val price = ent.getPrice(cn.p_id)!!.get(note.date)
                            if (price.first != cn.price || price.second != cn.discount) {
                                updatePrices.add(
                                    RawPrice(
                                        if (ent is Client) (ent.masterBranch?.id ?: ent.id) else ent.id,
                                        curPd.productId,
                                        cn.price,
                                        if (note.date <= unclosed_date) curDate else note.date,
                                        cn.discount
                                    )
                                )
                            }
                            val pds = curPd.copy(
                                value = cn.amount,
                                returns = cn.returns,
                                price = cn.price,
                                discount = cn.discount,
                                wrapped_amount = cn.amountSecond,
                                conversion_ratio = if (cn.amount > 0 && cn.amountSecond > 0) 0f else cn.conversion_rate
                            )
                            seen[(cn.p_id)] = pds

                        } else {
                            val pds = ProductDelivery(
                                cn.p_id,
                                cn.amount,
                                cn.returns,
                                cn.price,
                                discount = cn.discount,
                                wrapped_amount = cn.amountSecond,
                                conversion_ratio = if (cn.amount > 0 && cn.amountSecond > 0) 0f else cn.conversion_rate
                            )

                            seen[(cn.p_id)] = pds

                        }


                    }
                    seen.forEach {
                        if (!it.value.empty())
                            newDelivery.add(it.value)
                    }
                    when (it.key.second) {
                        DocType.DELIVERY_NOTES.state -> {
                            delivery.add(it.key.first)
                            delivery_pds.add(newDelivery)
                        }

                        DocType.ORDERS.state -> {
                            orders.add(it.key.first)
                            orders_pds.add(newDelivery)
                        }

                        DocType.SUP_DELIVERY_NOTES.state -> {
                            sup.add(it.key.first)
                            sup_pds.add(newDelivery)
                        }
                    }


                }

            }

        val res: MutableList<InformationBase> = mutableListOf()
        if (savePriceInClient && updatePrices.isNotEmpty()) {
            updateAllClientProductPrice(curDate, updatePrices)
        }
        if (updateDrivers.isNotEmpty()) {
            newClientAllDailyData(daily = updateDrivers, onlyDriver = true)
        }


        if (delivery.isNotEmpty() && delivery_ids.size == delivery.size) {
            val result = updateAllClientNotes(
                delivery_ids = delivery,
                pds = delivery_pds,
                full = savePriceInClient && updatePrices.isNotEmpty()
            )
            if (result.second != DBQuery.SUCCESS) return Respone(result.first, result.second)
            res.addAll(result.first)
        }
        if (sup.isNotEmpty() && sup_ids.size == sup.size) {

        }
        if (orders.isNotEmpty() && order_ids.size == orders.size) {
            val result = updateAllClientOrders(
                order_ids = orders,
                pds = orders_pds,
                full = savePriceInClient && updatePrices.isNotEmpty()
            )
            res.addAll(result.first)
            if (result.second != DBQuery.SUCCESS) return Respone(res, result.second)

        }


        return Respone(res, DBQuery.SUCCESS)
    }
}

















