package Utils

val DOC_NAME = listOf<String>(
    "חשבונית מס",
    "חשבונית מס קבלה",
    "חשבונית מס זיכוי",
    "סיכום תעודות",
    "תעודת משלוח",
    "הכל",
    "תעודת ספק",
    "קבלה",
    "מחיר לקוח",
    "כרטסת",
    "הזמנה",
    "תעודת משלוח - החזר",
    "תעודת ביקור"
)

val DOC_NAME_SHORT = listOf<String>(
    "חש",
    "חש מס/ק",
    "זיכוי",
    "סיכום תעודות",
    "תעודה",
    "מחזור",
    "תעודת ספק",
    "קבלה",
    "מחיר לקוח",
    "כרטסת",
    "הזמנה",
    "תעודת משלוח - החזר",
    "תעודת ביקור"
)

enum class DocType(val state: Int) {
    TAX_NOTE(0),
    TAX_PAY_NOTE(1),
    PAY_NOTE(7),
    SINGLE_DELIVERY_NOTE(3),
    DELIVERY_NOTES(4),
    ALL(state = 5),
    SUP_DELIVERY_NOTES(6),
    TAX_NOTE_CANCEL(2),
    CLIENT_PRICES(8),
    CLIENT_CARTESET(9),
    ORDERS(10),
    RETURNS(11),
    VISIT(12),
    PRODUCT_SUMMARY(13),;

    fun convert(s: Int): DocType {
        return when (s) {
            0 -> TAX_NOTE
            1 -> TAX_PAY_NOTE
            7 -> PAY_NOTE
            3 -> SINGLE_DELIVERY_NOTE
            4 -> DELIVERY_NOTES
            6 -> SUP_DELIVERY_NOTES
            2 -> TAX_NOTE_CANCEL
            8 -> CLIENT_PRICES
            9 -> CLIENT_CARTESET
            10 -> ORDERS
            11 -> RETURNS
            12 -> VISIT
            13 -> PRODUCT_SUMMARY
            else -> ALL
        }
    }

    fun isInfoDoc(): Boolean {
        return when (this) {
            TAX_NOTE, TAX_PAY_NOTE, TAX_NOTE_CANCEL, PAY_NOTE, DELIVERY_NOTES, ORDERS -> true
            else -> {
                false
            }
        }
    }

    fun DOC_NAME(): String {
        return DOC_NAME[this.state]

    }

    fun DOC_NAME_SHORT(): String {
        return DOC_NAME_SHORT[this.state]

    }
}
