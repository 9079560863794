package Structs


import Utils.DatesManipulator
import myName

@myName("DatedObject")
abstract class DatedObject<K>() {
    var objects: MutableList<Pair<String, K>> = ArrayList()

    @myName("get")
    fun get(date: String = DatesManipulator.dateNow()): K {
        return objects.lastOrNull { pair -> pair.first <= date }?.second ?: getDefault(date)
    }

    fun getRange(from: String = DatesManipulator.dateNow(), to: String = DatesManipulator.dateNow()): List<K> {
        val lb = mutableListOf(get(from))
        val allRange = objects.filter { it.first in from..to }
        if (allRange.isEmpty()) {
            return (lb)
        }
        lb.addAll(allRange.map { it.second })

        return lb.distinct()
    }

    abstract fun getDefault(curDate: String = DatesManipulator.dateNow()): K

    @myName("isActive")
    abstract fun isActive(curDate: String = DatesManipulator.dateNow()): Boolean

    private fun getDated(date: String): K? {
        return objects.lastOrNull { pair -> pair.first <= date }?.second
    }

    fun getDatedPriceIndexExactly(date: String): Int {
        return objects.indexOfLast { pair -> pair.first == date }
    }

    fun getDatedPriceIndex(date: String): Int {
        return objects.indexOfLast { pair -> pair.first <= date }
    }

    fun order() {
        objects.sortBy { it.first }
    }


    open fun add(obj: K, date: String, runOver: Boolean = false) {
        if (runOver) {
            objects.clear()
        }
        var query = getDatedPriceIndexExactly(date)
        if (query == -1) {
            query = getDatedPriceIndex(date)
            objects.add(query + 1, Pair(date, obj))
        } else {
            objects[query] = (Pair(date, obj))
        }
    }

    abstract fun copy(): DatedObject<K>


}