package Structs

import DataBase.DB
import DataBase.DBQuery
import DataBase.UINKDBInterface
import Utils.DatesManipulator
import Utils.FillTaxNoteType
import Utils.prettyRoundString
import Utils.roundToDecimals
import myName

enum class TaxNoteRowDataType {
    NOTE,
    NOTE_SUM,
    PRODUCT,
    PRODUCT_SINGLE,
    PRODUCT_SINGLE_NOTE,
    HAND,
    CANCEL_TAX_NOTE,
    CANCEL_TAX_NOTE_PAY,
    CANCEL_TAX_CANCEL_NOTE,
    EMPTY,
    FREE

}

@myName("TaxNoteExplain")
data class TaxNoteExplain(
    val type: TaxNoteRowDataType,
    val describe: String,
    val barcode: String,
    val date: String,
    val amount: Float,
    val returns: Float,
    val price_before: Float,
    val price_after: Float,
    val discount: Float,
    val total_before: Float,
    val total_after: Float,
    val tax_paid: Float,
    val price_min: Float = price_after,
    val price_max: Float = price_after,
    val price_min_before: Float = price_after,
    val price_max_before: Float = price_after,
    val note_id: String? = null,
    val bold: Boolean = false,
    val additional_extra_detail: String? = null,
    val product_id: Int? = null,
    val amountSecond:Float? = null,
    val amountSecondReturn:Float? = null,
) {
    companion object {
        private fun createfreeRow(data: ClientTaxNoteData, date: String): TaxNoteExplain {
            val taxPaid = data.taxPaid ?: (data.value - data.value / UINKDBInterface.activeDB.tax.get(date))
            val tot = data.value
            val plus = if (taxPaid > 0) "(+)" else ""
            return TaxNoteExplain(
                TaxNoteRowDataType.FREE,
                data.details + plus,
                "",
                DatesManipulator.dateIsrael(date),
                data.amount ?: 0f,
                0f,
                data.amount?.let {
                    val value_inc = tot / it
                    val tax_splitted = taxPaid / it
                    value_inc - tax_splitted
                } ?: 0f,
                data.amount?.let {
                    tot / it
                } ?: 0f,
                0f,
                total_before = tot - taxPaid,
                total_after = tot, //include tax
                tax_paid = taxPaid
            )
        }

        private fun createHandRow(data: ClientTaxNoteData, date: String): TaxNoteExplain {
            val taxPaid = data.taxPaid ?: (data.value - data.value / UINKDBInterface.activeDB.tax.get(date))
            val tot = data.value
            val taxDetails = if (taxPaid == 0f) "ללא מעמ" else ""
            return TaxNoteExplain(
                TaxNoteRowDataType.HAND,
                "רישום ידני $taxDetails",
                "",
                DatesManipulator.dateIsrael(date),
                0f,
                0f,
                0f,
                0f,
                0f,
                total_before = tot - taxPaid,
                total_after = tot, //include tax
                tax_paid = taxPaid
            )
        }

        fun getSumProductRowList(tn: ClientTaxNote, deliveryNotes: List<DeliveryNote>): List<TaxNoteExplain> {
            val db = UINKDBInterface.activeDB
            val exp = db.calcDeliveryNoteSumMap(deliveryNotes)
            val result: MutableList<TaxNoteExplain> = mutableListOf()
            exp.first.entries.sortedBy {
                if (it.key != -1)
                    db.getClientProduct(it.key).first!!.getPosition()
                else
                    it.key
            }.forEach { me ->
                if (me.key != -1) {
                    val product_id = me.key
                    val p = db.getClientProduct(product_id).first!!
                    val value = me.value.totalValue
                    val instancePositive = me.value.positiveInstances
                    val instanceNegative = me.value.negativeInstances
                    val price_min = me.value.price_min
                    val price_max = me.value.price_max

                    val barcode = p.getBarcode(tn.document_date)
                    val name = p.getName(tn.document_date)
                    val tax = me.value.taxPaid
                    var price_before = 0f
                    var price_min_before = 0f
                    var price_max_before = 0f
                    var price_after = me.value.price
                    if (tax != 0f) { // risk that someone changed price no tax to this product after tax note
                        price_before = price_after / db.tax.get(tn.document_date)
                        price_min_before = price_min / db.tax.get(tn.document_date)
                        price_max_before = price_max / db.tax.get(tn.document_date)
                    } else {
                        price_before = price_after
                        price_min_before = price_min
                        price_max_before = price_max
                    }
                    result.add(
                        TaxNoteExplain(
                            TaxNoteRowDataType.PRODUCT,
                            name,
                            barcode,
                            date = DatesManipulator.dateIsrael(tn.document_date),
                            instancePositive,
                            instanceNegative,
                            price_before, // after tax for now
                            price_after,
                            me.value.discount,
                            total_before = value - tax,
                            total_after = value,// after tax
                            tax_paid = tax,
                            price_min = price_min,
                            price_max = price_max,
                            price_min_before = price_min_before,
                            price_max_before = price_max_before,
                            product_id = product_id
                        )
                    )
                }
            }
            return result
        }

        fun getSingleProductRowList(
            tn: ClientTaxNote,
            client: Client,
            deliveryNotes: List<DeliveryNote>
        ): List<TaxNoteExplain> {
            val db = UINKDBInterface.activeDB
            val result: MutableList<TaxNoteExplain> = mutableListOf()
            deliveryNotes.forEach { d ->
                val note_used: Byte = if (d.isUsed()) 1 else 0
                d.delivery_info.forEach { pd ->
                    val p = pd.getProduct()
                    val prices = db.pdUsePrice(pd, client, d.date, note_used)!!
                    val discount = prices.third
                    val price_before = prices.first //with discount already
                    val price_after = prices.second //with discount already
                    val value =
                        (pd.value - pd.returns + (pd.wrapped_amount - pd.wrapped_amount_return) * pd.conversion_ratio) * price_before
                    var taxPaid =
                        (pd.value - pd.returns + (pd.wrapped_amount - pd.wrapped_amount_return) * pd.conversion_ratio) * (price_after - price_before)
                    val barcode =
                        if (p.getBarcode(tn.document_date)
                                .compareTo("0000000000000") == 0
                        ) "" else p.getBarcode(tn.document_date)
                    val name = p.getName(tn.document_date) + if (p.getUnitAmmount(d.date) > 0) " (${
                        p.getUnitAmmount(d.date).toInt()
                    })" else ""
                    result.add(
                        TaxNoteExplain(
                            TaxNoteRowDataType.PRODUCT_SINGLE_NOTE,
                            name,
                            barcode,
                            date = DatesManipulator.dateIsrael(d.date),
                            pd.value + (pd.wrapped_amount) * pd.conversion_ratio,
                            pd.returns + (pd.wrapped_amount_return) * pd.conversion_ratio,
                            price_before,
                            price_after,
                            discount,
                            value,
                            value + taxPaid, // the client value
                            tax_paid = taxPaid,
                            note_id = d.delivery_id.toString(),
                            product_id = p.id,
                            amountSecond = pd.wrapped_amount,
                            amountSecondReturn = pd.wrapped_amount_return,
                        )
                    )
                }
            }
            return result
        }

        fun getNoteRowList(
            tn: ClientTaxNote,
            deliveryNotes: List<DeliveryNote>
        ): List<TaxNoteExplain> {
            val db = UINKDBInterface.activeDB
            val result: MutableList<TaxNoteExplain> = mutableListOf()
            deliveryNotes.sortedBy { it.date }.forEach { d ->
                val taxPaid = tn.getNoteTaxPaid(d.delivery_id)
                    ?: d.taxPaid //will be always correct from september 21
                val noteValue =
                    (tn.getValueOfNote(d.id)!!)
                result.add(
                    TaxNoteExplain(
                        TaxNoteRowDataType.NOTE,
                        "ת.מ מס' ${d.delivery_id}",
                        "",
                        date = DatesManipulator.dateIsrael(d.date),
                        0f,
                        0f,
                        0f,
                        0f,
                        0f,
                        total_before = noteValue - taxPaid,
                        total_after = noteValue,
                        tax_paid = taxPaid,
                        note_id = d.delivery_id.toString()
                    )
                )
            }
            return result
        }

        //require full built client tax note
        fun create(
            tn: ClientTaxNote,
            noteRequestType: Int,
            splitNet: Boolean = false,
            noteAsList: Boolean = true,
            sortProductByPosition:Boolean=false
        ): List<TaxNoteExplain> {
            val db = UINKDBInterface.activeDB
            val client = db.getClient(tn.client_id).first!!
            val result: MutableList<TaxNoteExplain> = mutableListOf()
            tn.valueData.forEach {
                when (it.fillTaxNoteType) {
                    FillTaxNoteType.NOTES -> {
                        val deliveryNotes = db.getNotesOfClientTaxNote(tn).first
                        if (splitNet && client.hasBranch() && noteRequestType <4) {
                            val notes = deliveryNotes
                            val groups = notes.groupBy { n -> n.ent_id }
                            val sizeOfRow = 8
                            groups.forEach { (t, u) ->
                                val curC = db.getClient(t).first!!.getName(tn.document_date)
                                val chunk = u.map { it }.chunked(sizeOfRow)
                                val sum_all = u.map { tn.getValueOfNote(it.delivery_id) }
                                    .sumByDouble { it?.toDouble() ?: (0).toDouble() }.toFloat()
                                val tax_all = u.map { tn.getNoteTaxPaid(it.delivery_id) ?: it.taxPaid }
                                    .sumByDouble { it?.toDouble() ?: (0).toDouble() }.toFloat()
                                result.add(
                                    TaxNoteExplain(
                                        TaxNoteRowDataType.NOTE,
                                        "סה''כ $curC",
                                        "",
                                        date = "",
                                        1f,
                                        0f,
                                        0f,
                                        0f,
                                        0f,
                                        total_before = sum_all - tax_all,
                                        total_after = sum_all,
                                        tax_paid = tax_all,
                                        bold = true
                                    )
                                )
                                when (noteRequestType) {
                                    0,4 -> {
                                        if (noteAsList) {
                                            chunk.forEachIndexed { index, list ->
                                                result.add(
                                                    TaxNoteExplain(
                                                        TaxNoteRowDataType.NOTE_SUM,
                                                        "ת.מ מס' ${list.map { it.delivery_id }.joinToString()}",
                                                        "",
                                                        date = DatesManipulator.dateIsrael(tn.date),
                                                        1f,
                                                        0f,
                                                        0f,
                                                        0f,
                                                        0f,
                                                        total_before = 0f,
                                                        total_after = 0f,
                                                        tax_paid = 0f,
                                                    )
                                                )
                                            }
                                        } else {
                                            result.addAll(getNoteRowList(tn, u))
                                        }
                                    }

                                    1,5 -> {
                                        result.addAll(getSumProductRowList(tn, u))
                                    }

                                    2,3 -> {
                                        result.addAll(getSingleProductRowList(tn, db.getClient(t).first!!, u))
                                    }



                                }
                                result.add(
                                    TaxNoteExplain(
                                        TaxNoteRowDataType.EMPTY,
                                        "",
                                        "",
                                        date = "",
                                        0f,
                                        0f,
                                        0f,
                                        0f,
                                        0f,
                                        total_before = 0f,
                                        total_after = 0f,
                                        tax_paid = 0f
                                    )
                                )
                            }
                        } else {
                            when (noteRequestType) {
                                0,4 -> {
                                    result.addAll(getNoteRowList(tn, deliveryNotes))
                                }

                                1,5 -> {
                                    result.addAll(getSumProductRowList(tn, deliveryNotes))
                                }

                                2,3 -> {
                                    result.addAll(getSingleProductRowList(tn, client, deliveryNotes))
                                }

                            }
                        }
                    }

                    FillTaxNoteType.PRODUCTS -> {

                        val productDeliveries = tn.productDeliveries.sortedBy { if(sortProductByPosition) it.tempPosition else it.getProduct().position }
                        productDeliveries.forEach { pd ->
                            val p = pd.getProduct()
                            val prices = db.pdPriceFromTaxNote(pd, client, tn.document_date)
                            val discount = prices.third
                            val price_before = prices.first * (1 - discount / 100)
                            val price_after = prices.second * (1 - discount / 100)
                            val item = pd
                            var describeUnit = ""
                            if (item.getProduct().getUnitWrapped().isContainer() && item.getProduct().unit_amount > 0
                                && item.wrapped_amount == 0f && item.wrapped_amount_return == 0f
                            ) {
                                describeUnit = " ${prettyRoundString(item.getProduct().unit_amount * item.value, 2)} יח"
                            }

                            if (item.wrapped_amount > 0 || item.wrapped_amount_return > 0) describeUnit =
                                "${
                                    prettyRoundString(
                                        item.wrapped_amount - item.wrapped_amount_return,
                                        2
                                    )
                                } ${item.getUnit()}"

                            val value =
                                (pd.value - pd.returns + (pd.wrapped_amount - pd.wrapped_amount_return) * pd.conversion_ratio) * price_before
                            var taxPaid =
                                (pd.value - pd.returns + (pd.wrapped_amount - pd.wrapped_amount_return) * pd.conversion_ratio) * (price_after - price_before)
                            val barcode =
                                if (p.getBarcode(tn.document_date)
                                        .compareTo("0000000000000") == 0
                                ) "" else p.getBarcode(tn.document_date)
                            val name = p.getName(tn.document_date) + if (p.getUnitAmmount(tn.document_date) > 0) " (${
                                p.getUnitAmmount(tn.document_date).toInt()
                            })" else ""
                            result.add(
                                TaxNoteExplain(
                                    TaxNoteRowDataType.PRODUCT_SINGLE,
                                    name,
                                    barcode,
                                    date = DatesManipulator.dateIsrael(tn.document_date),
                                    pd.value + (pd.wrapped_amount) * pd.conversion_ratio,
                                    pd.returns + (pd.wrapped_amount_return) * pd.conversion_ratio,
                                    price_before, //the client price
                                    price_after,
                                    discount,
                                    value,
                                    value + taxPaid, // the client value
                                    tax_paid = taxPaid,
                                    additional_extra_detail = describeUnit,
                                    product_id = p.id,
                                    amountSecond = pd.wrapped_amount,
                                    amountSecondReturn = pd.wrapped_amount_return,
                                )
                            )
                        }
                    }

                    FillTaxNoteType.HAND -> {
                        result.add(createHandRow(it, tn.document_date))
                    }

                    FillTaxNoteType.FREE_STYLE -> {
                        result.add(createfreeRow(it, tn.document_date))
                    }

                    FillTaxNoteType.FREE -> {
                        result.add(createfreeRow(it, tn.document_date))
                    }

                    FillTaxNoteType.TAX_NOTES -> {
                        val cancelTaxNote = tn.getTaxNotesOrNull() ?: listOf()
                        cancelTaxNote.forEach { cur_tn ->
                            result.add(
                                TaxNoteExplain(
                                    TaxNoteRowDataType.CANCEL_TAX_NOTE,
                                    "ביטול חשבונית מס" + " ${cur_tn}",
                                    "",
                                    date = DatesManipulator.dateIsrael(tn.document_date),
                                    0f,
                                    0f,
                                    0f, //the client price
                                    0f,
                                    0f,
                                    0f,
                                    0f, // the client value
                                    tax_paid = 0f
                                )
                            )
                        }


                    }

                    FillTaxNoteType.TAX_PAY_NOTES -> {
                        val cancelTaxPayNote = tn.getTaxPayNotesOrNull() ?: listOf()
                        cancelTaxPayNote.forEach { cur_tn ->
                            result.add(
                                TaxNoteExplain(
                                    TaxNoteRowDataType.CANCEL_TAX_NOTE_PAY,
                                    "ביטול חשבונית מס/קבלה" + " ${cur_tn}",
                                    "",
                                    date = DatesManipulator.dateIsrael(tn.document_date),
                                    0f,
                                    0f,
                                    0f, //the client price
                                    0f,
                                    0f,
                                    0f,
                                    0f, // the client value
                                    tax_paid = 0f
                                )
                            )
                        }
                    }

                    FillTaxNoteType.TAX_CANCEL_NOTES -> {
                        val cancelTaxPayNote = tn.getTaxCancelNotesOrNull() ?: listOf()
                        cancelTaxPayNote.forEach { cur_tn ->
                            result.add(
                                TaxNoteExplain(
                                    TaxNoteRowDataType.CANCEL_TAX_CANCEL_NOTE,
                                    "ביטול חשבונית זיכוי" + " ${cur_tn}",
                                    "",
                                    date = DatesManipulator.dateIsrael(tn.document_date),
                                    0f,
                                    0f,
                                    0f, //the client price
                                    0f,
                                    0f,
                                    0f,
                                    0f, // the client value
                                    tax_paid = 0f
                                )
                            )
                        }
                    }
                }

            }
            return result
        }

    }


}
