package Structs

import DataBase.UINKDBInterface
import Utils.DatesManipulator
import Utils.DocType
import Utils.TaxNoteType
import kotlinx.serialization.Serializable
import myName
import kotlin.math.abs
import kotlin.math.max


data class DataKey(
    val byYear: Int?,
    val byMonth: Int?,
    val byWeek: String?,
    val byDay: Int?,
    val byEnt: Int?,
    val byProduct: Float?,
    val byAgent: String?
)

@Serializable
data class DataQueryHolder(
    val doc_type: Int,
    var valueSum: Float,
    var returnSum: Float,
    var number_of_rows: Float,
    var byMoney: Float? = null,
    var byTax: Float? = null,
    var valueSumSec: Float? = null,
    var returnSumSec: Float? = null,
    var valueSumSecJoin: Float? = null,
    var returnSumSecJoin: Float? = null,
    var byMoneyJoin: Float? = null,
    var byTaxJoin: Float? = null,
    val byYear: Int? = null,
    val byMonth: Int? = null,
    val byWeek: String? = null,
    val byDay: Int? = null,
    val byEnt: Int? = null,
    val byProduct: Float? = null,
    val byAgent: String? = null,
    var stdValue: Float? = null,
    var stdReturn: Float? = null,
    var counter: Float = 1f,
    var date: String? = null,
    var byCost: Float? = null,
    var byCostRet: Float? = null,
    var byCostJoin: Float? = null,
    var byCostJoinRet: Float? = null,
    var id: Int? = null,
) {
    var multiplyValue=1f
    fun toKey(
        byYear: Boolean,
        byMonth: Boolean,
        byWeek: Boolean,
        byDay: Boolean,
        byEnt: Boolean,
        byProduct: Boolean,
        byAgent: Boolean
    ): DataKey {
        return DataKey(
            if (byYear) this.byYear else null,
            if (byMonth) this.byMonth else null,
            if (byWeek) this.byWeek else null,
            if (byDay) this.byDay else null,
            if (byEnt) this.byEnt else null,
            if (byProduct) this.byProduct else null,
            if (byAgent) this.byAgent else null
        )
    }

    @myName("getTotalMoney")
    fun getTotalMoney(): Float {
        return (byMoney ?: 0f) + (byMoneyJoin ?: 0f)
    }

    @myName("getTotalBeforeTax")
    fun getTotalBeforeTax(): Float {
        return getTotalMoney() - getTotalTax()
    }

    @myName("getTotalTax")
    fun getTotalTax(): Float {
        return (byTax ?: 0f) + (byTaxJoin ?: 0f)
    }

    @myName("getTotalCost")
    fun getTotalCost(): Float {
        return (byCost ?: 0f) + (byCostJoin ?: 0f)
    }

    @myName("getTotalCostRet")
    fun getTotalCostRet(): Float {
        return (byCostRet ?: 0f) + (byCostJoinRet ?: 0f)
    }

    fun increase(d: DataQueryHolder, maxBy: Boolean = false): DataQueryHolder {
        valueSum += d.valueSum
        returnSum += d.returnSum
        number_of_rows = number_of_rows.let { if (maxBy) max(it, d.number_of_rows) else it.plus(d.number_of_rows) }
        byMoney = byMoney?.plus(d.byMoney ?: 0f)
        byTax = byTax?.plus(d.byTax ?: 0f) ?: d.byTax?.plus(byTax ?: 0f)
        valueSumSec = valueSumSec?.plus(d.valueSumSec ?: 0f) ?: d.valueSumSec?.plus(valueSumSec ?: 0f)
        returnSumSec = returnSumSec?.plus(d.returnSumSec ?: 0f) ?: d.returnSumSec?.plus(returnSumSec ?: 0f)
        valueSumSecJoin =
            valueSumSecJoin?.plus(d.valueSumSecJoin ?: 0f) ?: d.valueSumSecJoin?.plus(valueSumSecJoin ?: 0f)
        returnSumSecJoin =
            returnSumSecJoin?.plus(d.returnSumSecJoin ?: 0f) ?: d.returnSumSecJoin?.plus(returnSumSecJoin ?: 0f)
        byMoneyJoin = byMoneyJoin?.plus(d.byMoneyJoin ?: 0f) ?: d.byMoneyJoin?.plus(byMoneyJoin ?: 0f)
        byTaxJoin = byTaxJoin?.plus(d.byTaxJoin ?: 0f) ?: d.byTaxJoin?.plus(byTaxJoin ?: 0f)
        byCost = byCost?.plus(d.byCost ?: 0f) ?: d.byCost?.plus(byCost ?: 0f)
        byCostRet = byCostRet?.plus(d.byCostRet ?: 0f) ?: d.byCostRet?.plus(byCostRet ?: 0f)
        byCostJoin = byCostJoin?.plus(d.byCostJoin ?: 0f) ?: d.byCostJoin?.plus(byCostJoin ?: 0f)
        byCostJoinRet = byCostJoinRet?.plus(d.byCostJoinRet ?: 0f) ?: d.byCostJoinRet?.plus(byCostJoinRet ?: 0f)
        multiplyValue += d.multiplyValue
        counter += 1
        return this
    }
    fun multiply(number:Float): DataQueryHolder {
        valueSum *= number
        returnSum *= number
        byMoney = byMoney?.times(number)
        byTax = byTax?.times(number)
        valueSumSec = valueSumSec?.times(number)
        returnSumSec = returnSumSec?.times(number)
        valueSumSecJoin =
            valueSumSecJoin?.times(number)
        returnSumSecJoin =
            returnSumSecJoin?.times(number)
        byMoneyJoin = byMoneyJoin?.times(number)
        byTaxJoin = byTaxJoin?.times(number)
        byCost = byCost?.times(number)
        byCostRet = byCostRet?.times(number)
        byCostJoin = byCostJoin?.times(number)
        byCostJoinRet = byCostJoinRet?.times(number)
        return this
    }

    fun negative(): DataQueryHolder {
        return this.copy(
            valueSum = -valueSum,
            returnSum = -returnSum,
            byMoney = byMoney?.let { -it },
            byTax = byTax?.let { -it },
            valueSumSec = valueSumSec?.let { -it },
            returnSumSec = returnSumSec?.let { -it },
            valueSumSecJoin = valueSumSecJoin?.let { -it },
            returnSumSecJoin = returnSumSecJoin?.let { -it },
            byMoneyJoin = byMoneyJoin?.let { -it },
            byTaxJoin = byTaxJoin?.let { -it },
            byCost = byCost,
            byCostRet = byCostRet,
            byCostJoin = byCostJoin,
            byCostJoinRet = byCostJoinRet,
        )
    }

    companion object {
        fun toGroup(
            data: List<DataQueryHolder>,
            byYear: Boolean,
            byMonth: Boolean,
            byWeek: Boolean,
            byDay: Boolean,
            byEnt: Boolean,
            byProduct: Boolean,
            byAgent: Boolean
        ): Grouping<DataQueryHolder, DataKey> {
            return data.groupingBy { it.toKey(byYear, byMonth, byWeek, byDay, byEnt, byProduct, byAgent) }
        }

        fun toGroupAggregate(
            data: List<DataQueryHolder>,
            byYear: Boolean,
            byMonth: Boolean,
            byWeek: Boolean,
            byDay: Boolean,
            byEnt: Boolean,
            byProduct: Boolean,
            byAgent: Boolean,
            maxBy: Boolean = false
        ): List<DataQueryHolder?> {

            return toGroup(
                data,
                byYear,
                byMonth,
                byWeek,
                byDay,
                byEnt,
                byProduct,
                byAgent
            ).aggregate { key, accumulator: DataQueryHolder?, element, first ->
                if (first)
                    element.copy()
                else
                    accumulator?.increase(element, maxBy)
            }.values.toList()
        }


        fun fromPD(
            doc_type: Int,
            pd: ProductDelivery,
            date: String,
            c: Entity,
            note_used: Byte,
            agent: String,
            byDate: Boolean? = null,
            id:Int?=null
        ): DataQueryHolder {
            var price: Float
            var priceBefore: Float
            val rawPrice = UINKDBInterface.activeDB.pdUsePrice(pd, c, date, note_used)!!
            priceBefore = rawPrice.first
            price = rawPrice.second
            val real_amount = pd.value - pd.returns
            val wrapped_amount = pd.wrapped_amount - pd.wrapped_amount_return
            val cost = UINKDBInterface.activeDB.getProductCost(pd.productId)?.get(date) ?: Pair(0f, 0f)
            val cost_value = cost.first * (1 - cost.second / 100)
            return DataQueryHolder(
                doc_type,
                pd.value,
                pd.returns,
                1f,
                (real_amount) * (price),
                (real_amount) * (price - priceBefore),
                pd.wrapped_amount,
                pd.wrapped_amount_return,
                pd.wrapped_amount * pd.conversion_ratio,
                pd.wrapped_amount_return * pd.conversion_ratio,
                (wrapped_amount) * pd.conversion_ratio * price,
                (wrapped_amount) * pd.conversion_ratio * (price - priceBefore),
                DatesManipulator.getYear(date).toInt(),
                DatesManipulator.getMonth(date).toInt(),
                DatesManipulator.getStartWeek(date),
                DatesManipulator.getDayOfWeek(date),
                c.id,
                pd.productId.toFloat(),
                agent,
                0f,
                0f,
                date = byDate?.let { date },
                byCost = pd.value * cost_value,
                byCostJoin = pd.wrapped_amount * pd.conversion_ratio * cost_value,
                byCostRet = pd.returns * cost_value,
                byCostJoinRet = pd.wrapped_amount_return * pd.conversion_ratio * cost_value,
                id= id
            )
        }

        fun fromTN(data: List<ClientTaxNote>, byDate: Boolean? = null): MutableList<DataQueryHolder> {
            var ret: MutableList<DataQueryHolder> = mutableListOf()
            data.forEach {
                var ent_id = it.client_id
                if (ent_id == -1)
                    return@forEach
                val c =
                    UINKDBInterface.activeDB.getClient(ent_id).first!!

                val note_used: Byte = 0
                it.productDeliveries.forEach { pd ->
                    var r =
                        fromPD(it.getConnectedDocType(), pd, it.document_date, c, note_used, it.agent, byDate = byDate)
                    if (it.taxNoteType == TaxNoteType.CANCEL)
                        r = r.negative()
                    ret.add(r)
                }
            }
            return ret
        }

        fun fromDVS(
            data: List<Note>,
            isClient: Boolean,
            byDate: Boolean? = null,
        ): MutableList<DataQueryHolder> {
            var ret: MutableList<DataQueryHolder> = mutableListOf()
            data.forEach {
                var ent_id = it.ent_id
                if (ent_id == -1)
                    return@forEach
                val c =
                    if (isClient)
                        UINKDBInterface.activeDB.getClient(ent_id).first!!
                    else
                        UINKDBInterface.activeDB.getSupplier(ent_id).first!!

                val note_used: Byte = if (it.isUsed()) 1 else 0
                it.delivery_info.forEach { pd ->
                    ret.add(fromPD(it.getConnectedDocType(), pd, it.date, c, note_used, it.agent, byDate,id=it.id))
                }
            }
            return ret
        }

        fun toStringTable(data: List<DataQueryHolder>): List<List<String>> {
            val resresult: MutableList<MutableList<String>> = mutableListOf()
            //header
            val result: MutableList<String> = mutableListOf()
            val first = data[0]
            result.add("מסמך")
            result.add("כמויות")
            result.add("חזרות")
            result.add("מספר שורות")
            first.byMoney?.let { result.add("סכום(ש''ח)") }
            first.valueSumSec?.let { result.add("כמויות יחידה נוספת") }
            first.returnSumSec?.let { result.add("חזרות יחידה נוספת") }
            first.valueSumSecJoin?.let { result.add("כמויות מהכפלות") }
            first.returnSumSecJoin?.let { result.add("כמויות מהכפלות") }
            first.byMoneyJoin?.let { result.add("סכום מהכפלות") }
            first.byYear?.let { result.add("שנה") }
            first.byMonth?.let { result.add("חודש") }
            first.byWeek?.let { result.add("שבוע") }
            first.byDay?.let { result.add("יום בשבוע") }
            first.byEnt?.let { result.add("לקוח") }
            first.byProduct?.let { result.add("מוצר") }
            first.byAgent?.let { result.add("סוכן") }
            first.stdValue?.let { result.add("סטיית תקן כמויות") }
            first.stdReturn?.let { result.add("סטיית תקן חזרות") }
            resresult.add(result)

            data.forEach {
                val result: MutableList<String> = mutableListOf()
                result.add(DocType.DELIVERY_NOTES.convert(it.doc_type).DOC_NAME())
                result.add(it.valueSum.toString())
                result.add(it.returnSum.toString())
                result.add(it.number_of_rows.toString())
                it.byMoney?.let { result.add(it.toString()) }
                it.valueSumSec?.let { result.add(it.toString()) }
                it.returnSumSec?.let { result.add(it.toString()) }
                it.valueSumSecJoin?.let { result.add(it.toString()) }
                it.returnSumSecJoin?.let { result.add(it.toString()) }
                it.byMoneyJoin?.let { result.add(it.toString()) }
                it.byYear?.let { result.add(it.toString()) }
                it.byMonth?.let { result.add(it.toString()) }
                it.byWeek?.let { result.add(it.toString()) }
                it.byDay?.let { result.add(it.toString()) }
                it.byEnt?.let { result.add(it.toString()) }
                it.byProduct?.let { result.add(it.toString()) }
                it.byAgent?.let { result.add(it.toString()) }
                it.stdValue?.let { result.add(it.toString()) }
                it.stdReturn?.let { result.add(it.toString()) }
                resresult.add(result)

            }
            return resresult
        }

    }
}