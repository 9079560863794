package Utils

val METHOD_NAME = listOf<String>(
    "מזומן", "צ'ק", "אשראי", "העברה בנקאית", "ביט", "ניכוי מס במקור", "ברטר", "צ'ק מהיר"
)

enum class PayMethod(val state: Int = 0) {
    CASH(1),
    CHECK(2),
    CARD(3),
    BANK(4),
    BIT(5),
    MASS_BAMACOR(6),
    BARTER(7),
    FAST_CHECK(8);

    fun convert(ps: Int): PayMethod {
        return when (ps) {
            1 -> {
                CASH
            }
            2 -> {
                CHECK
            }
            3 -> {
                CARD
            }
            4 -> {
                BANK
            }
            5 -> {
                BIT
            }
            6 -> {
                MASS_BAMACOR
            }
            7 -> {
                BARTER
            }
            8 -> {
                FAST_CHECK
            }
            else -> {
                CASH
            }
        }
    }

    fun MethodName(): String {
        return METHOD_NAME[this.state - 1]

    }

}