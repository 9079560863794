package Structs

import DataBase.UINKDBInterface
import Utils.DatesManipulator
import kotlinx.serialization.Serializable

@Serializable
data class InventoryItem(

    val agent_id: Int,
    var product_id: Int,
    var valueSum: Float,
    var returnSum: Float,
    var valueSumSec: Float,
    var returnSumSec: Float,
    val updated: String,
    var inventoryStart: Float? = null,
    var inventoryStart2: Float? = null,
    var amountDistribution: Float? = null,
    var amountDistribution2: Float? = null,
    val type: Int = 0,
    val real_product: Int = -1,
) {
    init {

        if (real_product != -1) {
            product_id = real_product
        }
    }

    companion object {
        fun prepareAllItems(
            itemsMap: Map<Int, InventoryItem>,
            agent_id: Int,
            curItems: MutableList<InventoryItem>? = null
        ): List<InventoryItem> {
            val items = curItems ?: mutableListOf<InventoryItem>()
            val products = UINKDBInterface.activeDB.getAllClientProduct(true).first
            products.forEach {
                if (!itemsMap.containsKey(it.id)) {
                    items.add(
                        InventoryItem(
                            agent_id,
                            it.id,
                            0f,
                            0f,
                            0f,
                            0f,
                            DatesManipulator.dateNow(),
                            0f, 0f
                        )
                    )
                }
            }
            return items
        }

        fun orderNoteToInventoryItem(orderNote: OrderNote, full: Boolean): List<InventoryItem> {
            //for now agent

            val agent_id = orderNote.agent_id
            val curItems = orderNote.delivery_info.map {
                InventoryItem(
                    agent_id,
                    it.productId,
                    0f, 0f, 0f, 0f, orderNote.date_issued,
                    it.value,
                    it.wrapped_amount,
                    // switch to updated
                )

            }.toMutableList()

            if (full) {

                val itemsMap = curItems.map { it.product_id to it }.toMap()
                return prepareAllItems(itemsMap, agent_id, curItems)

            }
            return curItems
        }
    }
}
