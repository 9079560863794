package Structs

import DataBase.UINKDBInterface
import Utils.DatesManipulator
import Utils.DocType
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.JsonPrimitive
import myName

@Serializable
class ClientCarteset(
    val date: String="",
    val number:Int=-1,
    val id: Int,
    val positive: Float,
    val negative: Float,
    val date_updated: String="",
    val state: Int = 0,
    val order_id: Int = -1,
    val notes: String = ""
) : InformationBase() {
    override fun getConnectedDate(): String {
        return date
    }

    override fun getConnectedId(): Int {
        return number
    }

    override fun getConnectedDocType(): Int {
        return DocType.CLIENT_CARTESET.state
    }

    override fun getConnectedEntId(): Int {
        return id
    }

    override fun getConnectedValue(): Float {
        return positive - negative
    }

    override fun getConnectedName(): String {
        return UINKDBInterface.activeDB.getClient(id).first?.getName(date) ?: ""
    }

    fun isContinuesYear(): Boolean {
        return state == 1
    }

    @myName("getConnectedOrder")
    fun getConnectedOrder(): OrderNote? {
        return if (order_id != -1)
            UINKDBInterface.activeDB.getClientOrderNotes(order_id = order_id).first.firstOrNull()
        else null
    }

    fun toJson(): JsonObject {
        val map: MutableMap<String, JsonPrimitive> = mutableMapOf()
        map["id"] = JsonPrimitive(id.toString())

        map["date"] = JsonPrimitive(date)

        map["state"] = JsonPrimitive(state.toString())
        map["order_id"] = JsonPrimitive(order_id.toString())
        map["positive"] = JsonPrimitive(positive.toString())
        map["negative"] = JsonPrimitive(negative.toString())
        map["date_updated"] = JsonPrimitive(DatesManipulator.dateNow())
        map["notes"] = JsonPrimitive(notes)

        return JsonObject(map)
    }

}