package Utils

//import kotlinx.datetime.*
//import java.text.SimpleDateFormat
//import java.time.LocalDate
//import java.time.format.DateTimeFormatter


import kotlinx.datetime.*
import myName
import kotlin.math.abs
import kotlin.math.min

val DayOfWeek.ToIsraelWeekDay: Int
    get() {
        return when (this) {
            DayOfWeek.SUNDAY -> {
                0
            }

            DayOfWeek.MONDAY -> {
                1
            }

            DayOfWeek.TUESDAY -> {
                2
            }

            DayOfWeek.WEDNESDAY -> {
                3
            }

            DayOfWeek.THURSDAY -> {
                4
            }

            DayOfWeek.FRIDAY -> {
                5
            }

            DayOfWeek.SATURDAY -> {
                6
            }
        }
    }

@myName("DatesManipulator")
object DatesManipulator {
    fun israel_date_format_to_UTC(date: String): String {
        val splitted = date.split('-')

        return splitted.reversed().joinToString("-")
    }

    fun toStringRigthTimeLoc(date: String): String {
        return date.toString().split(" ").reversed().joinToString(" ")
    }

    fun toStringwithTime(date: String): String {
        return date.toString().split(" ").reversed().joinToString("  ")
    }

    @myName("dateIsrael")
    fun dateIsrael(date: String): String {
        return dateTimeIsrael(date).split(" ")[0]
    }
fun getDatesChunkes(fromDate:String,toDate:String, chunkSize:Int=3):List<Pair<String,String>>{
        val dates = mutableListOf<Pair<String,String>>()

        val sub = getMonthDatesRange(fromDate,toDate)
        sub.forEachIndexed { index, s ->
            if(index%chunkSize==0){
                val d = (if(index==0) s else getNextDay(s))
                if(index==0 || d<toDate)
                    dates.add((if(index==0) s else getNextDay(s)) to sub[min(index+chunkSize,sub.size-1)])

            }
        }
        return dates
    }
    @myName("dateIsraelShrink")
    fun dateIsraelShrink(date: String): String {
        val splitted = date.split("-")
        return "${splitted[2]}-${splitted[1]}"
    }
    @myName("dateIsraelShrinkYear")
    fun dateIsraelShrinkYear(date: String): String {
        val splitted = date.split("-")
        return "${splitted[2]}-${splitted[1]}-${splitted[0].takeLast(2)}"
    }

    @myName("getTime")
    fun getTime(date: String): String {
        val d = date.split(" ")
        if (d.size < 2) {
            return "00:00"
        }
        val h = d[1].split(":")
        return "${h[0]}:${h[1]}"
    }

    fun inverseDateIsrael(date: String): String {
        return dateIsrael(date)
    }

    @myName("dateTimeIsrael")
    fun dateTimeIsrael(date: String): String {
        val dateSplit = date.split(" ")
        val first = dateSplit[0].split("-").reversed().joinToString("-")
        val second = if (dateSplit.size == 2) " ${dateSplit[1]}" else ""

        return "$first$second"
    }

    fun toStringWithoutime(date: String): String {
        return date.toString().split(" ")[0]
    }

    fun _localDateToString(date: LocalDate): String {
        val year = date.year
        val month = date.month.ordinal + 1
        val day = date.dayOfMonth
        val printMonth = if (month < 10) "0$month" else month.toString()
        val printDay = if (day < 10) "0$day" else day.toString()
        return "$year-$printMonth-$printDay"

    }

    fun getYear(date: String): String {
        return date.split(" ")[0].split("-")[0]
    }

    fun getMonth(date: String): String {
        return date.split(" ")[0].split("-")[1]
    }

    fun getDay(date: String): String {
        return date.split(" ")[0].split("-")[2]
    }
    fun getDayOfYear(date: String): Int {
        return LocalDate.parse(date.split(" ")[0]).dayOfYear
    }

    fun _localDateTimeToString(date: LocalDateTime): String {
        val year = date.year
        val month = date.month.ordinal + 1
        val day = date.dayOfMonth
        val hour = date.hour
        val minute = date.minute
        val second = date.second
        val printMonth = if (month < 10) "0$month" else month.toString()
        val printDay = if (day < 10) "0$day" else day.toString()
        val printHour = if (hour < 10) "0$hour" else hour.toString()
        val printMinute = if (minute < 10) "0$minute" else minute.toString()
        val printSecond = if (second < 10) "0$second" else second.toString()
        return "$year-$printMonth-$printDay $printHour:$printMinute:$printSecond"

    }

    @myName("dateNow")
    fun dateNow(): String {
        val now: Instant = Clock.System.now()
//        val zone = TimeZone.of("Europe/Berlin")
        val today: LocalDateTime = now.toLocalDateTime(TimeZone.currentSystemDefault())
        return _localDateTimeToString(today)


    }

    @myName("dateNowObject")
    fun dateNowObject(): LocalDateTime {
        val now: Instant = Clock.System.now()
//        val zone = TimeZone.of("Europe/Berlin")
        return now.toLocalDateTime(TimeZone.currentSystemDefault())
    }

    @myName("dateNowNoHour")
    fun dateNowNoHour(): String {
        return dateNow().split(" ")[0]
    }

    fun firstDateOfMonthNow(): String {
        return minDay(dateNow())
    }

    fun daysDiffBetweenDates(d1: String, d2: String): Int {
        val d1 = LocalDate.parse(d1.split(" ")[0])
        val d2 = LocalDate.parse(d2.split(" ")[0])
        return abs(d1.daysUntil(d2))
    }
    fun monthDiffBetweenDates(d1: String, d2: String): Int {
        val d1 = LocalDate.parse(d1.split(" ")[0])
        val d2 = LocalDate.parse(d2.split(" ")[0])
        return abs(d1.monthsUntil(d2))
    }

    @myName("minDay")
    fun minDay(date: String): String {
        val date = date.split(" ")[0]
        val splitted = date.split("-").toMutableList()
        splitted[2] = "01"
        return splitted.joinToString("-")
    }

    @myName("minWeekDay")
    fun minWeekDay(date: String): String {

        val localDate = LocalDate.parse(date.split(" ")[0])
        val dayPeriod = when (localDate.dayOfWeek) {
            DayOfWeek.SUNDAY -> {
                DatePeriod(0, 0, 0)
            }

            DayOfWeek.MONDAY -> {
                DatePeriod(0, 0, 1)
            }

            DayOfWeek.TUESDAY -> {
                DatePeriod(0, 0, 2)
            }

            DayOfWeek.WEDNESDAY -> {
                DatePeriod(0, 0, 3)
            }

            DayOfWeek.THURSDAY -> {
                DatePeriod(0, 0, 4)
            }

            DayOfWeek.FRIDAY -> {
                DatePeriod(0, 0, 5)
            }

            DayOfWeek.SATURDAY -> {
                DatePeriod(0, 0, 6)
            }
        }
        return _localDateToString(localDate.minus(dayPeriod))
    }

    @myName("maxWeekDay")
    fun maxWeekDay(date: String): String {

        val localDate = LocalDate.parse(date.split(" ")[0])
        val dayPeriod = when (localDate.dayOfWeek) {
            DayOfWeek.SUNDAY -> {
                DatePeriod(0, 0, 6)
            }

            DayOfWeek.MONDAY -> {
                DatePeriod(0, 0, 5)
            }

            DayOfWeek.TUESDAY -> {
                DatePeriod(0, 0, 4)
            }

            DayOfWeek.WEDNESDAY -> {
                DatePeriod(0, 0, 3)
            }

            DayOfWeek.THURSDAY -> {
                DatePeriod(0, 0, 2)
            }

            DayOfWeek.FRIDAY -> {
                DatePeriod(0, 0, 1)
            }

            DayOfWeek.SATURDAY -> {
                DatePeriod(0, 0, 0)
            }
        }
        return _localDateToString(localDate.plus(dayPeriod))

    }

    @myName("getDayOfWeek")
    fun getDayOfWeek(date: String): Int {

        val localDate = LocalDate.parse(date.split(" ")[0])
        return localDate.dayOfWeek.ordinal
    }

    @myName("getDayOfWeekIsrael")
    fun getDayOfWeekIsrael(date: String): Int {

        val localDate = LocalDate.parse(date.split(" ")[0])
        return localDate.dayOfWeek.ToIsraelWeekDay
    }

    @myName("getNumberOfDaysBetweenDates")
    fun getNumberOfDaysBetweenDates(dayOfWeek: Int, dateFrom: String, dateTo: String): Int {

        val localDateFrom = LocalDate.parse(dateFrom.split(" ")[0])
        val localDateTo = LocalDate.parse(dateTo.split(" ")[0])
        val daysAmount = localDateFrom.daysUntil(localDateTo)
        val weeks = (daysAmount / 7).toInt()

        val dayOfWeekFrom = localDateFrom.dayOfWeek.ordinal
        val dayOfWeekTo = localDateTo.dayOfWeek.ordinal
        var weekCounter = 1
        var instancesCounter = 0
        if (dayOfWeek >= dayOfWeekFrom)
            instancesCounter += 1
        if (dayOfWeek <= dayOfWeekTo && dateFrom != dateTo)
            instancesCounter += 1
        while (weekCounter < weeks - 1) {
            instancesCounter += 1
            weekCounter += 1
        }

        return instancesCounter
    }

    @myName("getWeek")
    fun getWeek(date: String): Int {
        val localDate = LocalDate.parse(date.split(" ")[0])
        val day = localDate.dayOfYear
        val weekNumber = (day / 7f).toInt()
        return weekNumber
    }

    @myName("getDayOfWeekStr")
    fun getDayOfWeekStr(date: String, full: Boolean = false): String {

        val localDate = LocalDate.parse(date.split(" ")[0])
        return when (localDate.dayOfWeek) {
            DayOfWeek.SUNDAY -> {
                if (full) "ראשון" else "א"
            }

            DayOfWeek.MONDAY -> {
                if (full) "שני" else "ב"
            }

            DayOfWeek.TUESDAY -> {
                if (full) "שלישי" else "א"
            }

            DayOfWeek.WEDNESDAY -> {
                if (full) "רביעי" else "ד"
            }

            DayOfWeek.THURSDAY -> {
                if (full) "חמישי" else "ה"
            }

            DayOfWeek.FRIDAY -> {
                if (full) "שישי" else "ו"
            }

            DayOfWeek.SATURDAY -> {
                if (full) "שבת" else "ש"
            }
        }
    }

    @myName("minDayOfYear")
    fun minDayOfYear(date: String): String {
        val date = date.split(" ")[0]
        val splitted = date.split("-").toMutableList()
        splitted[2] = "01"
        splitted[1] = "01"
        return splitted.joinToString("-")
    }

    @myName("maxDayOfYear")
    fun maxDayOfYear(date: String): String {
        val date = date.split(" ")[0]
        val splitted = date.split("-").toMutableList()
        splitted[2] = "01"
        splitted[1] = "12"
        return maxDay(splitted.joinToString("-"))

    }

    @myName("maxDay")
    fun maxDay(date: String): String {
        return _localDateToString(LocalDate.parse(minDay(date)).plus(DatePeriod(0, 1, 0)).minus(DatePeriod(0, 0, 1)))
    }

    fun analyzeTextAsDate(date: String): String {
        val now = date.split(" ")[0].split("-").toMutableList()
        return when {
            now[2].compareTo("max") == 0 -> {
                now[2] = "01"
                maxDay(now.joinToString("-"))
            }

            now[2].compareTo("min") == 0 -> {
                now[2] = "01"
                (now.joinToString("-"))
            }

            else -> {
                date
            }
        }
    }

    fun toMonthYearFromDate(date: String): String {
        var date = toStringWithoutime(date)
        return date.split("-").subList(0, 2).joinToString("-")
    }

    @myName("getNextDay")
    fun getNextDay(date: String, day: Int = 1): String {
        return _localDateToString(LocalDate.parse(date.split(" ")[0]).plus(DatePeriod(days = day)))

    }

    @myName("getStartWeek")
    fun getStartWeek(date: String): String {
        val dayOfWeek = getDayOfWeekIsrael(date)
        return getNextDay(date, -dayOfWeek)

    }

    @myName("getEndWeek")
    fun getEndWeek(date: String): String {
        val dayOfWeek = getDayOfWeekIsrael(date)
        return getNextDay(date, 6 - dayOfWeek)

    }

    @myName("getNextMonth")
    fun getNextMonth(date: String, Month: Int = 1): String {
        return _localDateToString(LocalDate.parse(date.split(" ")[0]).plus(DatePeriod(months = Month)))

    }

    @myName("getNextYear")
    fun getNextYear(date: String, year: Int = 1): String {
        return _localDateToString(LocalDate.parse(date.split(" ")[0]).plus(DatePeriod(years = year)))
    }

    @myName("getDatesRange")
    fun getDatesRange(min: String, max: String): List<String> {
        val lst: MutableSet<String> = mutableSetOf()
        var cur = min
        lst.add(min)
        var steps: Int = 0
        val diff = daysDiffBetweenDates(min, max) + 5
        while (cur != max && steps < diff) {
            steps += 1
            cur = getNextDay(cur)
            lst.add(cur)
        }
        return lst.toList()
    }
    @myName("getMonthDatesRange")
    fun getMonthDatesRange(min: String, max: String): List<String> {
        val lst: MutableSet<String> = mutableSetOf()
        var cur = min
        lst.add(min)
        var steps: Int = 0
        val diff = monthDiffBetweenDates(min, max)
        while (cur != max && steps < diff) {
            steps += 1
            cur = getNextMonth(cur)
            lst.add(cur)
        }
        lst.add(max)
        return lst.distinct().toList()
    }
}