package Structs

import DataBase.UINKDBInterface
import kotlinx.serialization.Serializable
import myName

@myName("ClientDebt")
@Serializable
data class ClientDebt(
    val client_id: Int,
    val branch: Int = -1,
    val notes: Float,
    val positive_carteset: Float,
    val negative_carteset: Float,
    val notes_amount: Float,
    val notes_open: Float,
    val taxNotes: Float,
    val taxNotes_open: Float,
    val tax_note_pays: Float,
    val taxNotes_amount: Float,
    val pay_notes: Float,
    val pay_notes_amount: Float
) {

}