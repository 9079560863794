package Structs

import DataBase.UINKDBInterface
import Utils.DocType
import Utils.PayMethod
import Utils.fixJsonInternal
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import myName
import printPlat
import kotlin.math.abs

@myName("ClientLightPay")
@Serializable
sealed class ClientLightPay(


) : InformationBase() {
    @myName("id")
    abstract val id: Int

    @myName("client_id")
    abstract val client_id: Int

    @myName("value")
    abstract val value: Float
    abstract val date: String

    @myName("document_date")
    abstract val document_date: String
    abstract val action_time: String
    override fun getConnectedDocType(): Int {
        return DocType.PAY_NOTE.state
    }

    override fun getConnectedDate(): String {
        return document_date
    }

    override fun getConnectedId(): Int {
        return id
    }

    override fun getConnectedEntId(): Int {
        return client_id
    }

    override fun getConnectedValue(): Float {
        return value
    }

    override fun getConnectedName(): String {
        return UINKDBInterface.activeDB.getClient(client_id).first?.let {
            if (it.branch != -1 && it.master != -1) it.getName(
                document_date
            ) else it.getBusinessName(document_date)
        } ?: ""
    }
    override fun toString(): String {
        return document_date.toString().split(" ").reversed().joinToString(" ")
    }

    override fun getConnectedPayment(): Float {
        return value
    }

    @myName("getClient")
    fun getClient(): Client {
        return UINKDBInterface.activeDB.getClient(client_id).first!!
    }
}

@myName("ClientLightPayImp")
@Serializable
class ClientLightPayImp(
    override val id: Int,
    override val client_id: Int,
    override val value: Float,
    override val date: String,
    override val document_date: String, override val action_time: String=""
) : ClientLightPay() {

}

@myName("ClientPay")
@Serializable
data class ClientPay(
    override val id: Int,
    override val client_id: Int,
    override val value: Float,
    override val date: String,
    override val document_date: String,
    val details: String,
    val payment_data_raw: String,
    var tax_note_id: String,
    val active: Int,
    var external_details: String = "",
    val agent: String = "",
    val driver_id: Int? = null,
    override val action_time: String=""
) : ClientLightPay() {
    val paymentsData: List<PaymentData> = PaymentData.createFromJson(payment_data_raw)
    val ClientStaticData = Structs.ClientStaticData.createFromJson(details)
    var taxNotes: List<Int> = listOf()
    var taxNotesAmounts: MutableList<Float> = mutableListOf()
    var taxPayNoteCancel: Boolean = false

    init {
        external_details = fixJsonInternal(external_details)
        if (!tax_note_id.isNullOrEmpty()) {
            taxNotes = tax_note_id.split(",").map {
                val splitted = it.split(":")

                val first = splitted[0].toInt()
                var second = -1f
                if (splitted.size == 2) {
                    second = splitted[1].toFloat()
                }
//                taxNotesAmounts.add(second)
                first
            }
            if (taxNotes.first() < 0) {
                taxPayNoteCancel = true
                taxNotes = taxNotes.map { abs(it) }
                tax_note_id = taxNotes.joinToString(",")
            }
        }
    }
    @myName("getDriver")
    fun getDriver(): Agent? {
        return driver_id?.let {
            UINKDBInterface.activeDB.getAgentBy(it, null, driver = true).first
        }
    }

    override fun getConnectedAgent(): String {
        return agent
    }
    override fun isActive(): Boolean {
        return active == 1
    }
    @myName("getAgentId")
    fun getAgentId(): Int? {
        return UINKDBInterface.activeDB.getAgentBy(name=agent, id = null).first?.id
    }
    override fun getConnectedName(): String {
        return UINKDBInterface.activeDB.getClient(client_id).first?.let {
            if (it.branch != -1 && it.master != -1) it.getName(
                document_date
            ) else  ClientStaticData.business_name
        } ?: ClientStaticData.business_name
    }
    fun getSumByType(p: PayMethod): Float {
        return paymentsData.filter { it.payMethod == p }
            .sumByDouble { it.value.toDouble() }.toFloat()
    }
    override fun getComments(): String {
        return external_details
    }
    fun getSumListByType(p: PayMethod): List<Float> {
        return paymentsData.filter { it.payMethod == p }.map {
            it.value
        }
    }

    fun getAllCheckDates(): List<String> {
        return paymentsData.filter { it.payMethod == PayMethod.CHECK }.map { it.check_date }
    }

}
