package Structs

import DataBase.UINKDBInterface
import Utils.*
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import myName
import printPlat
import kotlin.math.abs

@Serializable
@myName("ClientLightTaxNote")
sealed class ClientLightTaxNote(

) : InformationBase() {
    @myName("document_date")
    abstract val document_date: String

    @myName("id")
    abstract val id: Int

    @myName("client_id")
    abstract val client_id: Int

    @myName("value")
    abstract val value: Float
    abstract val discount_percent: Float

    @myName("total_value")
    abstract var total_value: Float
    abstract val type: Int

    @myName("withProduct")
    abstract val withProduct: Int

    abstract val active_state: Int
    abstract val taxNoteType: TaxNoteType
    abstract val activeState: ActiveStateTaxNoteType
    abstract var signer: String?
    abstract var digi_sign: Int?
    abstract var digi_photo: Int?
    abstract var date_sign: String?
    abstract val action_time: String
    abstract val taxConfirmation: String
    override fun getConnectedDocType(): Int {
        return type
    }

    @myName("getTaxPaid")
    fun getTaxPaid(): Float {
        return roundToDecimals(abs(total_value + getDiscountValue() - value), 3)

    }

    @myName("getDiscountValue")
    fun getDiscountValue(): Float {
        return discount_percent / 100 * (value)
    }

    override fun getConnectedPayment(): Float {
        if (type == 1)
            return total_value
        return 0f
    }

    @myName("getDiscountValueTotal")
    fun getDiscountValueTotal(): Float {
        return discount_percent / 100 * (total_value)
    }

    @myName("isCancelable")
    fun isCancelable(): Boolean {
        return activeState == ActiveStateTaxNoteType.ACTIVE
    }


    override fun getConnectedDate(): String {
        return document_date
    }

    override fun getConnectedId(): Int {
        return id
    }

    override fun getConnectedEntId(): Int {
        return client_id
    }

    override fun getConnectedValue(): Float {
        return total_value
    }

    override fun getConnectedValueReal(): Float {
        return total_value * if (type == 2) -1 else 1
    }

    override fun getSign(): Int {
        return if (type == 2) -1 else 1
    }

    override fun getConnectedName(): String {
        return UINKDBInterface.activeDB.getClient(client_id).first?.let {
            if (it.branch != -1 && it.master != -1) it.getName(
                document_date
            ) else it.getBusinessName(document_date)
        } ?: ""
    }

    override fun toString(): String {
        return document_date.toString().split(" ").reversed().joinToString(" ")
    }

    override fun isActive(): Boolean {
        return activeState != ActiveStateTaxNoteType.NOTACTIVE
    }

    override fun isUsed(): Boolean {
        return if ((taxNoteType == TaxNoteType.WITH_PAYMENT && activeState != ActiveStateTaxNoteType.NOTACTIVE)) true else activeState == ActiveStateTaxNoteType.PAID
    }

    fun setSign(NoteSignHolder: NoteSignHolder) {
        digi_sign = NoteSignHolder.digi_sign
        digi_photo = NoteSignHolder.digi_photo
        signer = NoteSignHolder.signer
        date_sign = NoteSignHolder.date
    }

    @myName("getClient")
    fun getClient(): Client {
        return UINKDBInterface.activeDB.getClient(client_id).first!!
    }
}

@Serializable
@myName("ClientLightTaxNoteImp")
class ClientLightTaxNoteImp(
    override val document_date: String,
    override val id: Int,
    override val client_id: Int,
    override val value: Float,
    override val discount_percent: Float,
    override var total_value: Float,
    override val type: Int,
    override val withProduct: Int,
    override val active_state: Int = 1,
    override var date_sign: String? = null,
    override var digi_sign: Int? = null,
    override var digi_photo: Int? = null,
    override var signer: String? = null, override val action_time: String="", override val taxConfirmation: String="",
) : ClientLightTaxNote() {
    override val taxNoteType: TaxNoteType = type.let { TaxNoteType.REGULAR.convert(it) }
    override val activeState: ActiveStateTaxNoteType = active_state.let { ActiveStateTaxNoteType.ACTIVE.convert(it) }

}

@myName("ClientTaxNote")
@Serializable
class ClientTaxNote(
    val date: String,
    override val document_date: String,
    override val id: Int,
    override val client_id: Int,
    override val value: Float,
    override val discount_percent: Float,
    override var total_value: Float,
    val payment_data_raw: String?,
    val value_data_raw: String,
    var cover_dates: String,
    val cover_date_start: String,
    val cover_date_end: String,
    override val type: Int,
    var details: String,
    override val withProduct: Int = 0,
    override val active_state: Int = 1,
    var external_details: String = "",
    var notes2: String = "",
    var notes3: String = "",
    var notes4: String = "",
    var notes5: String = "",
    var agent_id: Int = -1,

    val agent: String = "",
    val order_id: Int = -1,
    val value_left: Float = 0f,
    val driver_id: Int? = null,
    override var date_sign: String? = null,
    override var digi_sign: Int? = null,
    override var digi_photo: Int? = null,
    override var signer: String? = null,
    override val action_time: String="", override val taxConfirmation: String=""
) : ClientLightTaxNote() {
    override val taxNoteType: TaxNoteType = type.let { TaxNoteType.REGULAR.convert(it) }
    override val activeState: ActiveStateTaxNoteType = active_state.let { ActiveStateTaxNoteType.ACTIVE.convert(it) }
    var paymentsData: List<PaymentData> = listOf()
    val valueData: List<ClientTaxNoteData> =
        ClientTaxNoteData.createFromJson(value_data_raw) // must be atleast one

    @myName("notesPrices")
    val notesPrices: MutableMap<Int, Float> = mutableMapOf()

    @myName("notesTaxesPaid")
    val notesTaxes: MutableMap<Int, Float> = mutableMapOf()
    val ClientStaticData = Structs.ClientStaticData.createFromJson(details)
    override fun getComments(): String {
        return external_details
    }
    @myName("getDriver")
    fun getDriver(): Agent? {
        return driver_id?.let {
            UINKDBInterface.activeDB.getAgentBy(it, null, driver = true).first
        }
    }
    @myName("productDeliveries")
    var productDeliveries: List<ProductDelivery> = listOf()

    @myName("hasReturns")
    fun hasReturns(): Boolean {
        return productDeliveries.any { (it.returns > 0 || it.wrapped_amount_return > 0) }
    }

    @myName("hasValues")
    fun hasValues(): Boolean {
        return productDeliveries.any { (it.value > 0 || it.wrapped_amount > 0) }
    }


    override fun getAmounts(): Float {
        return (if(type==2) -1 else 1) * productDeliveries.sumByDouble { (it.value - it.returns + (it.wrapped_amount - it.wrapped_amount_return) * it.conversion_ratio).toDouble() }
            .toFloat()
    }
    @myName("hasExtraUnit")
    fun hasExtraUnit(): Boolean {
        return productDeliveries.any {  it.wrapped_amount > 0f || it.wrapped_amount_return > 0 }
    }
    init {
        external_details = fixJsonInternal(external_details)
        if (cover_dates.isNotEmpty()) {
            cover_dates = DatesHolder(cover_dates!!).toString()
        }
        paymentsData = if (payment_data_raw != null) {
            PaymentData.createFromJson(payment_data_raw)
        } else {
            listOf()
        }
        val x = valueData.firstOrNull { it.fillTaxNoteType == FillTaxNoteType.NOTES }?.details?.split(",") ?: listOf()
        for (s: String in x) {
            val items = s.split(":")
            notesPrices[items[0].toInt()] = items[1].toFloat()
            if (items.size == 3) {
                notesTaxes[items[0].toInt()] = items[2].toFloat()
            }
        }
        productDeliveries = valueData.firstOrNull { it.fillTaxNoteType == FillTaxNoteType.PRODUCTS }?.let {
            ProductDelivery.createFromJson(it.details.replace("@", "\""))
        } ?: listOf()
    }

    @myName("hasNotes")
    fun hasNotes(): Boolean {
        return valueData.any { it.fillTaxNoteType == FillTaxNoteType.NOTES }
    }

    override fun getConnectedDateTime(): String {
        return date
    }

    override fun getConnectedAgent(): String {
        return agent
    }
    @myName("getAgentId")
    fun getAgentId(): Int? {
        return UINKDBInterface.activeDB.getAgentBy(name = agent, id = null).first?.id
    }

    @myName("hasTaxOnNotes")
    fun hasTaxOnNotes(): Boolean {
        return notesTaxes.isNotEmpty()
    }
    override fun getBusinessId(): String {
        return ClientStaticData.business_id
    }
    override fun signData(): NoteSignHolder? = if (digi_sign == 1 || digi_photo == 1) NoteSignHolder(
        id,
        type,
        digi_sign ?: 0,
        digi_photo ?: 0,
        signer ?: "",
        date_sign ?: ""
    ) else UINKDBInterface.activeDB.getSignOffline(id, type).first

    override fun digiSign(): Boolean =
        if (digi_sign == 1) true else UINKDBInterface.activeDB.getSignOffline(id, type).first?.digi_sign == 1

    override fun digiPhoto(): Boolean =
        if (digi_photo == 1) true else UINKDBInterface.activeDB.getSignOffline(id, type).first?.digi_photo == 1

    override fun getConnectedTaxValueReal(): Float {
        return getTaxPaid()
    }

    override fun getBeforeValueReal(): Float {
        return total_value - getTaxPaid()
    }

    //use only when has tax on notes is true
    @myName("getNoteTaxPaid")
    fun getNoteTaxPaid(note_id: Int): Float? {
        return notesTaxes[note_id]
    }

    @myName("toTaxNoteRows")
    fun toTaxNoteRows(noteRequest: Int, splitNet: Boolean = false, noteAsList: Boolean = true,sortProductByPosition:Boolean=false): List<TaxNoteExplain> {
        return TaxNoteExplain.create(this, noteRequest, splitNet, noteAsList,sortProductByPosition)
    }

    @myName("onlyProducts")
    fun onlyProducts(): Boolean {
        return (valueData.size == 2 && productDeliveries.size > 0 && getRoundValueOrNull() != null) || valueData.size == 1 && productDeliveries.size > 0
    }

    @myName("onlyNotes")
    fun onlyNotes(): Boolean {
        return (valueData.size == 2 && hasNotes() && getRoundValueOrNull() != null) || valueData.size == 1 && hasNotes()
    }

    @myName("onlyHand")
    fun onlyHand(): Boolean {
        return (valueData.all { it.fillTaxNoteType == FillTaxNoteType.FREE_STYLE || it.fillTaxNoteType == FillTaxNoteType.HAND || it.fillTaxNoteType == FillTaxNoteType.FREE })
    }

    @myName("getHandValueOrNull")
    fun getHandValueOrNull(): ClientTaxNoteData? {
        return valueData.firstOrNull { it.fillTaxNoteType == FillTaxNoteType.HAND }

    }

    @myName("getFreeValueLinesOrNull")
    fun getFreeValueLinesOrNull(): List<ClientTaxNoteData> {
        return valueData.filter { it.fillTaxNoteType == FillTaxNoteType.FREE_STYLE && (it.details != "הנחת עיגול" && it.details != "עיגול סכום") }

    }

    @myName("getRoundValueOrNull")
    fun getRoundValueOrNull(): ClientTaxNoteData? {
        return valueData.firstOrNull { it.fillTaxNoteType == FillTaxNoteType.FREE && (it.details == "הנחת עיגול" || it.details == "עיגול סכום") }
    }

    fun getTaxNotesOrNull(): List<Int>? {
        return valueData.firstOrNull { it.fillTaxNoteType == FillTaxNoteType.TAX_NOTES }?.details?.split(
            ","
        )
            ?.map { it.toInt() }
    }

    fun getTaxPayNotesOrNull(): List<Int>? {
        return valueData.firstOrNull { it.fillTaxNoteType == FillTaxNoteType.TAX_PAY_NOTES }?.details?.split(
            ","
        )
            ?.map { it.toInt() }
    }

    fun getTaxCancelNotesOrNull(): List<Int>? {
        return valueData.firstOrNull { it.fillTaxNoteType == FillTaxNoteType.TAX_CANCEL_NOTES }?.details?.split(
            ","
        )
            ?.map { it.toInt() }
    }

    @myName("getNotes")
    fun getNotes(): List<Int> {
        return if (hasNotes())
            notesPrices.keys.toList()
        else {
            listOf()
        }
    }

    fun getValueOfNote(id: Int): Float? {
        return notesPrices[id]
    }

    override fun getConnectedName(): String {
        return UINKDBInterface.activeDB.getClient(client_id).first?.let {
            if (it.branch != -1 && it.master != -1) it.getName(
                document_date
            ) else ClientStaticData.business_name
        } ?: ClientStaticData.business_name
    }

    fun getSumByType(p: FillTaxNoteType): Float {
        return ((valueData.firstOrNull { it.fillTaxNoteType == p }?.value
            ?: 0f).toDouble() * (1 - discount_percent / 100)).toFloat()
    }

    fun loadProducts() {

    }
    @myName("getCollectedKarton")
    fun getCollectedKarton(): Map<Int,CollectionObject>? {
        return getConnectedOrder()?.collectionObject
    }
    fun getPaySumByType(p: PayMethod): Float {
        return paymentsData.filter { it.payMethod == p }
            .sumByDouble { it.value.toDouble() }.toFloat()
    }

    fun getSumListByType(p: PayMethod): List<Float> {
        return paymentsData.filter { it.payMethod == p }.map {
            it.value
        }
    }

    fun getAllCheckDates(): List<String> {
        return paymentsData.filter { it.payMethod == PayMethod.CHECK }.map { it.check_date }
    }
    @myName("getConnectedOrder")
    fun getConnectedOrder(): OrderNote? {
        return if (order_id != null)
            UINKDBInterface.activeDB.getClientOrderNotes(order_id = order_id).first.firstOrNull()
        else null
    }
    override fun getConnectedProductsIds(): List<Int> {
        return productDeliveries.map { it.productId }
    }

    companion  object{
        fun getAllProducts(l: List<ClientTaxNote>): Set<Int> {
            val v = mutableSetOf<Int>()
            l.forEach {
                v.addAll(it.productDeliveries.map { it.productId })
            }
            return v
        }
    }

}
