package Structs

import kotlinx.serialization.Serializable

@Serializable
open class EntitySequence() : DatedObject<Entity>() {
    override fun isActive(curDate:String): Boolean {
        return get(curDate).getActive(curDate)
    }


    fun add(e: Entity) {
        add(e, e.date)
    }

    override fun copy(): EntitySequence {
        val new_p: EntitySequence = EntitySequence()
        new_p.objects = objects.toMutableList()
        return new_p
    }

    override fun add(obj: Entity, date: String,runOver:Boolean) {
        super.add(obj, date,false)
        obj.entityDated = this
    }

    override fun toString(): String {
        return get().toString()
    }


    override fun getDefault(curDate: String): Entity {
        return get()
    }


}