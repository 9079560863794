package DataBase.Interface

import Network.NetworkOutput
import Network.httpWorker
import Structs.Respone

interface BaseAdapterInterface {
    suspend fun purePost(
        url: String,
        params: MutableMap<String, String>,
        token:String?=null,
        urlEnc: Boolean = false,
    ): Respone<String?, NetworkOutput>

    suspend fun post(
        url: String,
        params: MutableMap<String, String>,
        autoCompany: Boolean = true,
        fixJsonType: FixJsonType = FixJsonType.simple,
        token_refresh_counter: Int = 5,
        timeout: Long = 20000
    ): Respone<String?, NetworkOutput>

    suspend fun post_file(
        url: String,
        params: MutableMap<String, String>,
        autoCompany: Boolean = true,
        token_refresh_counter: Int = 5,
        timeout: Long = 20000
    ): Respone<ByteArray?, NetworkOutput>

    suspend fun upload_img(
        url: String,
        params: MutableMap<String, String>,
        f: ByteArray,
        image_name: String,
        autoCompany: Boolean = true,
        token_refresh_counter: Int = 5
    ): Respone<String?, NetworkOutput>
    suspend fun upload_file(
        url: String,
        params: MutableMap<String, String>,
        f: ByteArray?=null,
        fixJsonType: FixJsonType=FixJsonType.simple,
        autoCompany: Boolean = true,
        token_refresh_counter: Int = 5,
        f_name:String?=null,
        f_mime_type:String?=null
    ): Respone<String?, NetworkOutput>

}

enum class FixJsonType {
    light,
    lightForInner,
    simple,
    complex,
    NONE
}