package Utils

import myName

val order_status_str = listOf<String>(
    "מבוטלת",
    "פעילה",
    "בוצעה",
    "ממתין ללקוח",
    "התקבלה ע''י לקוח",
    "נעולה",
    "התקבלה ע''י סוכן",
    "ממתין לסוכן",
    "הודפס",
    "אושרה ליצור",
    "שוכפלה",
    "נבדקה",
    "לא צריך",
    "סופק ללקוח",
    "לוקט",
    "התחיל ליקוט",
    "לא בוצעה",
)

val order_status_object = listOf<OrderStatus>(
    OrderStatus.LOCKED_BY_AGENT,
    OrderStatus.REGULAR,
    OrderStatus.CHECKED,
    OrderStatus.APPROVE_TO_ORDER,
    OrderStatus.WAIT_FOR_CLIENT,
    OrderStatus.WAIT_FOR_AGENT,
    OrderStatus.PRINTED,
    OrderStatus.CANCELED,
    OrderStatus.CLOSED,
    OrderStatus.ARRIVED,
    OrderStatus.DONE_BY_CLIENT,
    OrderStatus.DONE_BY_AGENT,
    OrderStatus.DUPLICATED,
    OrderStatus.REJECTED,
    OrderStatus.COLLECTED,
    OrderStatus.COLLECT_START,
    OrderStatus.NOT_DONE,
)
val done_status_order = mapOf<OrderStatus, Int>(
    OrderStatus.APPROVE_TO_ORDER to 0,
    OrderStatus.REGULAR to 1,
    OrderStatus.DONE_BY_CLIENT to 2,
    OrderStatus.DONE_BY_AGENT to 3,
    OrderStatus.WAIT_FOR_CLIENT to 4,
    OrderStatus.WAIT_FOR_AGENT to 5,
    OrderStatus.PRINTED to 6,
    OrderStatus.LOCKED_BY_AGENT to 7,
    OrderStatus.CLOSED to 8,
    OrderStatus.ARRIVED to 9,
    OrderStatus.CANCELED to 10,
    OrderStatus.DUPLICATED to 4,
    OrderStatus.CHECKED to 11,
    OrderStatus.REJECTED to 12,
    OrderStatus.COLLECTED to 13,
    OrderStatus.COLLECT_START to 14,
    OrderStatus.NOT_DONE to 15,
)

enum class OrderStatus(val state: Int = 0) {
    CANCELED(0),
    REGULAR(1),
    CLOSED(2),
    ARRIVED(13),

    WAIT_FOR_CLIENT(3),
    DONE_BY_CLIENT(4),
    LOCKED_BY_AGENT(5),
    DONE_BY_AGENT(6),
    WAIT_FOR_AGENT(7),
    PRINTED(8),
    APPROVE_TO_ORDER(9),
    DUPLICATED(10),
    CHECKED(11),
    REJECTED(12),
    COLLECTED(14),
    COLLECT_START(15),
    NOT_DONE(16),

    ;


    fun convert(os: Int): OrderStatus {
        return when (os) {
            0 -> {
                CANCELED
            }

            1 -> {
                REGULAR
            }

            2 -> {
                CLOSED
            }

            3 -> {
                WAIT_FOR_CLIENT
            }

            4 -> {
                DONE_BY_CLIENT
            }

            5 -> {
                LOCKED_BY_AGENT
            }

            6 -> {
                DONE_BY_AGENT
            }

            7 -> {
                WAIT_FOR_AGENT
            }

            8 -> {
                PRINTED
            }

            9 -> {
                APPROVE_TO_ORDER
            }

            10 -> {
                DUPLICATED
            }

            11 -> {
                CHECKED
            }

            12 -> {
                REJECTED
            }

            13 -> {
                ARRIVED
            }

            14 -> {
                COLLECTED
            }

            15 -> {
                COLLECT_START
            }


            else -> {
                REGULAR
            }
        }
    }

    @myName("orderName")
    fun orderName(): String {
        return order_status_str[this.state]
    }

    override fun toString(): String {
        return orderName()
    }

    @myName("closed")
    fun closed(): Boolean {
        return when (this) {
            CLOSED, ARRIVED,COLLECTED -> true
            else -> false
        }
    }

    @myName("locked")
    fun locked(): Boolean {
        return when (this) {
            CLOSED, APPROVE_TO_ORDER, PRINTED, LOCKED_BY_AGENT, ARRIVED, COLLECTED, COLLECT_START,REJECTED -> true
            else -> false
        }
    }

    @myName("disableSelect")
    fun disableSelect(): Boolean {
        return when (this) {
            CLOSED, CANCELED, ARRIVED -> true
            else -> false
        }
    }

    @myName("getLockingStates")
    fun getLockingStates(): List<String> {
        return order_status_str.mapIndexedNotNull { index: Int, s: String ->
            when (index) {
                ARRIVED.state, CLOSED.state, APPROVE_TO_ORDER.state, LOCKED_BY_AGENT.state, PRINTED.state, CHECKED.state, COLLECTED.state, COLLECT_START.state -> s
                else -> null
            }
        }

    }

    @myName("inverseName")
    fun inverseName(name: String): OrderStatus {
        val ind = order_status_str.indexOfFirst { it.compareTo(name) == 0 }
        return OrderStatus.REGULAR.convert(ind)

    }


}