package Utils

import DataBase.DBQuery
import Structs.InformationBase
import Structs.Respone
import kotlinx.coroutines.*


class ParallelExecutor {
    suspend fun <OUTPUT> getInfo(
        jobs: List<suspend () -> OUTPUT>,
        verifyResults: (OUTPUT) -> Boolean,
        onCompletion: suspend (jobIndex: Int, result: OUTPUT) -> Unit
    ) =
        withContext(Dispatchers.Default) {

            try {
                coroutineScope {
                    jobs.forEachIndexed { index, call ->
                        launch {
                            val respone = call()
                            if (!verifyResults(respone)) {
                                throw Exception("FAILED")
                            }
                            onCompletion(index, respone)
                        }
                    }
                }
                true
            } catch (e: Throwable) {
                false
            }
        }

    suspend fun <OUTPUT> execute(
        jobs: List<suspend () -> OUTPUT>,
        onTimeout: (jobIndex: Int) -> OUTPUT,
        onFailure: (jobIndex: Int, exception: Throwable) -> OUTPUT,
        onCompletion: suspend (jobIndex: Int, result: OUTPUT) -> Unit,
        timeout: Long,
        invokeDispatcher: CoroutineDispatcher = Dispatchers.Default
    ) {
        supervisorScope {
            val listenJobs = jobs.map { job ->
                async(invokeDispatcher) {
                    withTimeout(timeout) {
                        job()
                    }
                }
            }

            listenJobs.forEachIndexed { index, job ->
                launch {
                    val output = try {
                        job.await()
                    } catch (e: TimeoutCancellationException) {
                        onTimeout(index)
                    } catch (e: Exception) {
                        onFailure(index, e)
                    }
                    onCompletion(index, output)
                }
            }
        }
    }
}
